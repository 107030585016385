<template>
  <div>
    <associate-profile-view
      :visible.sync="profile.visible"
      :associate_number.sync="profile.associate_number"
      :open-tab="profile.open_tab"
      :is-editing="profile.is_editing"
      @edit="profile.edit"
    />
  </div>
</template>

<script>
import ProfileAssociateView from "@/modules/profile/components/profile-view.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState: modalsState } = createNamespacedHelpers("modals");

export default {
  components: {
    "associate-profile-view": ProfileAssociateView,
  },
  data() {
    return {};
  },
  computed: {
    ...modalsState({
      profile: (state) => state.modals.profile,
    }),
  },
};
</script>
