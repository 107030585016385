<template>
  <a-spin :spinning="loading">
    <div>
      <documents-send
        :visible.sync="visibleSendDocumentsModal"
        :loading="loadingSendDocument"
        @send="sendDocuments"
        @close="closeSendDocumentsModal"
      />
      <create-document :visible.sync="visibleCreateDocumentModal" />

      <div class="flex flex-column gap-4">
        <div class="flex justify-content-between align-items-center">
          <h3 class="m-0">Attached Documents</h3>
          <div class="flex gap-2">
            <a-button icon="plus" type="primary" @click="openCreateModal">
              Create
            </a-button>
            <template v-if="documents.length">
              <a-dropdown-button type="primary">
                Export
                <a-menu slot="overlay">
                  <a-menu-item
                    key="mail"
                    @click="openSendDocumentsModal(undefined)"
                  >
                    <a-icon type="mail" /> Send All To Email
                  </a-menu-item>
                  <a-menu-item key="download" @click="downloadAllDocuments">
                    <a-icon type="download" /> Download All
                  </a-menu-item>
                </a-menu>
              </a-dropdown-button>
            </template>
          </div>
        </div>
        <h3 class="text-center" v-if="!documents.length">No Documents Found</h3>
        <template v-else>
          <div class="list">
            <div class="item" v-for="item in documents" :key="item.id">
              <div class="flex justify-content-between">
                <h4 class="flex gap-2">
                  {{ item.name }}
                  <a-tag
                    class="m-0"
                    :color="getAssociateDocumentStatus(item.status).color"
                  >
                    {{ getAssociateDocumentStatus(item.status).title }}</a-tag
                  >
                </h4>
                <div class="flex gap-2">
                  <a-dropdown-button type="primary">
                    Export
                    <a-menu slot="overlay">
                      <a-menu-item
                        key="mail"
                        @click="openSendDocumentsModal(item.id)"
                      >
                        <a-icon type="mail" /> Send To Email
                      </a-menu-item>
                      <a-menu-item
                        key="download"
                        @click="downloadDocument(item.id)"
                      >
                        <a-icon type="download" /> Download
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown-button>
                  <a-button
                    type="danger"
                    icon="delete"
                    :loading="loadingButtons[item.id]"
                    @click="deleteDocument(item.id)"
                  >
                    Delete
                  </a-button>
                </div>
              </div>
              <a-descriptions :column="1" size="small" class="col-fixed p-0">
                <a-descriptions-item label="Type">
                  <a-tag class="m-0">{{ item.type.name }}</a-tag>
                </a-descriptions-item>
                <a-descriptions-item label="Document Number">
                  {{ item.number ?? "-" }}
                </a-descriptions-item>
                <a-descriptions-item label="Description">
                  {{ item.description ?? "-" }}
                </a-descriptions-item>
                <a-descriptions-item label="Created at">
                  {{
                    item.created_at ? formatDateString(item.created_at) : "-"
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="Expiration Date">
                  {{
                    item.expiration_date
                      ? formatDateString(item.expiration_date)
                      : "-"
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="Required in Company">
                  {{ item.required_in_project ? "Yes" : "No" }}
                </a-descriptions-item>
                <a-descriptions-item label="Required for Work">
                  {{ item.required_for_work ? "Yes" : "No" }}
                </a-descriptions-item>
                <a-descriptions-item label="Files">
                  <a-upload
                    list-type="picture-card"
                    :file-list="getFilesList(item.files)"
                    :showUploadList="{
                      showRemoveIcon: false,
                      showPreviewIcon: true,
                      showDownloadIcon: true,
                    }"
                    @preview="handlePreview"
                    @download="handleDownload"
                  >
                  </a-upload>
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </div>
          <div class="flex justify-content-end">
            <a-pagination
              show-size-changer
              :current="pagination.current"
              :page-size="pagination.pageSize"
              :total="pagination.total"
              @showSizeChange="handlePaginationChange"
            /></div
        ></template>
      </div>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import {
  Button,
  Descriptions,
  Dropdown,
  Menu,
  Modal,
  notification,
  Pagination,
  Spin,
  Table,
  Tag,
  Upload,
  Icon,
} from "ant-design-vue";
import moment from "moment-timezone";
import { createNamespacedHelpers } from "vuex";
import DocumentSendModal from "@/components/send-modal.vue";
import ProfileCreateDocument from "./profile-create-document.vue";

const { mapState: profileState } = createNamespacedHelpers("profile");
const {
  mapActions: documentActions,
  mapState: documentState,
  mapGetters: documentGetters,
} = createNamespacedHelpers("document");

export default {
  components: {
    "a-table": Table,
    "a-button": Button,
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
    "a-tag": Tag,
    "a-upload": Upload,
    "a-modal": Modal,
    "a-pagination": Pagination,
    "a-spin": Spin,
    "a-dropdown-button": Dropdown.Button,
    "a-menu": Menu,
    "a-menu-item": Menu.Item,
    "a-icon": Icon,
    "documents-send": DocumentSendModal,
    "create-document": ProfileCreateDocument,
  },
  data() {
    return {
      visibleSendDocumentsModal: false,
      visibleCreateDocumentModal: false,

      sendDocumentId: undefined,

      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
      },

      documents: [],

      loadingSendDocument: false,
      loading: false,

      previewVisible: false,
      previewImage: "",

      loadingButtons: {},
    };
  },
  computed: {
    ...documentState({
      associateDocuments: (state) => state.associateDocuments,
    }),
    ...documentGetters(["getAssociateDocumentStatus"]),
    ...profileState({
      associate: (state) => state.associate,
    }),
  },
  watch: {
    associateDocuments: {
      handler(newVal) {
        this.pagination.total = newVal.total_count;
        this.documents = newVal.items ?? [];
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...documentActions([
      "getAssociateDocuments",
      "exportDocument",
      "deleteAssociateDocument",
    ]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    openCreateModal() {
      this.visibleCreateDocumentModal = true;
    },

    isExpired(expiration_date) {
      return moment().utc().isSameOrAfter(moment(expiration_date).utc(), "day");
    },
    handlePaginationChange(page, size) {
      const isPaginationChanged =
        page !== this.pagination.current || size !== this.pagination.pageSize;

      if (!isPaginationChanged) {
        return;
      }

      this.pagination.current = page;
      this.pagination.pageSize = size;
      this.fetchDocuments();
    },

    getFilesList(files) {
      return files
        .map((item) => ({
          uid: item.id,
          name: item.url,
          url: item.url,
          status: "done",
        }))
        .sort((a, b) => a.uid - b.uid);
    },

    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    async handleDownload(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }

      const downloadFile = (url, fileName) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName || "download";
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        link.remove();
      };

      if (file.url) {
        downloadFile(file.url, file.name || "download");
      } else if (file.preview) {
        const base64Data = file.preview.split(",")[1];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
          type: file.type || "application/octet-stream",
        });
        const url = URL.createObjectURL(blob);
        downloadFile(url, file.name || "download");
        setTimeout(() => URL.revokeObjectURL(url), 1000);
      }
    },

    handleCancel() {
      this.previewVisible = false;
    },

    formatDateString(dateString) {
      return moment(dateString).utc().format("MM/DD/YYYY");
    },

    openSendDocumentsModal(id) {
      this.visibleSendDocumentsModal = true;
      if (id) this.sendDocumentId = id;
    },

    closeSendDocumentsModal() {
      this.visibleSendDocumentsModal = false;
      this.sendDocumentId = undefined;
    },

    async downloadDocument(document_id) {
      try {
        const payload = {
          object_ids: [document_id],
          type: "associate_document",
          export_type: "download",
        };

        const { file_url } = await this.exportDocument(payload);

        window.open(file_url, "_blank");
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },

    async downloadAllDocuments() {
      try {
        const payload = {
          associate_id: this.associate.id,
          type: "associate_document",
          export_type: "download",
        };

        const { file_url } = await this.exportDocument(payload);

        window.open(file_url);
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },

    async sendDocuments(email) {
      const payload = {
        type: "associate_document",
        export_type: "send_email",
        email,
      };

      if (this.sendDocumentId) {
        payload.object_ids = [this.sendDocumentId];
      } else {
        payload.associate_id = this.associate.id;
      }

      this.loadingSendDocument = true;

      try {
        await this.exportDocument(payload);

        this.showNotification(
          "success",
          "Success",
          "Document successfully sent to email"
        );

        this.closeSendDocumentsModal();
      } catch (error) {
        this.showNotification("error", "Error", error || "An error occurred");
      } finally {
        this.loadingSendDocument = false;
      }
    },

    async fetchDocuments() {
      this.loading = true;

      const payload = {
        associate_id: this.associate.id,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };

      try {
        await this.getAssociateDocuments(payload);
      } catch (error) {
        this.showNotification("error", "Error", error || "An error occurred");
      } finally {
        this.loading = false;
      }
    },

    async deleteDocument(id) {
      this.$set(this.loadingButtons, id, true);

      try {
        await this.deleteAssociateDocument({
          document: id,
          associate_id: this.associate.id,
        });

        this.showNotification(
          "success",
          "Success",
          "Successfully deleted the document."
        );

        this.fetchDocuments();
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.$set(this.loadingButtons, id, false);
      }
    },
  },
};
</script>

<style scoped>
.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 480px;
  overflow: auto;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.ant-descriptions {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

::v-deep .ant-descriptions-item-label {
  font-weight: bold;
  border: none;
  background-color: white;
}

::v-deep .ant-descriptions-row {
  border: none;
}

::v-deep .ant-descriptions-item-content {
  overflow-x: auto;
}

::v-deep .ant-descriptions-view {
  padding: 16px;
}

::v-deep .ant-descriptions-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
