<template>
  <canvas ref="qrCanvas"></canvas>
</template>

<script>
import QRCode from "qrcode";

export default {
  props: {
    link: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {
          width: 300,
        };
      },
    },
  },
  mounted() {
    this.generateQRCode();
  },
  methods: {
    async generateQRCode() {
      try {
        const canvas = this.$refs.qrCanvas;
        await QRCode.toCanvas(canvas, this.link, this.options);
      } catch (error) {
        console.error("Error QR generation:", error);
      }
    },
  },
};
</script>

