import apiClient from "@/services/api";

export default {
  async getRefBook({ rootState }, { name }) {
    try {
      const { data } = await apiClient.get(`/api/refBook/${name}`, {
        params: {
          project_id: rootState.applicationState.currentProject.id,
        },
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg}  Please try again later`);
      }

      return data.items;
    } catch (error) {
      throw error.message;
    }
  },
  async postRefBook({ rootState }, { refBook, ...rest }) {
    try {
      const { data } = await apiClient.post(`/api/refBook/${refBook}`, {
        project_id: rootState.applicationState.currentProject.id,
        ...rest,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg}  Please try again later`);
      }

      return data.item;
    } catch (error) {
      throw error.message;
    }
  },
};
