import apiClient from "@/services/api";

export default {
  install(Vue, { router, store, notification }) {
    if (!router) {
      throw new Error("Router instance is required for permissionGuard plugin");
    }
    if (!store) {
      throw new Error(
        "Vuex store instance is required for permissionGuard plugin"
      );
    }

    function hasPermission(permissionName) {
      const userPermissions =
        store.state?.applicationState?.associate?.permissions || [];
      return userPermissions.some((perm) => perm.name === permissionName);
    }

    function hasAdminGroup() {
      const userPermissions =
        store.state?.applicationState?.associate?.permissions || [];
      return userPermissions.some((perm) => perm.group === "administrator");
    }

    router.beforeEach((to, from, next) => {
      if (to.path === "/auth") {
        return next();
      }

      if (
        store.state?.applicationState?.associate &&
        !hasAdminGroup() &&
        store.state?.applicationState?.token
      ) {
        apiClient
          .post("/sign_out")
          .then(() => {
            store.commit("setToken", null);
            next({ path: "auth" });
            notification.warning({
              message: "Access Denied",
              description:
                "You do not have sufficient permissions to access this page. Please contact your company management to obtain the necessary permissions. If the issue persists, please reach out to support.",
            });
          })
          .catch((error) => {
            next({ path: "auth" });
          });

        return;
      }

      if (to.meta && to.meta.permissions) {
        const requiredPermissions = Array.isArray(to.meta.permissions)
          ? to.meta.permissions
          : [to.meta.permissions];

        const hasAllPermissions = requiredPermissions.some((permission) =>
          hasPermission(permission)
        );

        if (!hasAllPermissions) {
          next({ name: "no-permissions" });
          return;
        }
      }

      next();
    });
  },
};
