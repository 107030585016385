export default {
  modals: {
    profile: {
      edit: () => {},
    },
    editProfile: {},
    shift: {},
    editShift: {},
  },
};
