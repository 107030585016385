<template>
  <div>
    <h2 class="view-header">
      <span class="flex gap-2 align-items-center justify-content-center">
        Associate Profiles
        <a-tooltip placement="right" v-if="tableData.length && !loading">
          <template slot="title"> Start Tour </template>
          <a-icon
            @click.stop="startTour()"
            class="flex align-items-center cursor-pointer"
            type="question-circle"
            theme="twoTone"
          />
        </a-tooltip>
      </span>
    </h2>
    <v-tour name="profilesTour" :steps="tourSteps" :options="vueTourOptions" />
    <send-notification
      :associate="associateProfileToSendSms"
      @close="closeSmsModal"
    />
    <add-note :associate="associateProfileToAddNote" @close="closeNoteModal" />
    <associate-filters
      :current-filters="filters"
      :visible.sync="visibleProfilesFilters"
    />
    <associate-profile-view
      :visible.sync="associateProfileModalVisible"
      :associate_number.sync="editingAssociateNumber"
      @edit="fetchAssociates"
    />
    <div class="table_controls_and_data">
      <div class="flex gap-2 align-items-center">
        <a-button
          icon="user"
          type="primary"
          @click="openNewUserWithAssociateView"
          >Add Associate</a-button
        >
      </div>

      <div class="flex gap-2 align-items-center">
        <a-badge :count="countFilters">
          <a-button icon="filter" @click="openFiltersModal"> Filters </a-button>
        </a-badge>

        <a-button
          icon="undo"
          :disabled="countFilters === 0"
          @click="resetAssociateFilters"
        >
          Reset Filters
        </a-button>
      </div>
    </div>
    <a-table
      rowKey="id"
      size="small"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      :pagination="pagination"
      @change="handleTableChange"
      :scroll="{ x: 1800, y: tableScrollHeight }"
      :customRow="
        (record) => ({
          on: {
            click: () => startEditAssociateProfile(record.associate_number),
          },
        })
      "
    >
      <span slot="associate" slot-scope="text, record" :key="record.photo_url">
        <grouped-associate-info
          :first-name="record.first_name"
          :last-name="record.last_name"
          :photo-url="record.photo_url"
          :associate-number="record.associate_number"
          :unverified="!record.is_verified"
        />
      </span>
      <span slot="isActive" slot-scope="text, record">
        <!-- <a-popover v-if="record.suspension" title="Suspension">
          <template slot="content">
            <p>
              <b>Author: </b>
              <span>
                {{
                  combineFullName(
                    record.suspension.author.first_name,
                    record.suspension.author.last_name
                  )
                }}
              </span>
            </p>
            <p>
              <b>Reason</b>
              <span>
                {{ record.suspension.reason }}
              </span>
            </p>
            <p>
              <b>Duration: </b>
              <span v-if="record.suspension.indefinite_period">
                Indefinite
              </span>
              <span v-else>
                {{ record.suspension.days_amount }}
              </span>
            </p>
            <p v-if="!record.suspension.indefinite_period">
              <b>Days left: </b>
              <span>
                {{ record.suspension.remaining_days }}
              </span>
            </p>
          </template>
          <a-tag :color="getAssociateStatus(record.status).color">{{
            getAssociateStatus(record.status).title
          }}</a-tag>
        </a-popover> -->
        <a-tag :color="getAssociateStatus(record.work_status).color">{{
          getAssociateStatus(record.work_status).title
        }}</a-tag>
      </span>
      <span slot="verified" slot-scope="text, record">
        <a-tag :color="getVerifiedTagColor(record)">{{
          record.is_verified ? "YES" : "NO"
        }}</a-tag>
      </span>
      <span slot="scheduleType" slot-scope="text, record">
        {{ renderScheduleType(record.schedule_type) }}
      </span>
      <span slot="payStatus" slot-scope="text, record">
        {{ renderPayStatus(record.payment_info.status) }}
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-button-group size="small">
          <a-tooltip>
            <template slot="title"> Open Profile </template>
            <a-button
              id="edit-associate-profile"
              icon="user"
              @click.stop="startEditAssociateProfile(record.associate_number)"
            />
          </a-tooltip>

          <a-tooltip v-if="hasPermission('manage_shifts')">
            <template slot="title"> Open Shifts </template>
            <a-button
              id="associate-shifts-btn"
              icon="profile"
              @click.stop="openAssociateShiftsList(record.id)"
            />
          </a-tooltip>

          <a-tooltip v-if="hasPermission('send_push_notification')">
            <template slot="title"> Send Message </template>
            <a-button
              id="send-message-btn"
              icon="message"
              @click.stop="openSmsSendModal(record)"
            />
          </a-tooltip>

          <a-tooltip>
            <template slot="title"> Open Note </template>
            <a-button
              id="note-btn"
              icon="snippets"
              @click.stop="openNoteModal(record)"
            />
          </a-tooltip>
        </a-button-group>
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  Table,
  Icon,
  Button,
  Modal,
  Checkbox,
  Tag,
  Popover,
  Tooltip,
  Badge,
  notification,
} from "ant-design-vue";
import api from "@/api";
import Util from "@/util";
import SendNotification from "@/components/send-notification.vue";
import AddNote from "@/components/add-note.vue";
import router from "@/router";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import AssociateProfilesFilters from "@/modules/profile/components/associate-profiles-filters.vue";
import tour from "../mixins/ProfilesTourMixin";
import table from "../mixins/ProfilesTableMixin";
import { createNamespacedHelpers } from "vuex";
import ProfileAssociateView from "@/modules/profile/components/profile-view.vue";
import { ASSOCIATE_STATUS } from "@/store/modules/profile/constants";
import permissionMixin from "@/mixins/permissionMixin";

const { mapState: searchState, mapActions: searchActions } =
  createNamespacedHelpers("search");

const ScheduleType = {
  FIXED: "fixed",
  SELF_SCHEDULE: "self_schedule",
  FREE: "free",
};

const PayStatus = Object.freeze({
  FULL_TIME: "full_time",
  PART_TIME: "part_time",
  ON_CALL: "on_call",
  TERMINATED: "terminated",
});

const StatusTagColors = Object.freeze({
  GRAY: "#b4b4b4",
  GREEN: "#34db30",
  RED: "#ff4646",
});

export default {
  components: {
    "a-table": Table,
    "a-icon": Icon,
    "a-badge": Badge,
    "a-button": Button,
    "a-button-group": Button.Group,
    "a-modal": Modal,
    "a-checkbox": Checkbox,
    "a-tag": Tag,
    "a-popover": Popover,
    "a-tooltip": Tooltip,
    "send-notification": SendNotification,
    "add-note": AddNote,
    "grouped-associate-info": GroupedAssociateInfo,
    "associate-filters": AssociateProfilesFilters,
    "associate-profile-view": ProfileAssociateView,
  },
  mixins: [api, tour, table, permissionMixin],
  props: {
    filters: {
      type: Object,
    },
  },
  data() {
    return {
      visibleProfilesFilters: false,

      editingAssociateNumber: "",

      loading: false,

      associateProfileToSendSms: undefined,
      associateProfileToAddNote: undefined,
      associateProfileModalVisible: false,
    };
  },
  watch: {
    filters: {
      handler(newVal, prevVal) {
        if (JSON.stringify(newVal) != JSON.stringify(prevVal)) {
          this.pagination.current = 1;
          this.fetchAssociates();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...searchState({
      tableData: (state) => state.associates,
    }),
    countFilters() {
      return Object.values(this.filters ?? {}).filter((value) => {
        return (
          value !== undefined &&
          value !== null &&
          value !== "" &&
          (!Array.isArray(value) || value.length > 0)
        );
      }).length;
    },
  },
  methods: {
    ...searchActions(["getAssociates"]),

    // filters
    openFiltersModal() {
      this.visibleProfilesFilters = true;
    },

    resetAssociateFilters() {
      this.loading = true;

      this.$router.replace({ query: {} }).catch(() => {});
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    async fetchAssociates() {
      this.loading = true;

      let skipFinally = false;

      const payload = {
        query: "/all",
        filter: this.filters,
        cancelPreviousRequests: true,
      };

      try {
        await this.getAssociates(payload);
      } catch (error) {
        if (error === "canceled") {
          skipFinally = true;
          this.loading = true;
          return;
        }
        this.showNotification("error", "Error", error);
      } finally {
        if (!skipFinally) this.loading = false;
      }
    },

    renderScheduleType(scheduleType) {
      switch (scheduleType) {
        case ScheduleType.FIXED:
          return "Strict";
        case ScheduleType.SELF_SCHEDULE:
          return "Flexible";
        case ScheduleType.FREE:
          return "Free";
      }
    },
    renderPayStatus(payStatus) {
      switch (payStatus) {
        case PayStatus.TERMINATED:
          return "Terminated";
        case PayStatus.ON_CALL:
          return "On Call";
        case PayStatus.PART_TIME:
          return "Part Time";
        case PayStatus.FULL_TIME:
          return "Full Time";
        default:
          return "N/A";
      }
    },

    startEditAssociateProfile(associateNumber) {
      this.editingAssociateNumber = associateNumber;
      this.associateProfileModalVisible = true;
    },

    openAssociateShiftsList(id) {
      this.$router
        .push({
          path: "/shifts",
          query: { associate_ids: [id] },
        })
        .catch(() => {});
    },

    handleEdited() {
      this.fetchAssociates();
    },

    combineFullName(firstName, lastName) {
      return Util.combineAssociateName(firstName, lastName);
    },

    openSmsSendModal(associate) {
      this.associateProfileToSendSms = associate;
    },
    closeSmsModal() {
      this.associateProfileToSendSms = undefined;
    },
    openNoteModal(associate) {
      this.associateProfileToAddNote = associate;
    },
    closeNoteModal() {
      this.associateProfileToAddNote = undefined;
    },
    getVerifiedTagColor(record) {
      return record.is_verified ? StatusTagColors.GREEN : StatusTagColors.RED;
    },
    getAssociateStatus(status) {
      return status
        ? ASSOCIATE_STATUS[status]
        : { title: "N/A", color: "#b4b4b4" };
    },
    handleTableChange(pagination) {
      const isPaginationChanged =
        pagination.current !== this.pagination.current ||
        pagination.pageSize !== this.pagination.pageSize;

      if (!isPaginationChanged) {
        return;
      }

      this.pagination = { ...pagination };
    },

    openNewUserWithAssociateView() {
      router.push("/create-user-with-associate");
    },
  },
  mounted() {
    this.calculateTableHeight();
    window.addEventListener("resize", this.calculateTableHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.calculateTableHeight);
  },
};
</script>
<style>
.associate-profile-modal-wrapper {
  overflow-x: auto;
  width: 100%;
  height: 600px;
}
</style>
