import apiClient from "@/services/api";

export default {
  async getAlerts({ rootState, commit }, { page, size, filter }) {
    try {
      const { data } = await apiClient.post(`/api/alert/project/get_all`, {
        page,
        size,
        project_id: rootState.applicationState.currentProject.id,
        filter,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg}. Please try again later`);
      }

      commit("setAlerts", data.items);

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async getAlert({ rootState, state, commit }, { id }) {
    if (state.alerts.some((item) => item.id === id && item.data)) {
      return;
    }

    try {
      const { data } = await apiClient.post(`/api/alert/${id}/get`, {
        project_id: rootState.applicationState.currentProject.id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg}. Please try again later`);
      }

      const oldItems = state.alerts;

      const newItems = [
        ...oldItems.map((item) => {
          if (item.id === id) {
            return data.alert;
          }

          return item;
        }),
      ];

      commit("setAlerts", newItems);

      commit("setAlert", data.alert);
    } catch (error) {
      throw error.message;
    }
  },
};
