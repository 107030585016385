<template>
  <div>
    <div class="kpi-controls flex gap-3 align-items-center content-view">
      <div class="flex gap-2">
        <b>In Range</b>
        <a-switch v-model="showRangeKpi" />
      </div>
      <div v-if="showRangeKpi" class="flex gap-2">
        <a-date-picker
          v-model="kpiFrom"
          :disabled-date="disabledStartDate"
          :format="visualDateFormat"
          placeholder="From"
        />
        <a-date-picker
          v-model="kpiTo"
          :disabled-date="disabledEndDate"
          :format="visualDateFormat"
          placeholder="To"
        />
      </div>
    </div>

    <div v-if="loading">
      <div class="flex align-items-center justify-content-center h-20rem">
        <a-spin />
      </div>
    </div>

    <associate-kpi v-else :kpi-stats="kpiStats" />
  </div>
</template>

<script>
import AssociateKpi from "@/components/associate-kpi.vue";
import util from "@/util";
import { DatePicker, notification, Spin, Switch } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const { mapActions: profileActions, mapState: profileState } =
  createNamespacedHelpers("profile");

export default {
  components: {
    "a-switch": Switch,
    "a-spin": Spin,
    "a-date-picker": DatePicker,
    "associate-kpi": AssociateKpi,
  },
  props: {
    associate_number: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      showRangeKpi: false,

      kpiTo: undefined,
      kpiFrom: undefined,

      loading: false,
    };
  },
  watch: {
    kpiFrom() {
      this.loadKpi();
    },
    kpiTo() {
      this.loadKpi();
    },
    showRangeKpi() {
      if (!this.showRangeKpi && (this.kpiFrom || this.kpiTo)) {
        this.kpiFrom = undefined;
        this.kpiTo = undefined;
      }
    },
    associate_number: {
      handler(newVal) {
        if (newVal) this.loadKpi();
      },
      immediate: true,
    },
  },
  computed: {
    ...profileState({
      kpiStats: (state) => state.associateKPI,
    }),

    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },

    kpiFromString() {
      if (this.kpiFrom === null) {
        return null;
      }

      return util.convertDateTimeToUTC(this.kpiFrom);
    },
    kpiToString() {
      if (this.kpiTo === null) {
        return null;
      }

      return util.convertDateTimeToUTC(this.kpiTo);
    },
  },
  methods: {
    ...profileActions(["getKPI"]),

    disabledStartDate(startValue) {
      const endValue = this.kpiTo;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.kpiFrom;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    async loadKpi() {
      this.loading = true;

      const payload = {
        associate_number: this.associate_number,
        start: this.kpiFromString,
        end: this.kpiToString,
      };

      try {
        await this.getKPI(payload);
      } catch (error) {
        notification["error"]({
          message: "KPI Load Error",
          description: error,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
