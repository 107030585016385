<template>
  <div>
    <a-modal
      v-model="visibleCloneModal"
      title="Create New Profile"
      width="1000px"
      :footer="null"
      :destroy-on-close="true"
    >
      <clone-associate
        :associate-number="associate_number"
        @close="endCloneAssociate"
      />
    </a-modal>

    <a-modal
      class="profile-modal"
      :visible="visible"
      :destroy-on-close="true"
      width="1300px"
      @cancel="closeProfileModal"
    >
      <template slot="title">
        <div v-if="associate">
          Associate Profile {{ associate.first_name }}
          {{ associate.last_name }} (ID: {{ associate.associate_number }})
          <template v-if="!associate.is_verified">
            | <span style="color: red">Uncompleted</span>
          </template>
        </div>
        <div v-else>Associate Profile</div>
      </template>

      <div
        v-if="!associate"
        class="loading-spin"
        style="height: 650px; background-color: white"
      >
        <a-spin />
      </div>

      <template v-else>
        <profile-edit
          v-if="associate"
          :visible.sync="visibleEditModal"
          @clone="openCloneModal"
          @edit="endEditAssociate"
        />

        <a-tabs v-model="activeKey" size="small" tabPosition="left">
          <a-tab-pane key="kpi">
            <span slot="tab">
              <a-icon type="percentage" />
              KPI
            </span>
            <profile-kpi :associate_number="associate_number" />
          </a-tab-pane>
          <a-tab-pane key="personal">
            <span slot="tab">
              <a-icon type="user" />
              Personal
            </span>
            <a-spin :spinning="loading">
              <profile-personal @clone="openCloneModal" />
            </a-spin>
          </a-tab-pane>
          <a-tab-pane key="job">
            <span slot="tab">
              <a-icon type="bank" />
              Job
            </span>
            <a-spin :spinning="loading">
              <profile-job />
            </a-spin>
          </a-tab-pane>
          <a-tab-pane key="write_up">
            <span slot="tab">
              <a-icon type="profile" />
              Write-Up
            </span>
            <a-spin :spinning="loading || loadingSections">
              <profile-write-up />
            </a-spin>
          </a-tab-pane>
          <a-tab-pane key="pay">
            <span slot="tab">
              <a-icon type="credit-card" />
              Pay
            </span>
            <a-spin :spinning="loading">
              <profile-pay />
            </a-spin>
          </a-tab-pane>
          <a-tab-pane key="signed_documents">
            <span slot="tab">
              <a-icon type="file-done" />
              Signed Documents
            </span>
            <a-spin :spinning="loading || loadingSections">
              <profile-sign-documents />
            </a-spin>
          </a-tab-pane>
          <a-tab-pane key="associate_documents">
            <span slot="tab">
              <a-icon type="file-done" />
              Attached Documents
            </span>
            <a-spin :spinning="loading || loadingSections">
              <profile-documents />
            </a-spin>
          </a-tab-pane>
          <a-tab-pane key="associate_onboarding">
            <span slot="tab">
              <a-icon type="file-done" />
              Onboarding
            </span>
            <profile-onboarding />
          </a-tab-pane>
          <a-tab-pane key="auth_types">
            <span slot="tab">
              <a-icon type="unordered-list" />
              Available Authorizations
            </span>
            <a-spin :spinning="loading">
              <div class="flex flex-column gap-2" v-if="associate">
                <h3>Available Authorizations</h3>
                <auth-info
                  :authorizations="associate.available_auth_types"
                  :is-disabled="true"
                />
              </div>
            </a-spin>
          </a-tab-pane>
          <a-tab-pane key="permissions">
            <span slot="tab">
              <a-icon type="file-protect" />
              Permissions
            </span>
            <a-spin :spinning="loading">
              <div class="flex flex-column gap-2" v-if="associate">
                <h3>Permissions</h3>
                <div class="w-6"><manager-scope-info /></div>
                <permissions-info
                  :permissions.sync="associate.permissions"
                  :is-disabled="true"
                />
              </div>
            </a-spin>
          </a-tab-pane>
        </a-tabs>
        <template slot="footer">
          <div class="flex justify-content-between">
            <a-button @click="closeProfileModal">Close</a-button>
            <div class="flex justify-content-end">
              <a-button
                @click="openEditModal"
                icon="form"
                type="primary"
                ghost
                >{{
                  associate.is_verified ? "Edit" : "Edit & Complete"
                }}</a-button
              >
            </div>
          </div>
        </template></template
      >
    </a-modal>
  </div>
</template>

<script>
import { Button, Icon, Modal, notification, Spin, Tabs } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";
import ProfileViewKpi from "./profile-view-kpi.vue";
import ProfileViewPersonal from "./profile-view-personal.vue";
import ProfileViewJob from "./profile-view-job.vue";
import ProfileViewPay from "./profile-view-pay.vue";
import ProfileAssociateClone from "./profile-associate-clone.vue";
import ProfileAssociateEdit from "./profile-associate-edit.vue";
import RolePermissions from "@/modules/roles/components/role-permissions.vue";
import ProfileAuthorizations from "./profile-authorizations.vue";
import ProfileManagerScope from "./profile-manager-scope.vue";
import ProfileViewWriteUp from "./profile-view-write-up.vue";
import ProfileViewSignDocuments from "./profile-view-sign-documents.vue";
import ProfileViewDocuments from "./profile-view-documents.vue";
import ProfileViewOnboarding from "./profile-view-onboarding.vue";

const { mapActions: profileActions, mapState: profileState } =
  createNamespacedHelpers("profile");
const { mapActions: documentActions } = createNamespacedHelpers("document");

export default {
  components: {
    "a-modal": Modal,
    "a-tabs": Tabs,
    "a-icon": Icon,
    "a-tab-pane": Tabs.TabPane,
    "a-button": Button,
    "a-spin": Spin,
    "profile-kpi": ProfileViewKpi,
    "profile-personal": ProfileViewPersonal,
    "profile-sign-documents": ProfileViewSignDocuments,
    "profile-documents": ProfileViewDocuments,
    "profile-onboarding": ProfileViewOnboarding,
    "profile-job": ProfileViewJob,
    "profile-pay": ProfileViewPay,
    "profile-write-up": ProfileViewWriteUp,
    "permissions-info": RolePermissions,
    "manager-scope-info": ProfileManagerScope,
    "auth-info": ProfileAuthorizations,
    "clone-associate": ProfileAssociateClone,
    "profile-edit": ProfileAssociateEdit,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    associate_number: {
      type: String,
      default: undefined,
    },
    edit: {
      type: Function,
      default: () => {},
    },
    openTab: {
      type: String,
      default: "personal",
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      loadingSections: false,

      visibleCloneModal: false,
      visibleEditModal: false,

      activeKey: "personal",
    };
  },
  watch: {
    visible: {
      handler(newVal) {
        if (newVal) {
          this.activeKey = this.openTab;
          this.visibleEditModal = this.isEditing;
        }
      },
      immediate: true,
    },
    associate_number: {
      handler(newVal) {
        if (newVal) {
          this.fetchAssociate();
        }
      },
      immediate: true,
    },
    refetchAssociate: {
      handler(newVal) {
        if (newVal) {
          this.fetchAssociate();
          this.$emit("edit");
        }
      },
    },
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
      refetchAssociate: (state) => state.refetchAssociate,
    }),
  },
  methods: {
    ...documentActions(["getAssociateDocuments"]),
    ...profileActions([
      "getAssociate",
      "getSignedDocuments",
      "getCreationContext",
      "getWriteUps",
      "reloadAssociate",
    ]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    openEditModal() {
      this.visibleEditModal = true;
    },

    openCloneModal() {
      this.visibleCloneModal = true;
    },

    endCloneAssociate(newAssociateNumber) {
      this.visibleCloneModal = false;
      this.$emit("update:associate_number", newAssociateNumber);
    },

    endEditAssociate(newAssociateNumber) {
      this.$emit("update:associate_number", newAssociateNumber);
    },

    closeProfileModal() {
      this.$emit("update:visible", false);
      this.$emit("update:associate_number", undefined);
    },

    async fetchAssociate() {
      this.loading = true;

      const payload = {
        associate_number: this.associate_number,
      };

      if (!this.associate_number) {
        return;
      }

      try {
        await this.getAssociate(payload);

        this.loading = false;
        this.loadingSections = true;

        await Promise.all([
          this.getSignedDocuments({ associate_id: this.associate.id }),
          this.getAssociateDocuments({ associate_id: this.associate.id }),
          this.getWriteUps({
            associate_number: this.associate_number,
          }),
        ]);
      } catch (error) {
        this.showNotification("error", "Error", error);
        this.closeProfileModal();
      } finally {
        this.loading = false;
        this.loadingSections = false;
        this.reloadAssociate(false);
      }
    },
  },
};
</script>

<style scoped>
.profile-modal ::v-deep .ant-modal-body {
  padding-left: 0;
}

.profile-modal ::v-deep .ant-tabs-tab {
  text-align: left !important;
}

.profile-modal ::v-deep .ant-tabs-tab-active {
  background-color: #e6f7ff;
}

.profile-modal ::v-deep .ant-tabs-content {
  height: 600px;
  overflow-y: auto;
  padding: 0 24px;
}
</style>
