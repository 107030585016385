var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-avatar"},[(_vm.isLoading)?_c('a-skeleton',{attrs:{"active":"","avatar":{
      size: _vm.width ?? _vm.defaultSideLengthPx,
      shape: 'circle',
    },"title":false,"paragraph":false}}):_c('img',{style:({
      width: String(_vm.width ?? _vm.defaultSideLengthPx) + 'px',
      height: String(_vm.height ?? _vm.defaultSideLengthPx) + 'px',
    }),attrs:{"alt":"User Avatar","src":_vm.imageSource}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }