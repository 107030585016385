<template>
  <a-modal
    v-model="timeOffRequestVisible"
    title="Time Off Request"
    width="500px"
    :footer="null"
    @cancel="handleCancel"
  >
    <div v-if="timeOffRequest" class="flex flex-column gap-2">
      <div class="content-view">
        <div class="content-view-block to-request-info">
          <grouped-associate-info
            class="to-request-grouped-info"
            :first-name="timeOffRequest.associate.first_name"
            :last-name="timeOffRequest.associate.last_name"
            :photo-url="timeOffRequest.associate.photo_url"
            :role="timeOffRequest.associate.role.name"
            :associate-number="timeOffRequest.associate.associate_number"
            :unverified="!timeOffRequest.associate.is_verified"
          />
          <p>
            <b>Dates</b>
            <span>{{ formatDates(timeOffRequest.dates) }}</span>
          </p>
          <p>
            <b>Type</b>
            <a-tag
              class="to-request-type-tag"
              :color="getTagColor(timeOffRequest.type)"
            >
              {{ convertTypeToText(timeOffRequest.type) }}
            </a-tag>
          </p>
          <p>
            <b>Subtype</b>
            {{ convertSubtypeToText(timeOffRequest.subtype) }}
          </p>
          <p>
            <b>Status</b>
            <a-tag
              class="to-request-type-tag"
              :color="getStatusColor(timeOffRequest.status)"
            >
              {{ convertStatusToText(timeOffRequest.status) }}
            </a-tag>
          </p>
          <div class="to-request-buttons" v-if="visibleButtons">
            <a-button
              type="danger"
              :loading="declineLoading"
              :disabled="disabledButtons"
              @click="declineRequest"
            >
              Decline
            </a-button>
            <a-button
              type="primary"
              :loading="acceptLoading"
              :disabled="disabledButtons"
              @click="acceptRequest"
            >
              Accept
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { Modal, Tag, Button, notification } from "ant-design-vue";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import Util from "@/util";
import {
  timeOffRequestTypeColorMap,
  timeOffRequestTypeMap,
  timeOffRequestSubtypeMap,
  timeOffRequestStatusMap,
} from "@/const";
import api from "@/api";

export default {
  components: {
    "a-modal": Modal,
    "a-tag": Tag,
    "a-button": Button,
    "grouped-associate-info": GroupedAssociateInfo,
  },
  mixins: [api],
  watch: {
    timeOffRequest() {
      this.timeOffRequestVisible = this.timeOffRequest != null;
    },
  },
  data() {
    return {
      timeOffRequestVisible: false,

      declineLoading: false,
      acceptLoading: false,
    };
  },
  props: {
    timeOffRequest: {
      type: Object,
      required: false,
    },
  },
  computed: {
    disabledButtons() {
      return this.declineLoading || this.acceptLoading;
    },

    visibleButtons() {
      return this.timeOffRequest.status !== "approved";
    },
  },
  methods: {
    formatDates: Util.formatDatesListForTimeOffRequestModal,

    handleCancel() {
      this.$emit("update:timeOffRequest", undefined);
    },

    convertTypeToText(timeOffType) {
      return timeOffRequestTypeMap[timeOffType] ?? timeOffType;
    },
    convertSubtypeToText(timeOffSubtype) {
      return timeOffRequestSubtypeMap[timeOffSubtype] ?? timeOffSubtype;
    },
    getTagColor(timeOffType) {
      return timeOffRequestTypeColorMap[timeOffType] ?? "#808080";
    },

    convertStatusToText(timeOffStatus) {
      return timeOffRequestStatusMap[timeOffStatus] ?? timeOffStatus;
    },

    getStatusColor(timeOffStatus) {
      switch (timeOffStatus) {
        case "pending":
          return "#52bfff";
        case "approved":
          return "#34db30";
        case "declined":
          return "#ff4646";
      }
    },

    acceptRequest() {
      this.acceptLoading = true;
      this.apiAcceptTimeOffRequest(this.timeOffRequest.id)
        .then((response) => {
          const data = response.data;

          if (data.error_code && data.error_code !== "0") {
            notification["warning"]({
              message: "Time Off Request Accept Error",
              description: data.msg ?? "Please try again later",
            });
            return;
          }

          notification["success"]({
            message: "Time Off Request Accepted",
          });

          this.handleCancel();
          this.$emit("resolved");
        })
        .finally(() => (this.acceptLoading = false));
    },
    declineRequest() {
      this.declineLoading = true;
      this.apiDeclineTimeOffRequest(this.timeOffRequest.id)
        .then((response) => {
          const data = response.data;

          if (data.error_code && data.error_code !== "0") {
            notification["warning"]({
              message: "Time Off Request Decline Error",
              description: data.msg ?? "Please try again later",
            });
            return;
          }

          notification["success"]({
            message: "Time Off Request Declined",
          });

          this.handleCancel();
          this.$emit("resolved");
        })
        .finally(() => (this.declineLoading = true));
    },
  },
  mounted() {
    if (this.timeOffRequest) {
      this.timeOffRequestVisible = true;
    }
  },
};
</script>

<style>
.to-request-info {
  max-width: unset;
  width: 100%;
}
.to-request-grouped-info {
  margin-bottom: 15px;
}

.to-request-buttons {
  display: flex;
  width: fit-content;
  gap: 1.5rem;

  margin: 15px auto auto;
}

.to-request-type-tag {
  margin-right: 0;
}
</style>
