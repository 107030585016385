export default {
  name: 'PermissionMixin',
  computed: {
    /**
     * Computed property to access user permissions from Vuex store.
     * @returns {Array} - Array of user permissions.
     */
    userPermissions() {
      return this.$store.state.applicationState.associate.permissions || [];
    },
  },
  methods: {
    /**
     * Checks if the user has a specific permission.
     * @param {string} permissionName - The name of the permission to check.
     * @returns {boolean} - Returns true if the user has the permission, otherwise false.
     */
    hasPermission(permissionName) {
      return this.userPermissions.some((perm) => perm.name === permissionName);
    },

    /**
     * Checks if the user has ALL specified permissions.
     * @param {string[]} permissionNames - An array of permission names to check.
     * @returns {boolean} - Returns true if the user has all the specified permissions, otherwise false.
     */
    hasAllPermissions(permissionNames) {
      return permissionNames.every((name) => this.hasPermission(name));
    },

    /**
     * Checks if the user has ANY of the specified permissions.
     * @param {string[]} permissionNames - An array of permission names to check.
     * @returns {boolean} - Returns true if the user has at least one of the specified permissions, otherwise false.
     */
    hasAnyPermission(permissionNames) {
      return permissionNames.some((name) => this.hasPermission(name));
    },

    /**
     * Checks if the user has a specific permission within a certain group.
     * @param {string} permissionName - The name of the permission to check.
     * @param {string} groupName - The group name to match.
     * @returns {boolean} - Returns true if the user has the permission in the specified group, otherwise false.
     */
    hasPermissionByGroup(permissionName, groupName) {
      return this.userPermissions.some(
        (perm) => perm.name === permissionName && perm.group === groupName
      );
    },
  },
};
