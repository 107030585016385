<template>
  <div>
    <h3>Time</h3>
    <div class="wrapper">
      <shift-time :title="'Scheduled'" :times="scheduledTime" />
      <shift-time :title="'Signed'" :times="signedTime" />
      <shift-time :title="'Actual'" :times="actualTime" />
    </div>
  </div>
</template>

<script>
import { Descriptions } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";
import shiftsHelper from "@/helpers/shifts";
import ShiftTime from "./shift-time.vue";

const { mapState: shiftState } = createNamespacedHelpers("shifts");

export default {
  components: {
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
    "shift-time": ShiftTime,
  },
  mixins: [shiftsHelper],
  computed: {
    ...shiftState({
      shift: (state) => state.shift.shift,
    }),

    scheduledTime() {
      return this.generateTimeData("scheduled_");
    },

    actualTime() {
      return this.generateTimeData("actual_");
    },

    signedTime() {
      return this.generateTimeData("");
    },
  },
  methods: {
    generateTimeData(prefix) {
      return {
        scheduled_start: this.shift.scheduled_start,
        scheduled_end: this.shift.scheduled_end,
        start:
          prefix === "scheduled_"
            ? this.shift[`${prefix}start`]
            : this.shift[`${prefix}work_start`],
        end:
          prefix === "scheduled_"
            ? this.shift[`${prefix}end`]
            : this.shift[`${prefix}work_end`],
        lunch_start: this.shift[`${prefix}lunch_start`] || null,
        lunch_end: this.shift[`${prefix}lunch_end`] || null,
        second_lunch_start: this.shift[`${prefix}second_lunch_start`] || null,
        second_lunch_end: this.shift[`${prefix}second_lunch_end`] || null,
      };
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.ant-descriptions {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}
</style>
