import { notification } from "ant-design-vue";

export function showActionNotification(
  message,
  buttons = [],
  description = "",
  $createElement,
  type = "success"
) {
  setTimeout(() => {
    const h = $createElement;

    const buttonElements = buttons.map((button) => {
      return h(
        "a-button",
        {
          type: button.type || "default",
          on: { click: () => handleButtonClick(button.action) },
        },
        button.text
      );
    });

    const handleButtonClick = (action) => {
      if (typeof action === "function") {
        action();
      }
      closeNotification();
    };

    let notificationInstance = null;

    const closeNotification = () => {
      if (notificationInstance) {
        notificationInstance.destroy();
      }
    };

    notificationInstance = notification[type]({
      message,
      description: h(
        "div",
        [
          description && h("p", {}, description),
          h("div", { class: "custom-notification-buttons" }, buttonElements),
        ].filter(Boolean)
      ),
      duration: 10,
      btn: null,
      onClose: closeNotification,
    });
  }, 100);
}
