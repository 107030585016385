<template>
  <a-modal
    title="Filters"
    width="600px"
    :visible="visible"
    @cancel="cancel"
    :destroy-on-close="true"
  >
    <div class="grid">
      <b class="col-4">Associates</b>
      <search-associates
        class="col-8"
        :is-associate-id="true"
        :multiply="true"
        :value.sync="filters.associates"
      />

      <b class="col-4">Qualification</b>
      <custom-select
        class="col-8"
        :multiple="true"
        :value.sync="filters.role"
        placeholder="Qualification"
        :options="roles"
        name="name"
        optionKey="id"
      />

      <b class="col-4">Agency</b>
      <custom-select
        class="col-8"
        :multiple="true"
        :value.sync="filters.agency"
        placeholder="Agency"
        :options="filteredAgencies"
        name="name"
        optionKey="id"
      />

      <b class="col-4">Department</b>
      <custom-select
        class="col-8"
        :multiple="true"
        :value.sync="filters.department"
        placeholder="Department"
        :options="departments"
        name="name"
        optionKey="id"
      />

      <b class="col-4">Status</b>
      <custom-select
        class="col-8"
        :multiple="false"
        :value.sync="filters.status"
        placeholder="Status"
        :options="statuses"
        name="name"
        optionKey="key"
      />

      <template v-if="isShowPaymentType">
        <b class="col-4">Payment Type</b>
        <custom-select
          class="col-8"
          :multiple="true"
          :value.sync="filters.payment_type"
          placeholder="Payment Type"
          :options="paymentTypes"
          name="name"
          optionKey="id"
        />
      </template>

      <div class="flex gap-2 flex-wrap mt-2">
        <a-button
          @click="toggleFilter('unverified')"
          :type="filters.unverified ? 'primary' : 'default'"
        >
          Uncompleted
        </a-button>
      </div>
    </div>
    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button type="danger" :disabled="isEmptyFilters" @click="reset">
          Reset </a-button
        ><a-button type="primary" @click="apply"> Apply </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { Modal, Button } from "ant-design-vue";
import SearchAssociates from "../../../components/search-associates.vue";
import util from "@/util";
import CustomSelect from "@/components/ui/custom-select.vue";

const filtersBaseState = Object.freeze({
  associates: undefined,
  status: undefined,
  role: undefined,
  unverified: false,
  agency: undefined,
  department: undefined,
  payment_type: undefined,
});

export default {
  components: {
    "a-modal": Modal,
    "a-button": Button,
    "custom-select": CustomSelect,
    "search-associates": SearchAssociates,
  },
  data() {
    return {
      filters: { ...filtersBaseState },
      roles: this.$store.state.applicationState.projectRoles,
      paymentTypes: this.$store.state.applicationState.paymentTypes.map(
        (item) => ({ name: util.capitalize(item.name), id: item.name })
      ),
      legalEntities: this.$store.state.applicationState.projectLegalEntities,
      departments: this.$store.state.applicationState.projectDepartments,
      statuses: [
        { key: "active", name: "Active" },
        { key: "suspended", name: "Suspended" },
        { key: "terminated", name: "Terminated" },
        { key: "disabled", name: "Disabled" },
      ],
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    currentFilters: {
      type: Object,
      required: true,
    },
  },
  watch: {
    currentFilters: {
      handler(newValue) {
        this.filters = {
          ...newValue,
          unverified: newValue.unverified ? true : undefined,
          role: newValue.role ? newValue.role.map(Number) : undefined,
          department: newValue.department
            ? newValue.department.map(Number)
            : undefined,
          payment_type: newValue.payment_type
            ? newValue.payment_type.map(Number)
            : undefined,
          agency: newValue.agency ? newValue.agency.map(Number) : undefined,
        };
      },
      immediate: true,
      deep: true,
    },
    "$store.state.applicationState.currentPaymentType": {
      handler() {
        if (this.filters.payment_type && !this.isShowPaymentType) {
          this.filters.payment_type = undefined;
          this.$router.replace({ query: this.filters }).catch(() => {});
        }
      },
      immediate: true,
    },
  },
  computed: {
    isEmptyFilters() {
      return JSON.stringify(filtersBaseState) == JSON.stringify(this.filters);
    },

    isShowPaymentType() {
      return (
        this.$store.state.applicationState.currentPaymentTypeName === "All"
      );
    },

    filteredAgencies() {
      return this.legalEntities.filter((item) => item.type === "agency");
    },
  },

  methods: {
    reset() {
      if (this.isEmptyFilters) {
        this.cancel();
      } else {
        this.filters = { ...filtersBaseState };
        this.$router.replace({ query: {} }).catch(() => {});
      }
    },
    cancel() {
      this.$emit("update:visible", false);
    },
    apply() {
      this.cancel();
      this.$router.replace({ query: this.filters }).catch(() => {});
    },
    toggleFilter(type) {
      this.filters[type] = this.filters[type] ? undefined : true;
    },
    capitalize: util.capitalize,
  },
};
</script>
