<template>
  <div class="grid">
    <div class="col">
      <div class="flex justify-content-between">
        <h3>Digital CDPH 530 Form</h3>
        <div class="flex gap-2">
          <a-button
            v-if="confirmation"
            icon="download"
            type="primary"
            @click="downloadConfirmation"
          >
            Download
          </a-button>

          <a-button
            v-else-if="project_id === 1"
            icon="plus"
            type="primary"
            @click="generateConfirmation"
          >
            Generate
          </a-button>
        </div>
      </div>
      <a-descriptions
        v-if="confirmation"
        :column="1"
        size="small"
        class="col-7 p-0"
      >
        <a-descriptions-item label="Work Zone">
          {{ confirmation?.zone?.name ?? " - " }}</a-descriptions-item
        >
        <a-descriptions-item label="Date"
          >{{ formatDateRange(confirmation.start, confirmation.end) }}
        </a-descriptions-item>
        <a-descriptions-item label="Lunch"
          >{{
            timeRangeToString(
              confirmation.lunch1_start,
              confirmation.lunch1_end
            )
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Second Lunch">
          {{
            timeRangeToString(
              confirmation.lunch2_start,
              confirmation.lunch2_end
            )
          }}</a-descriptions-item
        >
        <a-descriptions-item label="Supervisor">
          <grouped-associate-info
            v-if="confirmation?.supervisor"
            :first-name="confirmation.supervisor.first_name"
            :last-name="confirmation.supervisor.last_name"
            :photo-url="confirmation.supervisor.photo_url"
            :associate-number="confirmation.supervisor.associate_number"
            :role="confirmation.supervisor.role.name"
            :unverified="!confirmation.supervisor.is_verified"
          />
          <span v-else>-</span>
        </a-descriptions-item>
        <a-descriptions-item label="Supervisor Signature">
          <signature
            v-if="signature"
            :associate="signatureAssociate"
            :available-signature="signature"
        /></a-descriptions-item>
        <a-descriptions-item label="Created At">
          {{ formatDate(confirmation.created_at) }}</a-descriptions-item
        >
        <a-descriptions-item label="Updated At">
          {{ formatDate(confirmation.updated_at) }}</a-descriptions-item
        >
      </a-descriptions>
      <h4 v-else class="text-center">
        Shifts do not have a Digital CDPH 530 Form.
      </h4>
    </div>
  </div>
</template>

<script>
import { Button, Descriptions, notification } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";
import Signature from "@/components/signature.vue";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import util from "@/util";
import moment from "moment-timezone";
import shiftsHelper from "@/helpers/shifts";

const { mapState: shiftState, mapActions: shiftActions } =
  createNamespacedHelpers("shifts");

export default {
  components: {
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
    "a-button": Button,
    "grouped-associate-info": GroupedAssociateInfo,
    signature: Signature,
  },
  mixins: [shiftsHelper],

  data() {
    return {
      project_id: this.$store.state.applicationState.currentProject.id,
      timeZone: this.$store.state.applicationState.timeZone,
      dateTimeFormat: this.$store.state.applicationState.dateTimeFormat,
    };
  },
  computed: {
    ...shiftState({
      confirmation: (state) => state.shift.shift.shift_confirmation_form,
      shift: (state) => state.shift.shift,
    }),

    signatureAssociate() {
      return {
        name: util.combineAssociateName(
          this.confirmation.supervisor.first_name,
          this.confirmation.supervisor.last_name
        ),
        email: this.confirmation.supervisor.email,
        phone: this.confirmation.supervisor.phone,
      };
    },

    signature() {
      if (this.confirmation)
        return {
          image_url: this.confirmation.signature,
          signed_date: this.confirmation.confirmed_at,
        };

      return undefined;
    },
  },
  methods: {
    ...shiftActions(["getShiftConfirmation", "generateShiftConfirmation"]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    formatDate(date) {
      return moment.utc(date).tz(this.timeZone).format(this.dateTimeFormat);
    },

    async downloadConfirmation() {
      if (!this.confirmation.id) {
        this.showNotification(
          "error",
          "Error",
          "This confirmation can not be downloaded"
        );
      }

      try {
        const payload = {
          id: this.confirmation.id,
        };

        const link = await this.getShiftConfirmation(payload);

        window.open(link, "_blank");
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },

    async generateConfirmation() {
      try {
        const payload = {
          shifts: [this.shift.id],
        };

        await this.generateShiftConfirmation(payload);
        this.$emit("update");
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },

    timeRangeToString(start, end) {
      if (!start || !end) {
        return " - ";
      }

      const { start: startTime, end: endTime } = this.formatTimeRange(
        start,
        end
      );

      return `${startTime} - ${endTime}`;
    },
  },
};
</script>

<style scoped>
.ant-descriptions {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

::v-deep .ant-descriptions-item-label {
  font-weight: bold;
  border: none;
  background-color: white;
}

::v-deep .ant-descriptions-row {
  border: none;
}

::v-deep .ant-descriptions-item-content {
  text-align: right !important;
}

::v-deep .ant-descriptions-view {
  padding: 16px;
}

::v-deep .ant-descriptions-item {
  display: flex;
  justify-content: space-between;
}

.signature-description {
  background-color: red;
}
</style>
