import shiftsHelper from "@/helpers/shifts";
import util from "@/util";
import moment from "moment-timezone";
import { createNamespacedHelpers } from "vuex";

const { mapActions: shiftActions } = createNamespacedHelpers("shifts");

export default {
  mixins: [shiftsHelper],
  data() {
    return {
      loadingEstimatedLunches: {},
      estimatedLunches: {},

      pagination: {
        pageSizeOptions: ["10", "50", "100"],
        pageSize: 50,
        current: 1,
        total: 0,
        showSizeChanger: true,
      },

      tableScrollHeight: 0,

      columns: [
        {
          title: "Shift ID",
          dataIndex: "shift.id",
          key: "shiftId",
          align: "center",
          sorter: true,
        },
        {
          title: "Associate",
          key: "associate",
          scopedSlots: { customRender: "associate" },
          align: "center",
          sorter: true,
        },
        {
          title: "Date",
          key: "date",
          scopedSlots: { customRender: "date" },
          align: "center",
          sorter: true,
        },
        // {
        //   title: "Providers",
        //   key: "providers",
        //   scopedSlots: { customRender: "providers" },
        //   align: "center",
        // },
        // {
        //   title: "Confirmation",
        //   key: "confirmation",
        //   scopedSlots: { customRender: "confirmation" },
        //   align: "center",
        // },
        {
          title: "Authorizations",
          key: "authorizations",
          scopedSlots: { customRender: "authorizations" },
          align: "center",
        },
        {
          title: "History",
          key: "history",
          scopedSlots: { customRender: "history" },
        },
        {
          title: "Actions",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          align: "center",
        },
      ],

      nestedColumns: [
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
        },
        {
          title: "Date",
          key: "date",
          width: 300,
          customRender: (_, record) => {
            const { start, end } = record;
            const formatted =
              start && end ? this.formatDateRange(start, end) : " - ";

            return formatted;
          },
        },
        {
          title: "Start",
          key: "start",
          dataIndex: "start",
          customRender: (_, record) => {
            const colorClass =
              record.type === "Signed" && record.signed_start_diff_info
                ? record.signed_start_diff_info.color
                : "";

            const formatted = record.start
              ? this.formatDate(record.start)
              : " - ";

            const mainDiv = <div class={`${colorClass}`}>{formatted}</div>;

            if (record.signed_start_diff_info) {
              const tooltipTitle = `Signed start time differs ${record.signed_start_diff_info.difference} minutes from tracked time.`;
              return (
                <a-tooltip>
                  <template slot="title">{tooltipTitle}</template>
                  {mainDiv}
                </a-tooltip>
              );
            }

            return mainDiv;
          },
        },
        {
          title: "End",
          key: "end",
          dataIndex: "end",
          customRender: (_, record) => {
            const colorClass =
              record.type === "Signed" && record.signed_end_diff_info
                ? record.signed_end_diff_info.color
                : "";

            const formatted = record.end ? this.formatDate(record.end) : " - ";

            const mainDiv = <div class={`${colorClass}`}>{formatted}</div>;

            if (record.signed_end_diff_info) {
              const tooltipTitle = `Signed end time differs ${record.signed_end_diff_info.difference} minutes from tracked time`;
              return (
                <a-tooltip>
                  <template slot="title">{tooltipTitle}</template>
                  {mainDiv}
                </a-tooltip>
              );
            }

            return mainDiv;
          },
        },
        {
          title: "Dur",
          key: "duration",
          customRender: (_, record) => {
            const { start, end } = record;

            if (!start || !end) {
              return " - ";
            }

            const formatted = util.getFormattedDuration(start, end);

            return formatted;
          },
        },
        {
          title: "Work Hrs",
          key: "work_hours",
          customRender: (_, record) => {
            const {
              start,
              end,
              first_lunch_start,
              first_lunch_end,
              second_lunch_start,
              second_lunch_end,
            } = record;

            const formatted =
              this.formatWorkHours(
                start ? moment.utc(start) : null,
                end ? moment.utc(end) : null,
                first_lunch_start ? moment.utc(first_lunch_start) : null,
                first_lunch_end ? moment.utc(first_lunch_end) : null,
                second_lunch_start ? moment.utc(second_lunch_start) : null,
                second_lunch_end ? moment.utc(second_lunch_end) : null
              ) ?? " - ";

            return formatted;
          },
        },
        {
          title: "Pay Hrs",
          key: "pay_hours",
          customRender: (_, record) => {
            const {
              scheduled_start,
              scheduled_end,
              start,
              end,
              first_lunch_start,
              first_lunch_end,
              second_lunch_start,
              second_lunch_end,
            } = record;

            const formatted =
              this.formatPayHours(
                scheduled_start ? moment.utc(scheduled_start) : null,
                scheduled_end ? moment.utc(scheduled_end) : null,
                start ? moment.utc(start) : null,
                end ? moment.utc(end) : null,
                first_lunch_start ? moment.utc(first_lunch_start) : null,
                first_lunch_end ? moment.utc(first_lunch_end) : null,
                second_lunch_start ? moment.utc(second_lunch_start) : null,
                second_lunch_end ? moment.utc(second_lunch_end) : null
              ) ?? " - ";

            return formatted;
          },
        },
        {
          title: "L1 Start",
          key: "first_lunch_start",
          customRender: (_, record) => {
            const { first_lunch_start, start, end, lunch_waived } = record;

            const waivedAbility = this.isLunchWaiveAvailable(start, end);

            if (waivedAbility && lunch_waived) {
              return "Waived";
            }

            if (!start || !end) {
              return " - ";
            }

            const formatted = first_lunch_start
              ? this.formatDate(first_lunch_start)
              : "-";

            return formatted;
          },
        },
        {
          title: "L1 End",
          key: "first_lunch_end",
          customRender: (_, record) => {
            const { first_lunch_end, start, end, lunch_waived } = record;

            const waivedAbility = this.isLunchWaiveAvailable(start, end);

            if (waivedAbility && lunch_waived) {
              return "Waived";
            }

            if (!start || !end) {
              return " - ";
            }

            const formatted = first_lunch_end
              ? this.formatDate(first_lunch_end)
              : "-";

            return formatted;
          },
        },
        {
          title: "L1 Dur",
          customRender: (_, record) => {
            const {
              first_lunch_end,
              first_lunch_start,
              start,
              end,
              lunch_waived,
            } = record;

            const waivedAbility = this.isLunchWaiveAvailable(start, end);

            if (waivedAbility && lunch_waived) {
              return "Waived";
            }

            if (!start || !end || !first_lunch_start || !first_lunch_end) {
              return " - ";
            }

            const formatted = util.getFormattedDuration(
              first_lunch_start,
              first_lunch_end
            );

            return formatted;
          },
        },
        {
          title: "L2 Start",
          key: "second_lunch_start",
          customRender: (_, record) => {
            const {
              second_lunch_start,
              start,
              end,
              lunch_waived,
              first_lunch_end,
              first_lunch_start,
            } = record;

            const waivedAbility = this.isSecondLunchWaiveAvailable(
              start,
              end,
              first_lunch_start,
              first_lunch_end
            );

            if (waivedAbility && lunch_waived) {
              return "Waived";
            }

            if (!start || !end || !second_lunch_start) {
              return " - ";
            }

            const formatted = second_lunch_start
              ? this.formatDate(second_lunch_start)
              : "-";

            return formatted;
          },
        },
        {
          title: "L2 End",
          key: "second_lunch_end",
          customRender: (_, record) => {
            const {
              second_lunch_end,
              start,
              end,
              first_lunch_start,
              first_lunch_end,
              lunch_waived,
            } = record;

            const waivedAbility = this.isSecondLunchWaiveAvailable(
              start,
              end,
              first_lunch_start,
              first_lunch_end
            );

            if (waivedAbility && lunch_waived) {
              return "Waived";
            }

            if (!start || !end || !second_lunch_end) {
              return " - ";
            }

            const formatted = second_lunch_end
              ? this.formatDate(second_lunch_end)
              : "-";

            return formatted;
          },
        },
        {
          title: "L2 Dur",
          customRender: (_, record) => {
            const {
              second_lunch_start,
              second_lunch_end,
              start,
              end,
              first_lunch_start,
              first_lunch_end,
              lunch_waived,
            } = record;

            const waivedAbility = this.isSecondLunchWaiveAvailable(
              start,
              end,
              first_lunch_start,
              first_lunch_end
            );

            if (waivedAbility && lunch_waived) {
              return "Waived";
            }

            if (!start || !end || !second_lunch_start || !second_lunch_start) {
              return " - ";
            }

            const formatted = util.getFormattedDuration(
              second_lunch_start,
              second_lunch_end
            );

            return formatted;
          },
        },
      ],
    };
  },
  computed: {
    minWorkDuration() {
      return this.$store.state.applicationState.timeConstants.min_work_duration;
    },
    minWorkWithWaiverDuration() {
      return this.$store.state.applicationState.timeConstants
        .min_work_with_waiver_duration;
    },
    longWorkDuration() {
      return this.$store.state.applicationState.timeConstants
        .long_work_duration;
    },
    longWorkWithWaiverDuration() {
      return this.$store.state.applicationState.timeConstants
        .long_work_with_waiver_duration;
    },
  },
  methods: {
    ...shiftActions(["getEstimatedLunches"]),

    calculateTableHeight() {
      this.tableScrollHeight = window.innerHeight - 260;
    },

    isLunchWaiveAvailable(start, end) {
      if (!start || !end) {
        return false;
      }

      const duration = util.countDuration(start, end, "minutes");

      const calculation =
        duration >= this.minWorkDuration &&
        duration < this.minWorkWithWaiverDuration;

      return calculation;
    },

    isSecondLunchWaiveAvailable(
      start,
      end,
      first_lunch_start,
      first_lunch_end
    ) {
      if (!start || !end || !first_lunch_start || !first_lunch_end) {
        return false;
      }

      const duration = util.countDuration(start, end, "minutes");
      const lunchDuration = util.countDuration(
        first_lunch_start,
        first_lunch_end,
        "minutes"
      );

      const calculation =
        duration - lunchDuration >= this.longWorkDuration &&
        duration - lunchDuration < this.longWorkWithWaiverDuration;

      return calculation;
    },

    getSignedDiff(startTime, signedTime) {
      const differenceInMinutes = Math.abs(
        moment(startTime).diff(moment(signedTime), "minutes")
      );

      return {
        difference: differenceInMinutes,
        color: differenceInMinutes > 30 ? "orange-highlight" : null,
      };
    },

    formatDate(date) {
      return moment(date).format(this.$store.state.applicationState.timeFormat);
    },

    async handleExpand(expanded, record) {
      const shift_id = record.shift.id;

      if (!expanded || this.loadingEstimatedLunches[shift_id] === false) {
        return;
      }

      this.loadingEstimatedLunches[shift_id] = true;

      try {
        const payload = {
          associate_number: record.associate.associate_number,
          shift_start: record.shift.scheduled_start,
          shift_end: record.shift.scheduled_end,
          lunch_waived: false,
        };

        const data = await this.getEstimatedLunches(payload);

        this.$set(this.estimatedLunches, shift_id, { ...data });
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingEstimatedLunches[shift_id] = false;
      }
    },

    expandedRowRender(record) {
      const shift_id = record.shift.id;

      const nestedData = [
        {
          key: "scheduled",
          type: "Scheduled",
          start: record.shift.scheduled_start,
          end: record.shift.scheduled_end,
          scheduled_start: record.shift.scheduled_start,
          scheduled_end: record.shift.scheduled_end,
          first_lunch_start:
            this.estimatedLunches[shift_id]?.estimated_lunch_start,
          first_lunch_end: this.estimatedLunches[shift_id]?.estimated_lunch_end,
          second_lunch_start:
            this.estimatedLunches[shift_id]?.estimated_second_lunch_start,
          second_lunch_end:
            this.estimatedLunches[shift_id]?.estimated_second_lunch_end,
          lunch_waived: record.shift.lunch_waived,
        },
        {
          key: "actual",
          type: "Actual",
          start: record.shift.actual_work_start,
          end: record.shift.actual_work_end,
          scheduled_start: record.shift.scheduled_start,
          scheduled_end: record.shift.scheduled_end,
          first_lunch_start: record.shift.actual_lunch_start,
          first_lunch_end: record.shift.actual_lunch_end,
          second_lunch_start: record.shift.actual_second_lunch_start,
          second_lunch_end: record.shift.actual_second_lunch_end,
          lunch_waived: record.shift.lunch_waived,
        },
        {
          key: "signed",
          type: "Signed",
          start: record.shift.signed_start,
          end: record.shift.signed_end,
          scheduled_start: record.shift.scheduled_start,
          scheduled_end: record.shift.scheduled_end,
          first_lunch_start: record.shift.lunch_start,
          first_lunch_end: record.shift.lunch_end,
          second_lunch_start: record.shift.second_lunch_start,
          second_lunch_end: record.shift.second_lunch_end,
          signed_start_diff_info: this.getSignedDiff(
            record.shift.signed_start,
            record.shift.actual_work_start
          ),
          signed_end_diff_info: this.getSignedDiff(
            record.shift.signed_end,
            record.shift.actual_work_end
          ),
          lunch_waived: record.shift.lunch_waived,
        },
      ];

      return (
        <a-table
          size="small"
          columns={this.nestedColumns}
          row-key={String(shift_id)}
          dataSource={nestedData}
          pagination={false}
          loading={!this.estimatedLunches[shift_id]}
        />
      );
    },
  },
};
