<template>
  <a-select
    show-search
    :mode="multiple ? 'multiple' : 'default'"
    v-model="selectProxy"
    :allow-clear="allowClear"
    :filter-option="filterOption"
    :placeholder="placeholder"
    :disabled="disabled"
    @select="$emit('select', $event)"
  >
    <a-select-option v-if="multiple" key="select-all" value="select-all">
      {{ allSelected ? "Unselect All" : "Select All" }}
    </a-select-option>

    <a-select-option
      v-for="option in options"
      :key="option[optionKey]"
      :value="option[optionKey]"
      ><slot name="option" :option="option">
        {{ option[name] }}
      </slot>
    </a-select-option>
  </a-select>
</template>

<script>
import { Select } from "ant-design-vue";

export default {
  components: {
    "a-select": Select,
    "a-select-option": Select.Option,
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Array],
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    options: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: undefined,
    },
    optionKey: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowClear: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    allSelected() {
      return this.options.length === (this.value ?? []).length;
    },
    selectProxy: {
      get() {
        if (this.multiple) {
          return this.value ?? [];
        }

        if (
          this.value &&
          typeof this.value === "string" &&
          Number(this.value)
        ) {
          return Number(this.value);
        }

        return this.value ?? undefined;
      },
      set(newValue) {
        if (this.multiple) {
          const allKeys = this.options.map((item) => item[this.optionKey]);

          if (newValue.includes("select-all")) {
            if (newValue.length - 1 === allKeys.length) {
              this.$emit("update:value", []);
            } else {
              this.$emit("update:value", [...allKeys]);
            }
          } else {
            this.$emit(
              "update:value",
              newValue.filter((val) => val !== "select-all")
            );
          }
        } else {
          this.$emit("update:value", newValue);
        }
      },
    },
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
