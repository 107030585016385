export const PERMISSIONS = Object.freeze({
  manage_associates: {
    title: "Manage Associates",
    description: "Can view / edit associates' profiles.",
    group: "administrator",
  },
  manage_shifts: {
    title: "Manage Shifts",
    description: "Can view / edit associates' shifts.",
    group: "administrator",
  },
  manage_documents: {
    title: "Manage Documents",
    description:
      "Can view / edit / add / send to sign documents. Can manage Onboarding / Departure process for associates.",
    group: "administrator",
  },
  manage_authorizations: {
    title: "Manage Authorizations",
    description:
      "Can view / accept / deny action authorizations from associates.",
    group: "administrator",
  },
  manage_project: {
    title: "Manage Company",
    description: "Can edit company settings and information.",
    group: "administrator",
  },
  resolve_alerts: {
    title: "Resolve Alerts",
    description: "Can view / resolve any company's alerts.",
    group: "administrator",
  },
  validate_payments: {
    title: "Validate Payments",
    description: "Can validate associates' payment requests.",
    group: "administrator",
  },
  process_payments: {
    title: "Process Payments",
    description: "Can process associates' payment requests.",
    group: "administrator",
  },
  send_push_notification: {
    title: "Send Announcement",
    description: "Can send announcements to associates.",
    group: "supervisor",
  },
  floating_work_area: {
    title: "Floating Work Area",
    description: "Can work from any location.",
    group: "supervisor",
  },
  ignore_shift_time_auths: {
    title: "Ignore Clock on / out Authorizations",
    description: "Associate can ignore shift start / end authorizations.",
    group: "supervisor",
  },
  invite_user: {
    title: "Invite User",
    description: "Can invite users to company.",
    group: "worker",
  },
  require_attach_files: {
    title: "Requires Additional Info",
    description: "Associate should attach files when signing a shift.",
    group: "worker",
  },
  shifts_from_scheduler: {
    title: "Shifts from Scheduler App",
    description: "Associate can get shifts from Scheduler App",
    group: "worker",
  },
  mobile_time_tracking: {
    title: "Mobile Time Tracking",
    description:
      "Associate can clock in / clock out using mobile app. Otherwise associate required to use terminal.",
    group: "worker",
  },
  require_shift_confirmation: {
    title: "Require CDPH 530 Form",
    description:
      "Associate is required to get the shift confirmed before signing it.",
    group: "worker",
  },
  manual_shift: {
    title: "Create Shifts Manually",
    description:
      "Associate may / may not have the ability to create Manual shifts.",
    group: "worker",
  },
  adjust_shift: {
    title: "Only Actual Time for Sign Shift",
    description:
      "Associate can edit the Actual time in both directions when signing and adjusting a Shift. When disabled, Associate will not be able to increase the Actual time.",
    group: "worker",
  },
});

export const PERMISSION_GROUPS = Object.freeze({
  WORKER: "worker",
  SUPERVISOR: "supervisor",
  ADMINISTRATOR: "administrator",
});
