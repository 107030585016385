import { DOCUMENT_STATUS, DOCUMENT_TYPE } from "./constants";

export default {
  getAssociateDocumentStatus() {
    return (status) => {
      return (
        DOCUMENT_STATUS[status] ?? {
          title: status,
          color: DOCUMENT_STATUS.active.color,
        }
      );
    };
  },
  getDocumentType() {
    return (type) => {
      return (
        DOCUMENT_TYPE[type] ?? {
          title: type,
          color: DOCUMENT_TYPE.text.color,
          icon: DOCUMENT_TYPE.text.icon,
        }
      );
    };
  },
};
