<template>
  <a-modal
    title="Attach Document"
    width="600px"
    :visible="visible"
    :destroy-on-close="true"
    @cancel="closeModal"
  >
    <a-form-model
      layout="horizontal"
      ref="form"
      class="flex gap-2 flex-column"
      :model="form"
      :rules="rules"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Name"
        prop="name"
      >
        <a-input v-model="form.name" placeholder="My worker license" />
      </a-form-model-item>
      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Details"
        prop="description"
      >
        <a-textarea
          v-model="form.description"
          placeholder="My license to work on both sides"
        />
      </a-form-model-item>
      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Choose Other Type"
      >
        <a-switch v-model="form.other" />
      </a-form-model-item>

      <a-form-model-item
        v-if="form.other"
        :colon="false"
        label-align="left"
        label="Other Type"
        prop="type"
      >
        <a-input v-model="form.type" placeholder="Other Type" />
      </a-form-model-item>
      <a-form-model-item
        v-else
        :colon="false"
        label-align="left"
        label="Type"
        prop="type"
      >
        <custom-select
          :multiple="false"
          :value.sync="form.type"
          placeholder="Type"
          :options="types"
          name="name"
          optionKey="id"
        />
      </a-form-model-item>
      <a-form-model-item
        v-if="!noNumber"
        :colon="false"
        label-align="left"
        label="Document Number"
        prop="number"
      >
        <a-input v-model="form.number" placeholder="Document Number" />
      </a-form-model-item>
      <a-form-model-item
        v-if="!noDate"
        :colon="false"
        label-align="left"
        label="Expiration Date"
        prop="expiration_date"
      >
        <a-date-picker
          format="MM/DD/YYYY"
          placeholder="Expiration Date"
          v-model="form.expiration_date"
          valueFormat="YYYY-MM-DD"
        />
      </a-form-model-item>
      <a-checkbox :checked="noDate" @change="noDate = $event.target.checked">
        No Expiration Date
      </a-checkbox>
      <a-checkbox
        :checked="noNumber"
        @change="noNumber = $event.target.checked"
      >
        No Document Number
      </a-checkbox>
      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Required For Work"
        prop="required_for_work"
      >
        <a-switch v-model="form.required_for_work" />
      </a-form-model-item>
      <a-form-model-item
        prop="files"
        :colon="false"
        label-align="left"
        label="Attachments"
      >
        <document-attachments
          :with-title="false"
          :files="[]"
          @save="saveAttachmentsIds"
        />
      </a-form-model-item>
    </a-form-model>

    <template slot="footer">
      <div class="flex justify-content-between">
        <a-button @click="closeModal"> Cancel </a-button>
        <a-button icon="save" type="primary" @click="save" :loading="loading">
          Save
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import CustomSelect from "@/components/ui/custom-select.vue";
import {
  Button,
  FormModel,
  Modal,
  Input,
  notification,
  DatePicker,
  Checkbox,
  Switch,
} from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";
import ShiftFormAttachments from "@/modules/shifts/components/shift-form-attachments.vue";
import moment from "moment-timezone";

const { mapActions: documentActions } = createNamespacedHelpers("document");
const { mapActions: refBookActions } = createNamespacedHelpers("refbook");
const { mapState: profileState } = createNamespacedHelpers("profile");

const initialForm = {
  name: undefined,
  description: undefined,
  type: undefined,
  number: undefined,
  expiration_date: undefined,
  required_for_work: false,
  other: false,
  files: [],
};

const initialRules = {
  name: [
    {
      required: true,
      message: "Name can not be empty",
      trigger: "change",
    },
  ],
  type: [
    {
      required: true,
      message: "Type can not be empty",
      trigger: "change",
    },
  ],
  number: [
    {
      required: true,
      message: "Number can not be empty",
      trigger: "change",
    },
  ],
  expiration_date: [
    {
      required: true,
      message: "Expiration date can not be empty",
      trigger: "change",
    },
  ],
  files: [
    {
      required: true,
      message: "Files can not be empty",
      trigger: "change",
      type: "array",
    },
  ],
};

export default {
  components: {
    "a-button": Button,
    "a-textarea": Input.TextArea,
    "a-modal": Modal,
    "a-date-picker": DatePicker,
    "a-form-model": FormModel,
    "a-form-model-item": FormModel.Item,
    "a-input": Input,
    "a-checkbox": Checkbox,
    "a-switch": Switch,
    "custom-select": CustomSelect,
    "document-attachments": ShiftFormAttachments,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visiblePasswordModal: false,
      loading: false,

      form: { ...initialForm },
      rules: {
        ...initialRules,
      },

      types: [],
      noNumber: false,
      noDate: false,
    };
  },
  watch: {
    "form.other": {
      handler(newVal) {
        this.form.type = undefined;
      },
    },
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
    }),
  },
  methods: {
    ...documentActions(["createAssociateDocument"]),
    ...refBookActions(["getRefBook", "postRefBook"]),

    closeModal() {
      this.$emit("update:visible", false);
      this.form = { ...initialForm };
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    async saveAttachmentsIds(ids) {
      this.form.files = ids;
    },

    save() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;

        this.loading = true;

        try {
          let type = this.form.type;

          if (this.form.other) {
            const data = await this.postRefBook({
              name: this.form.type,
              refBook: "AssociateDocumentType",
            });

            this.types.push(data);

            type = data.id;
          }

          const payload = {
            document: {
              ...this.form,
              type,
              files: this.attachmentsIds,
              expiration_date: this.noDate
                ? undefined
                : moment(this.form.expiration_date)
                    .utc()
                    .startOf("day")
                    .toISOString(),
              number: this.noNumber ? undefined : this.form.number,
              created_at: moment().utc().startOf("day").toISOString(),
            },
            associate_id: this.associate.id,
          };

          await this.createAssociateDocument(payload);

          this.showNotification(
            "success",
            "Success",
            "Associate document has been created successfully."
          );

          this.closeModal();
        } catch (error) {
          this.showNotification("error", "Error", error.message || error);
        } finally {
          this.loading = false;
        }
      });
    },

    async fetchTypes() {
      this.loading = true;

      const payload = {
        name: "AssociateDocumentType",
      };

      try {
        const data = await this.getRefBook(payload);

        this.types = data;
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    if (!this.types.length) await this.fetchTypes();
  },
};
</script>

<style scoped>
.ant-row {
  display: flex;
  margin: 0 !important;
}

.ant-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}

.ant-calendar-picker {
  width: 100%;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0;
}
</style>
