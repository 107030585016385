import {
  SHIFT_PAYMENT_REQUEST_STATUS,
  SHIFT_STATUS,
  SHIFT_PAYMENT_STATUS,
  AUTH_STATUSES,
  SHIFT_ORIGIN,
} from "./constants";

export default {
  getActiveShiftId(state) {
    return state.shift.shift?.id ?? undefined;
  },
  getShiftPaymentRequestStatus() {
    return (status) => {
      return (
        SHIFT_PAYMENT_REQUEST_STATUS[status] ?? {
          title: status,
          color: SHIFT_PAYMENT_REQUEST_STATUS.draft.color,
        }
      );
    };
  },
  getShiftPaymentStatus() {
    return (status) => {
      return (
        SHIFT_PAYMENT_STATUS[status] ?? {
          title: status,
          color: SHIFT_PAYMENT_STATUS.not_paid.color,
        }
      );
    };
  },
  getShiftStatus() {
    return (status) => {
      return (
        SHIFT_STATUS[status] ?? {
          title: status,
          color: SHIFT_STATUS.scheduled.color,
          text: "",
        }
      );
    };
  },
  getAuthType() {
    return (type) => {
      return (
        AUTH_STATUSES[type] ?? {
          title: type,
          color: AUTH_STATUSES.no_lunch_authorization.color,
          text: "",
        }
      );
    };
  },
  getOrigin() {
    return (origin) => {
      return origin
        ? SHIFT_ORIGIN[origin] ?? SHIFT_ORIGIN.whereami
        : SHIFT_ORIGIN.whereami;
    };
  },
};
