export default {
  setGroups(state, payload) {
    state.groups = payload;
  },
  setDocument(state, payload) {
    state.document = payload;
  },
  setAssociateDocuments: (state, payload) =>
    (state.associateDocuments = payload),
};
