var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"Move Shifts","visible":_vm.visible,"destroy-on-close":true,"width":"600px"},on:{"cancel":_vm.closeModal}},[_c('a-form-model',{ref:"formRef",attrs:{"layout":"horizontal","model":_vm.form,"rules":_vm.rules,"label-col":{ span: 8 },"wrapper-col":{ span: 16 }}},[_c('a-form-model-item',{attrs:{"colon":false,"label-align":"left","label":"Holder","prop":"holder_id"}},[_c('search-associates',{attrs:{"is-associate-id":true,"value":_vm.form.holder_id},on:{"update:value":function($event){return _vm.$set(_vm.form, "holder_id", $event)}}})],1),_c('a-form-model-item',{attrs:{"colon":false,"label-align":"left","label":"Assignee","prop":"assignee_id"}},[_c('a-select',{attrs:{"dropdown-match-select-width":false,"allow-clear":true,"disabled":!_vm.form.holder_id,"placeholder":_vm.loadingProfiles ? 'Loading...' : 'Assignee',"loading":_vm.loadingProfiles,"option-label-prop":"children"},scopedSlots:_vm._u([{key:"notFoundContent",fn:function(){return [_c('span',{staticClass:"text-center"},[_vm._v(" The associate does not have any other profiles "),_c('br'),_vm._v(" to move shifts to")])]},proxy:true}]),model:{value:(_vm.form.assignee_id),callback:function ($$v) {_vm.$set(_vm.form, "assignee_id", $$v)},expression:"form.assignee_id"}},_vm._l((_vm.profiles),function(profile){return _c('a-select-option',{key:profile.id,attrs:{"value":profile.id}},[_vm._v(" "+_vm._s(`[${profile.associate_number}] ${profile.first_name} ${profile.last_name}`)+" ")])}),1)],1)],1),_c('a-table',{attrs:{"row-selection":{
      selectedRowKeys: _vm.form.shift_ids,
      onChange: _vm.onSelectChange,
      getCheckboxProps: (record) => ({
        props: { disabled: record.shift.status === 'deleted' },
      }),
    },"rowKey":(record) => record.shift.id,"locale":{ emptyText: 'Shifts Not Found' },"loading":_vm.loadingShifts,"size":"small","pagination":_vm.pagination,"columns":_vm.columns,"data-source":_vm.associateShifts,"scroll":{ y: 200 }},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"date",fn:function(text, record){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatDate(record.shift.scheduled_start))+" - "+_vm._s(_vm.formatDate(record.shift.scheduled_end))+" ")])}},{key:"status",fn:function(text, record){return _c('span',{},[_c('a-tooltip',[_c('a-tag',{staticClass:"auth-tag",attrs:{"color":record.shift.status !== 'completed'
              ? _vm.getShiftStatus(record.shift.status).color
              : _vm.getShiftPaymentRequestStatus(record.shift.payment_status)
                  .color}},[_vm._v(" "+_vm._s(record.shift.status !== "completed" ? _vm.getShiftStatus(record.shift.status).title : _vm.getShiftPaymentRequestStatus(record.shift.payment_status) .title)+" ")])],1)],1)}}])},[_c('template',{slot:"footer"},[(_vm.hasSelected)?_c('span',[_vm._v(_vm._s(`Selected ${_vm.form.shift_ids.length} shifts`))]):_vm._e()])],2),_c('template',{slot:"footer"},[_c('div',{staticClass:"flex justify-content-between"},[_c('a-button',{on:{"click":_vm.closeModal}},[_vm._v("Close")]),_c('div',{staticClass:"flex justify-content-end"},[(_vm.hasEmptyProfiles)?_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" The associate does not have any other profiles to move shifts to. ")]),_c('a-button',{attrs:{"disabled":_vm.hasEmptyProfiles,"icon":"export","type":"primary","ghost":""},on:{"click":_vm.submit}},[_vm._v(" Move ")])],2):_c('a-button',{attrs:{"disabled":_vm.hasEmptyProfiles,"icon":"export","type":"primary","ghost":""},on:{"click":_vm.submit}},[_vm._v(" Move ")])],1)],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }