<template>
  <div>
    <a-modal v-model="visibleQrModal" :footer="null" :destroy-on-close="true">
      <div class="flex justify-content-center">
        <qr-code-generator :link="baseUrl" />
      </div>
    </a-modal>
    <div class="flex justify-content-between align-items-center mb-3">
      <h3 class="m-0">Attachments</h3>
      <div class="flex gap-2">
        <a-button
          @click="reloadAttachments"
          type="primary"
          icon="retweet"
          ghost
        ></a-button>
        <a-button
          v-if="baseUrl"
          @click="openQrModal"
          type="primary"
          icon="plus"
          ghost
          >Add Attachments via Cell Phone</a-button
        >
      </div>
    </div>
    <shift-attachments
      :with-title="false"
      :files="files"
      @save="saveAttachmentsIds"
    />
  </div>
</template>

<script>
import { Button, Modal, notification, Table } from "ant-design-vue";
import ShiftFormAttachments from "./shift-form-attachments.vue";
import { createNamespacedHelpers } from "vuex";
import QrCodeGenerator from "@/components/qr-code-generator.vue";
import moment from "moment-timezone";

const { mapActions, mapGetters, mapState } = createNamespacedHelpers("shifts");

export default {
  components: {
    "a-table": Table,
    "a-button": Button,
    "a-modal": Modal,
    "shift-attachments": ShiftFormAttachments,
    "qr-code-generator": QrCodeGenerator,
  },
  data() {
    return {
      attachmentsIds: [],
      visibleQrModal: false,

      baseUrl: undefined,
    };
  },
  computed: {
    ...mapGetters(["getActiveShiftId"]),
    ...mapState({
      shift: (state) => state.shift,
      files: (state) => state.shift.shift.files,
    }),
  },
  watch: {
    files: {
      handler(newVal) {
        this.attachmentsIds = newVal.map((item) => item.id);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions(["updateShiftAttachments", "getShiftAttachments"]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    openQrModal() {
      this.visibleQrModal = true;
    },

    closeQrModal() {
      this.visibleQrModal = false;
    },

    async reloadAttachments() {
      try {
        const payload = {
          id: this.shift.shift.id,
        };

        await this.getShiftAttachments(payload);
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },

    async saveAttachmentsIds(ids) {
      this.attachmentsIds = [...ids.map((item) => item.id)];

      try {
        const payload = {
          files: this.attachmentsIds,
          shift_id: this.getActiveShiftId,
        };

        await this.updateShiftAttachments(payload);

        this.showNotification(
          "success",
          "Success",
          "Attachments were successfully changed"
        );
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },
  },

  mounted() {
    const path = this.$store.state.applicationState.isProd
      ? window.location.origin
      : "https://qa.epeople.com";

    this.baseUrl = `${path}/whereami/#/attach-files?associate_id=${
      this.shift.associate.id
    }&shift_id=${this.shift.shift.id}&created_at=${moment()
      .utc()
      .seconds(0)
      .format()}`;
  },
};
</script>
