import apiClient from "@/services/api";

export default {
  async getAllSequence({ rootState, commit }, { page, size, filter, type }) {
    try {
      const { data } = await apiClient.post(`/api/sequence/get_all`, {
        page,
        size,
        type,
        project_id: rootState.applicationState.currentProject.id,
        filter,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg}. Please try again later`);
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  setSequence({ commit }, { sequence }) {
    commit("SET_SEQUENCE", sequence);
  },

  async getSequence({ commit }, { sequence_id }) {
    try {
      const { data } = await apiClient.post(`/api/sequence/get`, {
        sequence_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg}. Please try again later`);
      }

      commit("SET_SEQUENCE", data);
    } catch (error) {
      throw error.message;
    }
  },

  async postSequence(
    { commit, rootState },
    { update_active_request, sequence }
  ) {
    try {
      const { data } = await apiClient.post(`/api/sequence/set`, {
        update_active_request: null,
        sequence,
        project_id: rootState.applicationState.currentProject.id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg}. Please try again later`);
      }

      commit("SET_SEQUENCE", data.sequence);

      return data.sequence;
    } catch (error) {
      throw error.message;
    }
  },

  async deleteSequence(_, { sequence_id }) {
    try {
      const { data } = await apiClient.delete(`/api/sequence/${sequence_id}`);

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg}. Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },
};
