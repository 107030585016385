export const DOCUMENT_TYPE = Object.freeze({
  text: {
    title: "Text",
    icon: "file-text",
    color: "blue",
  },
  pdf: {
    title: "PDF",
    icon: "file-pdf",
    color: "red",
  },
  video: {
    title: "Video",
    icon: "file-image",
    color: "green",
  },
  survey: {
    title: "Survey",
    icon: "file-unknown",
    color: "purple",
  },
});

export const DOCUMENT_STATUS = Object.freeze({
  active: {
    title: "Active",
    color: "green",
  },
  expired: {
    title: "Expired",
    color: "orange",
  },
  deprecated: {
    title: "Deprecated",
    color: "blue",
  },
  deleted: {
    title: "Deleted",
    color: "red",
  },
});

export const DOCUMENT_PLACEHOLDERS = Object.freeze({
  full_name: {
    placeholder: "#FullName",
    text: "Full Name",
  },
  first_name: {
    placeholder: "#FirstName",
    text: "First Name",
  },
  last_name: {
    placeholder: "#LastName",
    text: "Last Name",
  },
  associate_phone_number: {
    placeholder: "#AssociatePhoneNumber",
    text: "Associate Phone Number",
  },
  associate_email: {
    placeholder: "#AssociateEmail",
    text: "Associate Email",
  },
  associate_id: {
    placeholder: "#AssociateId",
    text: "Associate ID",
  },
  associate_address: {
    placeholder: "#AssociateAddress",
    text: "Associate Address",
  },
  associate_current_location: {
    placeholder: "#AssociateCurrentLocation",
    text: "Associate Current Location",
  },
  qualification: {
    placeholder: "#Qualification",
    text: "Qualification",
  },
  department: {
    placeholder: "#Department",
    text: "Department",
  },
  supervisor: {
    placeholder: "#Supervisor",
    text: "Supervisor",
  },
  employment_type: {
    placeholder: "#EmploymentType",
    text: "Employment Type",
  },
  hourly_rate: {
    placeholder: "#HourlyRate",
    text: "Hourly Rate",
  },
  social_security_number: {
    placeholder: "#SocialSecurityNumber",
    text: "Social Security Number",
  },
  license_number: {
    placeholder: "#LicenseNumber",
    text: "License Number",
  },
  license_expiration_date: {
    placeholder: "#LicenseExpirationDate",
    text: "License Expiration Date",
  },
  today_date: {
    placeholder: "#TodayDate",
    text: "Today Date",
  },
  date_of_hire: {
    placeholder: "#DateOfHire",
    text: "Date of Hire",
  },
  termination_date: {
    placeholder: "#TerminationDate",
    text: "Termination Date",
  },
  employer_name: {
    placeholder: "#EmployerName",
    text: "Employer Name",
  },
  employer_email: {
    placeholder: "#EmployerEmail",
    text: "Employer Email",
  },
  employer_qualification: {
    placeholder: "#EmployerQualification",
    text: "Employer Qualification",
  },
  company_name: {
    placeholder: "#CompanyName",
    text: "Company Name",
  },
  company_address: {
    placeholder: "#CompanyAddress",
    text: "Company Address",
  },
  date_of_birth: {
    placeholder: "#DateOfBirth",
    text: "Date of Birth",
  },
  associate_address_street: {
    placeholder: "#AssociateAddressStreet",
    text: "Associate Address Street",
  },
  associate_address_state: {
    placeholder: "#AssociateAddressState",
    text: "Associate Address State",
  },
  associate_address_city: {
    placeholder: "#AssociateAddressCity",
    text: "Associate Address City",
  },
  associate_address_zip: {
    placeholder: "#AssociateAddressZip",
    text: "Associate Address Zip",
  },
  hourly_rate_ot: {
    placeholder: "#HourlyRateOt",
    text: "Hourly Rate OT",
  },
  employer_representative_name: {
    placeholder: "#EmployerRepresentativeName",
    text: "Employer Representative Name",
  },
  employer_representative_role: {
    placeholder: "#EmployerRepresentativeRole",
    text: "Employer Representative Role",
  },
  average_rate: {
    placeholder: "#AvgRate",
    text: "Average Rate",
  },
  end_contract_period: {
    placeholder: "#EndContractPeriod",
    text: "End Contract Period",
  },
  associate_legal_entity_name: {
    placeholder: "#AssociateLegalEntityName",
    text: "Associate Legal Entity Name",
  },
  associate_legal_entity_type: {
    placeholder: "#AssociateLegalEntityType",
    text: "Associate Legal Entity Type",
  },
  associate_legal_entity_ein: {
    placeholder: "#AssociateLegalEntityEIN",
    text: "Associate Legal Entity EIN",
  },
  associate_legal_entity_address: {
    placeholder: "#AssociateLegalEntityAddress",
    text: "Associate Legal Entity Address",
  },
  associate_initials: {
    placeholder: "#AssociateInitials",
    text: "Associate Initials",
  },
  first_shift_date: {
    placeholder: "#FirstShiftDate",
    text: "First Shift Date",
  },
  signed_date: {
    placeholder: "#SignedDate",
    text: "Signed Date",
  },
  associate_signature: {
    placeholder: "#AssociateSignature",
    text: "Associate Signature",
  },
  company_ein: {
    placeholder: "#CompanyEIN",
    text: "Company EIN",
  },
  employer_signature: {
    placeholder: "#EmployerSignature",
    text: "Employer Signature",
  },
  supervisor_signature: {
    placeholder: "#SupervisorSignature",
    text: "Supervisor Signature",
  },
  employer_representative_signature: {
    placeholder: "#EmployerRepresentativeSignature",
    text: "Employer Representative Signature",
  },
  employer_initials: {
    placeholder: "#EmployerInitials",
    text: "Employer Initials",
  },
  supervisor_initials: {
    placeholder: "#SupervisorInitials",
    text: "Supervisor Initials",
  },
  employer_representative_initials: {
    placeholder: "#EmployerRepresentativeInitials",
    text: "Employer Representative Initials",
  },
  hourly_rate_dt: {
    placeholder: "#HourlyRateDt",
    text: "Hourly Rate Dt",
  },
  hourly_rate_ot: {
    placeholder: "#HourlyRateOt",
    text: "Hourly Rate Ot",
  },
  return_date: {
    placeholder: "#ReturnDate",
    text: "Return Date",
  },
  min_work_duration: {
    placeholder: "#MinWorkDuration",
    text: "Minimum Work Duration",
  },
  min_work_with_waive_duration: {
    placeholder: "#MinWorkWithWaiveDuration",
    text: "Minimum Work with Waive Duration",
  },
  long_work_duration: {
    placeholder: "#LongWorkDuration",
    text: "Long Work Duration",
  },
  lunch_duration: {
    placeholder: "#LunchDuration",
    text: "Lunch Duration",
  },
  second_lunch_duration: {
    placeholder: "#SecondLunchDuration",
    text: "Second Lunch Duration",
  },
});
