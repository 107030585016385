export const ALERT_TYPES = Object.freeze({
  validate_joined_associate: {
    title: "Validate Associate",
    color: "#9E9E9E",
  },
  associate_joined: {
    title: "Associate Joined",
    color: "#4CAF50",
  },
  associate_left: {
    title: "Associate Left",
    color: "#4CAF50",
  },
  join_project_request: {
    title: "Join Request",
    color: "#FFC107",
  },
  project_summary: {
    title: "Company Summary",
    color: "#FFC107",
  },
  sequence_completed: {
    title: "Sequence Completed",
    color: "#FF9800",
  },
  unauthorized_authorizations: {
    title: "Unauthorized Authorizations",
    color: "#FF9800",
  },
  document_signed: {
    title: "Document Signed",
    color: "#F44336",
  },
  associate_document_expired: {
    title: "Associate document expired",
    color: "#F44336",
  },
  associate_inactive: {
    title: "Associate Disabled",
    color: "#03A9F4",
  },
  associate_resigned: {
    title: "Associate Resigned",
    color: "#4CAF50",
  },
});

export const SEVERITY_TYPES = Object.freeze({
  normal: {
    title: "Info",
    color: "#34db30",
  },
  warning: {
    title: "Warning",
    color: "#ffb546",
  },
  forbid_work: {
    title: "Critical",
    color: "#ff4646",
  },
});
