<template>
  <div id="app">
    <app-layout>
      <router-view />
      <modals />
    </app-layout>
  </div>
</template>

<script>
import api from "@/api";
import AppLayout from "@/components/app-layout.vue";
import { notification } from "ant-design-vue";
import Modals from "./components/modals.vue";
import { createNamespacedHelpers } from "vuex";

const { mapActions: modalActions } = createNamespacedHelpers("modals");

export default {
  components: {
    "app-layout": AppLayout,
    modals: Modals,
  },
  data() {
    return {
      version: null,
      checkInterval: 300000, // 5 minutes
    };
  },
  mixins: [api],
  watch: {
    $route: {
      handler(to, _) {
        const { info_text, tab, editingAssociate } = to.query;

        if (info_text) {
          notification["info"]({
            message: "Info",
            description: info_text,
          });
        }

        if (editingAssociate) {
          this.openModal({
            type: "profile",
            data: {
              visible: true,
              associate_number: editingAssociate,
              edit: () => {},
              open_tab: tab,
              is_editing: false,
            },
          });
        }

        if (info_text || tab || editingAssociate) {
          this.$router.replace({ query: {} }).catch(() => {});
        }
      },
      immediate: true,
      deep: true,
    },
    "$store.state.applicationState.currentProject.id": {
      handler(id) {
        if (id) {
          this.$router.replace({ query: {} }).catch(() => {});
        }
      },
      deep: true,
    },
  },
  methods: {
    ...modalActions(["openModal"]),
    checkVersion() {
      if (!this.$store.state.applicationState.token) {
        return;
      }

      this.apiGetFrontendVersion().then((response) => {
        const data = response.data;

        if (!data.version) {
          return;
        }

        if (this.version && this.version != data.version) {
          notification["info"]({
            message: "Web Application Update",
            description: "The page will refresh in 5 minutes",
            duration: 300, // also 5 minutes
          });

          setTimeout(() => window.location.reload(), this.checkInterval);
        }

        this.version = data.version;
      });
    },
  },
  mounted() {
    this.interval = setInterval(this.checkVersion, this.checkInterval);
  },
};
</script>

<style>
@import "~semantic-ui-css/semantic.min.css";
@import "./assets/css/main.css";
@import "~primeflex/primeflex.css";

#app {
  font-family: "Proxima Nova", Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
</style>
