<template>
  <div v-if="loading" class="loading-spin">
    <a-spin size="large" />
  </div>
  <div v-else>
    <a-modal
      title="You can disable previous associate"
      v-model="openedDisablePreviousAssociateModal"
      :footer="null"
    >
      <div class="flex flex-column gap-4">
        <div>
          <p>
            You are about to set a new associate with a different pay type or
            role.
          </p>
          <p>
            You have the option to disable the previous associate profile if it
            is no longer needed.
          </p>
        </div>
        <div class="flex gap-2 justify-content-end">
          <a-button @click="cloneAssociate(false)">Leave The Account</a-button>
          <a-button @click="cloneAssociate(true)" type="danger"
            >Disable Previous Associate</a-button
          >
        </div>
      </div>
    </a-modal>
    <a-form layout="inline" :form="form">
      <div class="content-view">
        <div class="content-view-block">
          <h3>Personal Info</h3>
          <p>
            <b>Full Name</b>
            <span>{{ associate.first_name }} {{ associate.last_name }}</span>
          </p>
          <p>
            <b>Email</b>
            <span>{{ associate.email }}</span>
          </p>
          <p>
            <b>Phone Number</b>
            <span>{{ associate.phone }}</span>
          </p>
          <p>
            <b>Birth Date</b>
            <span>{{ birthDate }}</span>
          </p>
          <p>
            <b>Address</b>
            <span>{{ address }}</span>
          </p>

          <h3>Company Info</h3>
          <p>
            <b class="associate-number-label"
              >Associate Number <span class="required-field">*</span></b
            >
            <a-form-item required>
              <a-input
                v-decorator="[
                  'associateNumber',
                  {
                    rules: [
                      { required: true, message: associateNumberSuggestionMsg },
                      {
                        len: 6,
                        message: 'The associate number must be 6 characters.',
                      },
                      { validator: associateNumberValidator },
                    ],
                  },
                ]"
                placeholder="Enter associate number"
              />
            </a-form-item>
          </p>

          <p>
            <b>Qualification <span class="required-field">*</span></b>
            <a-form-item required>
              <a-select
                v-decorator="[
                  'projectRole',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a qualification!',
                      },
                    ],
                  },
                ]"
                placeholder="Select qualification"
              >
                <a-select-option
                  v-for="role in projectRoles"
                  :key="role.id"
                  :value="role.id"
                  >{{ role.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </p>

          <div class="view-button-group">
            <a-form-item>
              <a-button
                type="primary"
                :loading="loadingForm"
                :disabled="enabledCloneButton"
                @click="toggleDisableSuggestionModal"
              >
                Create New Profile
              </a-button>
            </a-form-item>
          </div>
        </div>
        <div class="content-view-block">
          <h3>Payment Info</h3>
          <p>
            <b>Payment Type <span class="required-field">*</span></b>
            <a-form-item>
              <a-select
                v-decorator="[
                  'paymentType',
                  {
                    rules: [{ required: true }],
                  },
                ]"
                placeholder="Select PT"
                @change="handlePaymentTypeChange"
              >
                <a-select-option
                  v-for="type in paymentTypes"
                  :key="type.id"
                  :value="type.id"
                  >{{ type.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </p>

          <p>
            <b>Payment Status</b>
            <a-form-item>
              <a-select
                v-decorator="[
                  'paymentStatus',
                  {
                    rules: [{ required: false }],
                    initialValue: undefined,
                  },
                ]"
                placeholder="Select Payment Status"
              >
                <a-select-option
                  v-for="status in paymentStatuses"
                  :key="status.id"
                  :value="status.id"
                  >{{ status.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </p>

          <template v-if="isEmployeePaymentTypeSelected">
            <p>
              <b>Payment Method</b>
              <a-form-item>
                <a-select
                  v-decorator="[
                    'paymentMethod',
                    {
                      rules: [{ required: false }],
                    },
                  ]"
                  placeholder="Select PM"
                >
                  <a-select-option
                    v-for="method in paymentMethods"
                    :key="method.id"
                    :value="method.id"
                    >{{ method.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </p>

            <p>
              <b>Hourly Rate, $</b>
              <a-form-item>
                <a-input-number
                  v-decorator="[
                    'hourly_rate',
                    { rules: [{ required: false }], initialValue: undefined },
                  ]"
                  placeholder="
                    Hourly Rate
                  "
                  :min="0"
                />
              </a-form-item>
            </p>
          </template>

          <template v-if="isContractorPaymentTypeSelected">
            <p>
              <b>Agency<span class="required-field">*</span></b>
              <a-form-item>
                <a-select
                  v-decorator="[
                    'legalEntity',
                    {
                      rules: [
                        {
                          required: isContractorPaymentTypeSelected,
                          message: 'Select an legal entity',
                        },
                      ],
                    },
                  ]"
                  placeholder="Please select agency"
                >
                  <a-select-option
                    v-for="agency in agencies"
                    :key="agency.id"
                    :value="agency.id"
                  >
                    {{ agency.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </p>

            <p>
              <a-form-item>
                <a-radio-group
                  v-decorator="[
                    'is_legal_entity_admin',
                    { rules: [{ required: false }], initialValue: false },
                  ]"
                >
                  <a-radio-button :value="true"> Admin </a-radio-button>
                  <a-radio-button :value="false"> Associate </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </p>

            <p>
              <b>Hourly Rate, $</b>
              <a-form-item>
                <a-input-number
                  v-decorator="[
                    'hourly_rate',
                    { rules: [{ required: false }], initialValue: 0 },
                  ]"
                  placeholder="Enter rate"
                  :min="0"
                />
              </a-form-item>
            </p>
          </template>

          <p>
            <b>Allow Instant Payment</b>
            <span>
              <a-form-item>
                <a-checkbox
                  v-decorator="[
                    'allowInstantPayment',
                    {
                      rules: [
                        {
                          required: false,
                          type: 'boolean',
                          initialValue: true,
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </span>
          </p>
        </div>
      </div>
    </a-form>
  </div>
</template>

<script>
import {
  Card,
  Form,
  Input,
  InputNumber,
  Select,
  notification,
  Button,
  Checkbox,
  Tooltip,
  Modal,
  Spin,
  Radio,
} from "ant-design-vue";
import Util from "@/util";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";
import constData from "@/helpers/const-data";

const { mapActions: profileActions, mapState: profileState } =
  createNamespacedHelpers("profile");

const PaymentType = Object.freeze({
  EMPLOYEE: 3,
  CONTRACTOR: 6,
  VOLUNTEER: 5,
});

const PaymentStatus = Object.freeze({
  FULL_TIME: "full_time",
  PART_TIME: "part_time",
  ON_CALL: "on_call",
  TERMINATED: "terminated",
});

const PaymentMethod = Object.freeze({
  HOURLY: 0,
  SALARY: 1,
});

export default {
  components: {
    "a-card": Card,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-input": Input,
    "a-input-password": Input.Password,
    "a-input-number": InputNumber,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-button": Button,
    "a-checkbox": Checkbox,
    "a-tooltip": Tooltip,
    "a-modal": Modal,
    "a-spin": Spin,
    "a-radio-group": Radio.Group,
    "a-radio-button": Radio.Button,
  },
  props: ["associateNumber"],
  data() {
    return {
      form: undefined,
      hourly_rate: undefined,
      paymentTypes: [
        { id: PaymentType.EMPLOYEE, name: "Employee" },
        { id: PaymentType.CONTRACTOR, name: "Contractor" },
        { id: PaymentType.VOLUNTEER, name: "Volunteer" },
      ],
      paymentStatuses: [
        { id: PaymentStatus.FULL_TIME, label: "Full Time" },
        { id: PaymentStatus.PART_TIME, label: "Part Time" },
        { id: PaymentStatus.ON_CALL, label: "On Call" },
        { id: PaymentStatus.TERMINATED, label: "Terminated" },
      ],
      paymentMethods: [
        { id: PaymentMethod.HOURLY, label: "Hourly" },
        { id: PaymentMethod.SALARY, label: "Salary" },
      ],

      isEmployeePaymentTypeSelected: false,
      isContractorPaymentTypeSelected: false,

      associateNumberSuggestionMsg:
        "Please enter associate number of new associate",

      associateNumberErrorMsg: "Associate number must be exactly 6 digits.",

      loadingForm: false,

      enabledCloneButton: false,

      openedDisablePreviousAssociateModal: false,

      loading: false,

      statesList: constData.usStates,
      countriesList: constData.countries,
    };
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
    }),

    birthDate() {
      return this.associate.birth_date
        ? moment(this.associate.birth_date).format(
            this.$store.state.applicationState.dateFormat
          )
        : "-";
    },

    address() {
      const { address } = this.associate;

      if (!address) {
        return " - ";
      }

      const country = this.countriesList.find(
        (country) => country.code === address.country
      );

      const state = this.statesList.find(
        (state) => state.code === address.state
      );

      const parts = [
        address?.address_line1,
        address?.address_line2,
        address?.zipcode,
        address?.city,
        state?.name,
        country?.name,
      ];

      return parts.filter((part) => part).join(", ");
    },

    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    serverDateFormat() {
      return this.$store.state.applicationState.serverDateFormat;
    },
    projectRoles() {
      return this.$store.state.applicationState.projectRoles;
    },
    agencies() {
      return this.$store.state.applicationState.currentProject.legal_entities;
    },
  },
  methods: {
    ...profileActions([
      "getAssociate",
      "checkAvailableAssociateNumber",
      "postCloneAssociate",
    ]),

    showNotification(type, message, description) {
      notification[type]({
        message: message,
        description: description,
      });
    },

    getFieldValue(name) {
      return this.form.getFieldValue(name);
    },

    async fetchAssociate() {
      this.loading = true;

      const payload = {
        associate_number: this.associateNumber,
      };

      if (!this.associateNumber) {
        return;
      }

      try {
        await this.getAssociate(payload);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async associateNumberValidator(rule, value, callback) {
      if (value.length !== 6) {
        return;
      }

      try {
        const result = await this.checkAvailableAssociateNumber({
          associate_number: value,
        });

        if (result.owner) {
          callback(
            new Error(
              `This associate number is already occupied by another user in this company. You can use available associate number: ${result.available_number} `
            )
          );
        }
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },

    handlePaymentTypeChange(value) {
      switch (value) {
        case PaymentType.EMPLOYEE:
          this.isEmployeePaymentTypeSelected = true;
          this.isContractorPaymentTypeSelected = false;
          break;
        case PaymentType.CONTRACTOR:
          this.isEmployeePaymentTypeSelected = false;
          this.isContractorPaymentTypeSelected = true;
          break;
        default:
          this.isEmployeePaymentTypeSelected = false;
          this.isContractorPaymentTypeSelected = false;
      }
    },

    cloneAssociate(withDisablePrevious) {
      this.form.validateFields(async (err, values) => {
        this.openedDisablePreviousAssociateModal = false;

        if (err) {
          this.showIncorrectFormError();
          return;
        }

        this.loadingForm = true;

        const newAssociateNumber = values.associateNumber;

        const legalEntity = this.isContractorPaymentTypeSelected
          ? this.agencies.find((agency) => agency.id == values.legalEntity)
          : undefined;

        const associateInformation = {
          associate_number: this.associateNumber,
          automatic_completing_actions:
            this.associate.automatic_completing_actions,
          schedule_type: this.associate.schedule_type,
          role: { id: values.projectRole },
          allow_instant_payment: values.allowInstantPayment ?? false,
          payment_type: Object.keys(PaymentType).find(
            (key) => PaymentType[key] === values.paymentType
          ),
          pay_status: values.paymentStatus,
          pay_method: values.paymentMethod
            ? Object.keys(PaymentMethod)
                .find((key) => PaymentMethod[key] === values.paymentMethod)
                .toLowerCase()
            : "hourly",
          hourly_rate: values.hourly_rate
            ? Util.convertMoneyToDb(values.hourly_rate)
            : 0,
          legal_entity: this.isContractorPaymentTypeSelected
            ? { ...legalEntity }
            : undefined,
          is_verified: true,
        };

        if (
          !associateInformation.legal_entity &&
          this.isContractorPaymentTypeSelected
        ) {
          let message;

          if (!values.hourly_rate) {
            message = `Hourly rate for Agency "${this.legalEntity.name}" is not specified!`;
          }

          this.showIncorrectFormError(message);
          this.loadingForm = false;

          return;
        }

        const payload = {
          new_associate_number: newAssociateNumber,
          associate: associateInformation,
          disable_previous_associate: withDisablePrevious,
          is_legal_entity_admin: values.is_legal_entity_admin,
        };

        try {
          await this.postCloneAssociate(payload);
          notification.success({
            message: "Success",
            description: "New profile successfully created",
          });

          this.endCloneAssociate(newAssociateNumber);
        } catch (error) {
          notification.error({
            message: "Error",
            description: error,
          });
        } finally {
          this.loadingForm = false;
        }
      });
    },
    showIncorrectFormError(msg) {
      notification.warning({
        message: "Incorrect form",
        description:
          msg ??
          "Some fields are not filled in or are filled in incorrectly. Please check the fields",
      });
    },
    endCloneAssociate(newAssociateNumber) {
      this.$emit("close", newAssociateNumber);
    },

    toggleDisableSuggestionModal() {
      this.openedDisablePreviousAssociateModal =
        !this.openedDisablePreviousAssociateModal;
    },
  },
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "create_user_form" });
  },
  mounted() {
    this.fetchAssociate();
  },
};
</script>

<style scoped>
.content-view-block {
  min-width: 400px;
}

.create-user-with-associate-controls {
  padding: 10px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.associate-number-label {
  word-wrap: normal;
}
</style>

<style lang="css" scoped src="../../../assets/css/form-styles.css" />
