<template>
  <a-modal title="Filters" width="600px" v-model="visible" @cancel="apply">
    <div class="content-view">
      <div class="content-view-block w-full">
        <p>
          <b>Alert Type</b>
          <a-select
            class="filters-select"
            v-model="filters.alertType"
            :allow-clear="true"
            placeholder="Type"
          >
            <a-select-option
              v-for="type in alertTypes"
              :key="type.key"
              :value="type.key"
            >
              {{ type.title }}
            </a-select-option>
          </a-select>
        </p>
        <p>
          <b>Alert Severity</b>
          <a-select
            class="filters-select"
            v-model="filters.alertSeverity"
            :allow-clear="true"
            placeholder="Severity"
          >
            <a-select-option
              v-for="item in alertSeverities"
              :key="item.key"
              :value="item.key"
            >
              {{ item.title }}
            </a-select-option>
          </a-select>
        </p>
      </div>
    </div>
    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button type="danger" :disabled="disableResetButton" @click="reset">
          Reset
        </a-button>
        <a-button type="primary" @click="apply"> Apply </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { Modal, Button, Select } from "ant-design-vue";
import api from "@/api";
import { ALERT_TYPES, SEVERITY_TYPES } from "@/store/modules/alerts/constants";

const filtersBaseState = Object.freeze({
  alertType: undefined,
  alertSeverity: undefined,
});

export default {
  components: {
    "a-modal": Modal,
    "a-button": Button,
    "a-select": Select,
    "a-select-option": Select.Option,
  },
  mixins: [api],
  data() {
    return {
      filters: { ...filtersBaseState },
      foundAssociates: [],
      loading: false,
      searchUsersTimeout: null,

      alertSeverities: Object.entries(SEVERITY_TYPES).map(([key, value]) => ({
        key,
        ...value,
      })),
      alertTypes: Object.entries(ALERT_TYPES).map(([key, value]) => ({
        key,
        ...value,
      })),
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    currentFilters: {
      type: Object,
      required: true,
    },
  },
  computed: {
    disableResetButton() {
      return JSON.stringify(filtersBaseState) == JSON.stringify(this.filters);
    },
  },
  watch: {
    visible(newVal) {
      this.localVisible = newVal;
    },
    localVisible(newVal) {
      this.$emit("update:visible", newVal);
    },
  },
  methods: {
    reset() {
      this.filters = { ...filtersBaseState };
    },
    apply() {
      const filtersToApply = { ...this.filters };
      this.$emit("apply", filtersToApply);
    },
  },
  mounted() {
    this.filters = { ...this.currentFilters };
  },
};
</script>

<style scoped>
.daily-summary-filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.content-view-block {
  max-width: unset;
}
.filters-select {
  width: 250px;
}

.filters-select .ant-select-dropdown {
  width: 200px;
}

.filters-select .ant-select-dropdown-menu-item {
  white-space: normal;
  word-wrap: break-word;
}
</style>
