export default {
  data() {
    return {
      tourSteps: [
        {
          target: ".view-header",
          header: {
            title: "Associate Shifts Table",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: this.shiftsTourBeforeStartCallback,
          content:
            "This section displays detailed information about each shift worked by your associates. Click on a shift to see more details.",
        },
        {
          target: this.getTableHeaderByKey("status"),
          header: {
            title: "Status",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content: `
            <p>Each shift can have various statuses: </p>
            <ul style="text-align: left">
              <li>Scheduled: Shift has been scheduled.</li>
              <li>Started: Shift is currently in progress.</li>
              <li>Missed: Shift has been missed.</li>
              <li>Cancelled: Shift has been cancelled.</li>
              <li>Completed: Completed</li>
              <li>Unpaid: Payment for the shift is pending.</li>
              <li>Paid: Shift has been paid.</li>
              <li>Not Signed: Shift report not signed.</li>
              <li>Auth Issues: There are authorization issues with the shift.</li>
              <li>Ready To Pay: Shift has not been paid.</li>
              <li>Payment Cancelled: Payment was cancelled.</li>
              <li>Payment In Progress: Payment processing is in progress.</li>
              <li>Draft Payment: Payment request is in draft status.</li>

            </ul>
          `,
        },
        {
          target: this.getTableHeaderByKey("authorizations"),
          header: {
            title: "Authorizations",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content: `
          <p>Authorizations indicate rule violations during the shift. There are various types of authorizations:</p>
          <ul style="text-align: left">
            <li>Early Start</li>
            <li>Late Start</li>
            <li>No Meal Break</li>
            <li>Early Meal Break End</li>
            <li>No Second Meal Break</li>
            <li>Early Second Meal Break End</li>
            <li>Early End</li>
            <li>Late End</li>
            <li>Edit Shift</li>
            <li>Overtime</li>
            <li>Outside of Work Area</li>
            <li>Absence on Shift</li>
            <li>Manually Shift</li>
            <li>Signed Actual Time Discrepancy</li>
            <li>No Rest Break</li>
          </ul>
          `,
        },

        {
          target: "tbody tr .ant-table-row-expand-icon",
          header: {
            title: "Shift Times",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content: "This buttons opens shift details",
        },
        {
          target: '[data-row-key="signed"]',
          header: {
            title: "Signed Times",
          },
          params: {
            placement: "top",
            enableScrolling: false,
          },
          before: this.beforeSignedShiftStep,
          content:
            "This section shows the times signed off by the associate, which they consider accurate.",
        },
        {
          target: '[data-row-key="actual"]',
          header: {
            title: "Actual Times",
          },
          params: {
            placement: "top",
            enableScrolling: false,
          },
          content:
            "These are the times tracked by the application during the shift.",
        },
        {
          target: '[data-row-key="scheduled"]',
          header: {
            title: "Scheduled Times",
          },
          params: {
            placement: "top",
            enableScrolling: false,
          },
          content:
            "These are Scheduled Times for the shift",
        },
        {
          target: "#actions-btn-group",
          header: {
            title: "Actions",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content:
            "Here you can perform various actions on the shift. Let's explore each option available.",
        },
        {
          target: "#edit-shift-btn",
          header: {
            title: "Editing a Shift",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: this.beforeAuthsButtonStep,
          content:
            "Clicking the 'Edit' button opens a modal window where you can edit the shift details.",
        },
        {
          target: "#shift-times",
          header: {
            title: "Shift Times",
          },
          params: {
            placement: "left",
            enableScrolling: false,
          },
          before: this.beforeEditShiftStep,
          content:
            "Shift Times contains the aforementioned shift times (Signed, Actual, Scheduled).",
        },
        {
          target: "#signed-work-time",
          header: {
            title: "Shift Times",
          },
          params: {
            placement: "right",
            enableScrolling: false,
          },
          before: this.beforeEditShiftStep,
          content: `
          <p>You can modify the shift details using these fields. If you save changes that contradict company policies, authorizations might appear.</p>
          <p>To save a shift with authorisations and release the associate from signing them - use the "Auto Sign" checkbox in the modal confirmation box that will appear when you try to save the shift.</p>
          `,
        },
        {
          target: "#send-msg-btn",
          header: {
            title: "Send Message Button",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content:
            "Use this button to send a message to the associate, requesting them to discuss or take action regarding their shift.",
        },
        {
          target: "#delete-shift-btn",
          header: {
            title: "Delete Shift Button",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content:
            "This button allows you to delete the shift and all its associated information.",
        },
      ],

      callbacks: {
        onStart: this.shiftTourStartCallback,
        onSkip: this.shiftTourFinishCallback,
        onFinish: this.shiftTourFinishCallback,
      },
    };
  },
  computed: {},
  methods: {
    getTableHeaderByKey(key) {
      return `th[key="${key}"]`;
    },

    shiftsTourBeforeStartCallback() {
      return new Promise((resolve, reject) => {
        const checkCondition = () => {
          if (this.shifts && this.shifts.length > 0 && !this.loading) {
            resolve();
          } else {
            setTimeout(checkCondition, 100);
          }
        };
        checkCondition();
      });
    },

    shiftTourStartCallback() {
      this.tourIsOn = true;
    },

    shiftTourFinishCallback() {
      this.tourIsOn = false;
    },

    beforeAuthsButtonStep() {
      if (!this.editingAssociateShiftId) {
        return;
      }

      return new Promise((resolve) => {
        this.closeAssociateShiftModal();
        setTimeout(() => {
          resolve();
        }, 500);
      });
    },
    beforeEditShiftStep() {
      if (this.editingAssociateShiftId) {
        return;
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 500);
      });
    },
    beforeSignedShiftStep() {
      const firstRow = document.querySelector("tbody tr");

      if (firstRow) {
        const buttonDiv = firstRow.querySelector(".ant-table-row-collapsed");

        if (buttonDiv) {
          buttonDiv.click();
        }
      }
    },
  },
};
