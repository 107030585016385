var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('create-pay-code',{attrs:{"visible":_vm.visiblePayCodeModal,"pay-code-id":_vm.payCodeId},on:{"update:visible":function($event){_vm.visiblePayCodeModal=$event},"update:payCodeId":function($event){_vm.payCodeId=$event},"update:pay-code-id":function($event){_vm.payCodeId=$event}}}),_c('deletion-pay-code',{attrs:{"visible":_vm.visibleDeletionModal,"pay-code-id":_vm.payCodeId},on:{"update:visible":function($event){_vm.visibleDeletionModal=$event},"update:payCodeId":function($event){_vm.payCodeId=$event},"update:pay-code-id":function($event){_vm.payCodeId=$event}}}),_c('h2',{staticClass:"view-header"},[_vm._v("Pay Codes")]),_c('div',{staticClass:"actions"},[_c('a-select',{attrs:{"placeholder":"Attached","defaultActiveFirstOption":true},model:{value:(_vm.filter.attached),callback:function ($$v) {_vm.$set(_vm.filter, "attached", $$v)},expression:"filter.attached"}},[_c('a-select-option',{attrs:{"value":null}},[_vm._v(" All Pay Codes ")]),_c('a-select-option',{attrs:{"value":true}},[_vm._v(" Only Attached ")]),_c('a-select-option',{attrs:{"value":false}},[_vm._v(" Only Not Attached ")])],1),(
        _vm.hasAnyPermission([
          'validate_payments',
          'manage_shifts',
          'process_payments',
          'validate_payments',
        ])
      )?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.openPayCodeModal}},[_vm._v("Add Pay Code")]):_vm._e()],1),_c('a-table',{attrs:{"size":"small","pagination":{ pageSize: 50 },"columns":_vm.columns,"data-source":_vm.tableData,"loading":_vm.loadingPayCodes},scopedSlots:_vm._u([{key:"actions",fn:function(text, record){return (
        !record.initial &&
        _vm.hasAnyPermission([
          'validate_payments',
          'manage_shifts',
          'process_payments',
          'validate_payments',
        ])
      )?_c('span',{},[_c('div',{staticClass:"flex gap-2 justify-content-center"},[_c('a-button',{attrs:{"id":"open-btn","title":"Delete","size":"small","icon":"edit"},on:{"click":function($event){return _vm.openEditModal(record.id)}}},[_vm._v(" Edit ")]),_c('a-button',{attrs:{"id":"open-btn","title":"Delete","size":"small","icon":"delete"},on:{"click":function($event){return _vm.openDeleteModal(record.id)}}},[_vm._v(" Delete ")])],1)]):_vm._e()}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }