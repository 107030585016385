<template>
  <div class="column">
    <h3 v-if="withTitle" class="m-0">Attachments</h3>
    <div>
      <div class="ant-upload-list ant-upload-list-picture-card">
        <transition-group name="slide-fade" tag="div">
          <div
            class="ant-upload-list-picture-card-container"
            v-for="file in fileList"
            :key="file.uid"
          >
            <div
              class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card"
            >
              <div class="ant-upload-list-item-info">
                <div
                  class="ant-upload-list-item-uploading-text"
                  v-if="file.status === 'uploading'"
                >
                  Uploading...
                </div>
                <template v-else
                  ><img
                    v-if="isImageUrl(file)"
                    :src="file.thumbUrl || file.url"
                    :alt="file.name"
                    class="ant-upload-list-item-image" />
                  <a-icon
                    v-else
                    :type="getFileTypeIcon(file).icon"
                    theme="twoTone"
                    class="anticon anticon-file ant-upload-list-item-icon"
                    :style="{ fontSize: '60px' }"
                    :two-tone-color="getFileTypeIcon(file).color"
                /></template>
              </div>
              <span class="ant-upload-list-item-actions">
                <a-icon
                  v-if="isImageUrl(file)"
                  type="eye-o"
                  @click="handlePreview(file)"
                />
                <a-icon type="download" @click="handleDownload(file)" />
                <a-icon type="delete" @click="handleRemove(file)" />
              </span>
            </div></div
        ></transition-group>

        <a-upload
          list-type="picture-card"
          :file-list="fileList"
          :before-upload="beforeUpload"
          :remove="handleRemove"
          :custom-request="customRequestHandler"
          :action="null"
          :showUploadList="false"
          :multiple="true"
          @preview="handlePreview"
          @change="handleChange"
          @download="handleDownload"
          :style="{ width: 'fit-content' }"
        >
          <div>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
      </div>

      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { Button, Icon, notification, Upload } from "ant-design-vue";

export default {
  components: {
    "a-upload": Upload,
    "a-button": Button,
    "a-icon": Icon,
  },
  props: {
    files: {
      type: Array,
      default: [],
    },
    withTitle: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [api],
  data() {
    return {
      previewVisible: false,

      previewImage: "",
      fileList: [],
      uploadList: [],

      loading: false,
    };
  },
  watch: {
    files: {
      handler(newVal) {
        this.fileList = newVal
          .map((item) => ({
            uid: item.id,
            name: item.url,
            url: item.url,
            status: "done",
          }))
          .sort((a, b) => a.uid - b.uid);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },
    extname(url = "") {
      const temp = url.split("/");
      const filename = temp[temp.length - 1];
      const filenameWithoutSuffix = filename.split(/#|\?/)[0];
      return (/\.[^./\\]*$/.exec(filenameWithoutSuffix) || [""])[0];
    },
    isImageFileType(type) {
      return !!type && type.indexOf("image/") === 0;
    },
    isImageUrl(file) {
      if (this.isImageFileType(file.type)) {
        return true;
      }
      const url = file.thumbUrl || file.url;
      const extension = this.extname(url);
      if (
        /^data:image\//.test(url) ||
        /(webp|svg|png|gif|jpg|jpeg|jfif|bmp|dpg|ico)$/i.test(extension)
      ) {
        return true;
      }
      if (/^data:/.test(url)) {
        // other file types of base64
        return false;
      }
      if (extension) {
        // other file types which have extension
        return false;
      }
      return true;
    },
    getFileTypeIcon(file) {
      const url = file.thumbUrl || file.url;
      const extension = this.extname(url);

      switch (extension) {
        case ".pdf":
          return { icon: "file-pdf", color: "red" };
        case ".doc":
        case ".docx":
          return { icon: "file-word", color: "#1890ff" };
        case ".xls":
        case ".xlsx":
          return { icon: "file-excel", color: "green" };
        case ".ppt":
        case ".pptx":
          return { icon: "file-ppt", color: "red" };
        case ".zip":
        case ".rar":
          return { icon: "file-zip", color: "gray" };
        case ".mp4":
        case ".mov":
        case ".mkv":
        case ".wmv":
        case ".webm":
        case ".avi":
        case ".flv":
        case ".mts":
          return { icon: "video-camera", color: "purple" };
        default:
          return { icon: "file", color: "gray" };
      }
    },

    customRequestHandler(options) {
      options.onSuccess("success", options.file);
    },
    handleCancel() {
      this.previewVisible = false;
    },

    handleRemove(file) {
      this.fileList = this.fileList.filter((item) => item.uid !== file.uid);

      this.$emit(
        "save",
        this.fileList.map((item) => ({ id: item.uid })),
        "remove",
        file.uid
      );
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        try {
          file.preview = await this.getBase64(file.originFileObj);
        } catch (error) {
          console.error("Failed to generate preview:", error);
          return;
        }
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    beforeUpload(file, fileList) {
      const allowedExtensions = [
        "mp4",
        "mov",
        "mkv",
        "wmv",
        "webm",
        "avi",
        "flv",
        "mts",
        "png",
        "jpg",
        "jpeg",
        "svg",
        "doc",
        "docx",
        "pdf",
        "xls",
        "xlsx",
      ];

      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        this.showNotification(
          "error",
          "Error",
          "This file type is not allowed."
        );
        return false;
      }

      if (fileList.length > 0 && fileList[fileList.length - 1] === file) {
        this.customRequest(fileList);
      }

      return true;
    },

    async customRequest(uploadedFiles) {
      this.loading = true;
      try {
        const { data } = await this.apiUploadFiles(uploadedFiles);
        if (!data || !data.ids || !data.ids.length) {
          throw new Error("Error occurred while uploading files.");
        }

        this.fileList = [
          ...this.fileList.filter(
            (item) => !uploadedFiles.some((file) => file.uid === item.uid)
          ),
          ...uploadedFiles.map((item, idx) => ({
            uid: data.ids[idx],
            originFileObj: item,
            status: "done",
            name: item.name,
            size: item.size,
            type: item.type,
            lastModified: item.lastModified,
            lastModifiedDate: item.lastModifiedDate,
          })),
        ];

        this.$emit(
          "save",
          this.fileList
            .sort((a, b) => a.uid - b.uid)
            .filter((item) => typeof item.uid === "number")
            .map((item) => ({ id: item.uid })),
          "add"
        );
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    handleChange(info) {
      const { fileList } = info;
      this.fileList = fileList.map((file) => {
        if (typeof file.uid === "string") {
          file.status = "uploading";
        }

        if (!file.url && !file.preview) {
          this.getBase64(file.originFileObj).then((preview) => {
            file.preview = preview;
          });
        }
        return file;
      });
    },

    async handleDownload(file) {
      let preview = file.preview;
      if (!file.url && !preview) {
        preview = await this.getBase64(file.originFileObj);
      }

      const downloadFile = (url, fileName) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName || "download";
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        link.remove();
      };

      if (file.url) {
        downloadFile(file.url, file.name || "download");
      } else if (preview) {
        const base64Data = preview.split(",")[1];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
          type: file.type || "application/octet-stream",
        });
        const url = URL.createObjectURL(blob);
        downloadFile(url, file.name || "download");
        setTimeout(() => URL.revokeObjectURL(url), 1000);
      }
    },
  },
  mounted() {
    this.fileList = this.files
      .map((item) => ({
        uid: item.id,
        name: item.url,
        url: item.url,
        status: "done",
      }))
      .sort((a, b) => a.uid - b.uid);
  },
};
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
