<template>
  <a-modal
    title="Filters"
    width="600px"
    :visible="visible"
    @cancel="cancel"
    :destroy-on-close="true"
  >
    <div class="grid">
      <b class="col-4">Associate</b>
      <search-associates
        class="col-8"
        :is-associate-id="true"
        :multiply="true"
        :value.sync="filters.associate_ids"
      />

      <b class="col-4">Dates</b>
      <a-range-picker
        class="col-8"
        format="MM/DD/YYYY"
        v-model="dateProxy"
        valueFormat="YYYY-MM-DD"
        :ranges="dateRange"
      />

      <b class="col-4">Shift Statuses</b>
      <custom-select
        class="col-8"
        :multiple="true"
        :value.sync="filters.statuses"
        placeholder="Status"
        :options="shiftStatuses"
        name="title"
        optionKey="key"
      />

      <b class="col-4">Payment Statuses</b>
      <custom-select
        class="col-8"
        :multiple="true"
        :value.sync="filters.payment_statuses"
        placeholder="Status"
        :options="paymentStatuses"
        name="title"
        optionKey="key"
      />

      <b class="col-4">Payroll Auths</b>
      <custom-select
        class="col-8"
        :multiple="true"
        :value.sync="payrollSelected"
        placeholder="Type"
        :options="payrollAuths"
        name="title"
        optionKey="key"
      />

      <b class="col-4">Disciplinary Auths</b>
      <custom-select
        class="col-8"
        :multiple="true"
        :value.sync="disciplinarySelected"
        placeholder="Type"
        :options="disciplinaryAuths"
        name="title"
        optionKey="key"
      />

      <b class="col-4">Qualification</b>
      <custom-select
        class="col-8"
        :multiple="false"
        :value.sync="roleProxy"
        placeholder="Qualification"
        :options="roles"
        name="name"
        optionKey="id"
      />

      <b class="col-4">Shift Type</b>
      <a-select
        class="col-8"
        v-model="filters.shift_type"
        :allow-clear="true"
        :dropdown-match-select-width="false"
        placeholder="Shift Type"
      >
        <a-select-option value="worked"> Worked </a-select-option>
        <a-select-option value="pay_code"> Pay Code </a-select-option>
      </a-select>

      <template v-if="filters.shift_type === 'worked'"
        ><b class="col-4">With attached pay codes</b>
        <a-checkbox
          class="col-8"
          :checked="isAttachedChecked"
          @change="handleAttachedChange"
        />
      </template>

      <b class="col-4">Payment Type</b>
      <custom-select
        class="col-8"
        :multiple="false"
        :value.sync="filters.payment_type"
        placeholder="Payment Type"
        :options="paymentTypes"
        name="value"
        optionKey="key"
      />

      <b class="col-4">Pay Codes</b>
      <custom-select
        class="col-8"
        :multiple="false"
        :value.sync="payCodeProxy"
        placeholder="Pay Codes"
        :options="payCodes"
        name="name"
        optionKey="id"
      />

      <b class="col-4">Origin</b>
      <custom-select
        class="col-8"
        :multiple="false"
        :value.sync="filters.origin"
        placeholder="Origin"
        :options="origins"
        name="title"
        optionKey="key"
      />

      <div class="flex gap-2 flex-wrap mt-2">
        <a-button
          @click="toggleFilter('manually_created')"
          :type="filters.manually_created ? 'primary' : 'default'"
        >
          Manually Created
        </a-button>
        <a-button
          @click="toggleFilter('with_files')"
          :type="filters.with_files ? 'primary' : 'default'"
        >
          With Attachments
        </a-button>
        <a-button
          @click="toggleFilter('adjusted')"
          :type="filters.adjusted ? 'primary' : 'default'"
        >
          Adjusted
        </a-button>
      </div>
    </div>
    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button type="danger" :disabled="isEmptyFilters" @click="reset">
          Reset
        </a-button>
        <a-button type="primary" @click="apply"> Apply </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {
  Modal,
  Button,
  Select,
  Checkbox,
  Switch,
  DatePicker,
} from "ant-design-vue";
import moment from "moment-timezone";
import SearchAssociates from "@/components/search-associates.vue";
import {
  SHIFT_STATUS,
  SHIFT_PAYMENT_REQUEST_STATUS,
  AUTH_STATUSES,
  SHIFT_ORIGIN,
} from "@/store/modules/shifts/constants";
import { createNamespacedHelpers } from "vuex";
import CustomSelect from "@/components/ui/custom-select.vue";
import { PAYMENT_TYPE } from "@/store/modules/payment/constants";

const { mapState: payCodeState, mapActions: payCodeActions } =
  createNamespacedHelpers("paycode");

const filtersBaseState = Object.freeze({
  associate_ids: undefined,
  statuses: [
    "deleted",
    "missed",
    "not_signed",
    "cancelled",
    "completed",
    "started",
    "scheduled",
  ],
  payment_statuses: [
    "unpaid",
    "auth_issues",
    "not_paid",
    "draft",
    "paid",
    "in_progress",
    "partially_paid",
    "cancelled",
  ],
  auth_types: undefined,
  pay_code: undefined,
  role: undefined,
  payment_type: undefined,
  end_date: undefined,
  start_date: undefined,
  shift_type: undefined,
  attached_pay_code: undefined,
  manually_created: undefined,
  with_pay_codes: undefined,
  with_files: undefined,
  adjusted: undefined,
  origin: undefined,
});

export default {
  components: {
    "a-modal": Modal,
    "a-button": Button,
    "a-select": Select,
    "a-checkbox": Checkbox,
    "a-select-option": Select.Option,
    "a-switch": Switch,
    "a-range-picker": DatePicker.RangePicker,
    "search-associates": SearchAssociates,
    "custom-select": CustomSelect,
  },
  data() {
    return {
      filters: { ...filtersBaseState },

      shiftStatuses: Object.entries(SHIFT_STATUS).map(([key, value]) => ({
        key,
        ...value,
      })),

      disciplinaryAuths: Object.entries(AUTH_STATUSES)
        .map(([key, value]) => ({
          key,
          ...value,
        }))
        .filter((item) => item.type === "disciplinary"),

      payrollAuths: Object.entries(AUTH_STATUSES)
        .map(([key, value]) => ({
          key,
          ...value,
        }))
        .filter((item) => item.type === "payroll"),

      paymentStatuses: Object.entries(SHIFT_PAYMENT_REQUEST_STATUS).map(
        ([key, value]) => ({
          key,
          ...value,
        })
      ),
      paymentTypes: Object.entries(PAYMENT_TYPE).map(([key, value]) => ({
        key,
        value,
      })),
      roles: this.$store.state.applicationState.projectRoles,
      origins: Object.entries(SHIFT_ORIGIN).map(([key, value]) => ({
        key,
        ...value,
      })),
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    currentFilters: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...payCodeState({
      payCodes: (state) => state.payCodes,
    }),

    isEmptyFilters() {
      return JSON.stringify(filtersBaseState) === JSON.stringify(this.filters);
    },

    dateRange() {
      return {
        Today: [moment(), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
      };
    },

    dateProxy: {
      get() {
        return [this.filters.start_date, this.filters.end_date];
      },
      set(value) {
        this.filters.start_date = value[0];
        this.filters.end_date = value[1];
      },
    },

    roleProxy: {
      get() {
        return this.filters.role ? Number(this.filters.role) : undefined;
      },
      set(value) {
        this.filters.role = value;
      },
    },

    payCodeProxy: {
      get() {
        return this.filters.pay_code
          ? Number(this.filters.pay_code)
          : undefined;
      },
      set(value) {
        this.filters.pay_code = value;
        this.filters.with_pay_codes = value ? true : undefined;
      },
    },

    payrollSelected: {
      get() {
        return (this.filters.auth_types ?? []).filter((key) =>
          this.payrollAuths.some((auth) => auth.key === key)
        );
      },
      set(values) {
        this.filters.auth_types = [...values, ...this.disciplinarySelected];
      },
    },

    disciplinarySelected: {
      get() {
        return (this.filters.auth_types ?? []).filter((key) =>
          this.disciplinaryAuths.some((auth) => auth.key === key)
        );
      },
      set(values) {
        this.filters.auth_types = [...values, ...this.payrollSelected];
      },
    },

    isAttachedChecked() {
      return this.filters.attached_pay_code === "true";
    },
  },
  watch: {
    currentFilters: {
      handler(newValue) {
        this.filters = { ...newValue };
      },
      immediate: true,
      deep: true,
    },
    visible: {
      handler() {
        this.filters = { ...this.currentFilters };
      },
    },
    "filters.shift_type": {
      handler(newVal) {
        if (newVal === "worked") {
          this.filters.attached_pay_code = false;
        } else {
          this.filters.attached_pay_code = undefined;
        }
      },
    },
  },
  methods: {
    ...payCodeActions(["fetchPayCodes"]),

    reset() {
      if (this.isEmptyFilters) {
        this.cancel();
      } else {
        this.filters = { ...filtersBaseState };
        this.$router.replace({ query: {} }).catch(() => {});
      }
    },

    cancel() {
      this.$emit("update:visible", false);
    },

    apply() {
      this.cancel();

      const { statuses, payment_statuses, ...rest } = this.filters;

      let query = { ...rest };

      if (
        JSON.stringify(statuses) !== JSON.stringify(filtersBaseState.statuses)
      ) {
        query.statuses = statuses;
      }

      if (
        JSON.stringify(payment_statuses) !==
        JSON.stringify(filtersBaseState.payment_statuses)
      ) {
        query.payment_statuses = payment_statuses;
      }

      this.$router.replace({ query }).catch(() => {});
    },

    handleAttachedChange(e) {
      this.filters.attached_pay_code = e.target.checked ? "true" : "false";
    },

    disabledStartDate(current) {
      const endValue = this.filters.end_date;
      if (!endValue) {
        return false;
      }

      return current && current.valueOf() > moment(endValue).valueOf();
    },

    disabledEndDate(current) {
      const startValue = this.filters.start_date;
      if (!startValue) {
        return false;
      }

      return current && current.valueOf() <= moment(startValue).valueOf();
    },

    toggleFilter(type) {
      this.filters[type] = this.filters[type] ? undefined : true;
    },

    async fetchPayCodesInfo() {
      try {
        this.fetchPayCodes({});
      } catch {
        this.showNotification("error", "Error", error);
      }
    },
  },
  mounted() {
    this.fetchPayCodesInfo();

    const defaultValues = {
      end_date: moment().format("YYYY-MM-DD"),
    };

    if (!this.$route.query.end_date) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          end_date: defaultValues.end_date,
        },
      });
    }
  },
};
</script>

<style scoped>
.ant-calendar-picker {
  margin: 0;
}
</style>
