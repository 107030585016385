var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"Change Company Logo","footer":null},model:{value:(_vm.changeCompanyLogoVisible),callback:function ($$v) {_vm.changeCompanyLogoVisible=$$v},expression:"changeCompanyLogoVisible"}},[_c('change-company-logo',{on:{"set-project-logo":_vm.handleChangeLogo}})],1),_c('div',[_c('div',{staticClass:"project-name-with-logo-container"},[_c('div',{staticClass:"logo-container",style:({ backgroundImage: 'url(' + _vm.projectAvatarSource + ')' }),on:{"click":_vm.handleUpdateLogo}},[_c('a-icon',{staticClass:"camera-icon",attrs:{"type":"camera"}})],1),_c('h2',{staticClass:"project-name"},[_vm._v(_vm._s(_vm.project.name))])]),_c('a-form',{attrs:{"layout":"inline","form":_vm.form}},[_c('div',{staticClass:"content-view"},[_c('div',{staticClass:"content-view-block"},[_c('h3',[_vm._v("Company")]),_c('p',[_c('b',[_vm._v("Name "),(_vm.editingCompany)?_c('span',{staticClass:"required-field"},[_vm._v("*")]):_vm._e()]),(_vm.editingCompany)?_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter the company name!',
                      },
                      {
                        min: 2,
                        max: 100,
                        message:
                          'The name must be between 2 and 100 characters.',
                      },
                    ],
                  },
                ]),expression:"[\n                  'name',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter the company name!',\n                      },\n                      {\n                        min: 2,\n                        max: 100,\n                        message:\n                          'The name must be between 2 and 100 characters.',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Enter name"}})],1):_c('span',[_vm._v(_vm._s(_vm.project.name))])],1),_c('p',[_c('b',[_vm._v("Description "),(_vm.editingCompany)?_c('span',{staticClass:"required-field"},[_vm._v("*")]):_vm._e()]),(_vm.editingCompany)?_c('a-form-item',[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'description',
                  { rules: [{ required: false }] },
                ]),expression:"[\n                  'description',\n                  { rules: [{ required: false }] },\n                ]"}],attrs:{"placeholder":"Enter description","auto-size":{ minRows: 1, maxRows: 5 }}})],1):_c('span',[_vm._v(_vm._s(_vm.project.description))])],1),_c('p',[_c('b',[_vm._v("Owner")]),_c('span',[_vm._v(_vm._s(_vm.project.owner))])]),_c('p',[_c('b',[_vm._v("Join Type")]),(_vm.editingCompany)?_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'joinType',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a join type!',
                      },
                    ],
                  },
                ]),expression:"[\n                  'joinType',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please select a join type!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Select join type"}},[_c('a-select-option',{attrs:{"value":"open"}},[_vm._v("Open")]),_c('a-select-option',{attrs:{"value":"request"}},[_vm._v("Request")]),_c('a-select-option',{attrs:{"value":"invite"}},[_vm._v("Invite")])],1)],1):_c('span',[_vm._v(_vm._s(_vm.Util.capitalize(_vm.project.join_type)))])],1),_c('p',[_c('b',[_vm._v("Tags")]),(_vm.editingCompany)?_c('a-form-item',[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['tags', { rules: [{ required: false }] }]),expression:"['tags', { rules: [{ required: false }] }]"}],attrs:{"placeholder":"Enter tags","auto-size":{ minRows: 1, maxRows: 5 }}})],1):_c('span',[_vm._v(_vm._s(_vm.project.tags ?? "-"))])],1),_c('p',[_c('b',[_vm._v("Tax ID (EIN)")]),(_vm.editingCompany)?_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['tax_id']),expression:"['tax_id']"}],attrs:{"placeholder":"Enter Tax ID (EIN)"}})],1):_c('span',[_vm._v(_vm._s(_vm.project.tax_id ?? "-"))])],1),_c('p',[_c('b',[_vm._v("Address "),(_vm.editingCompany)?_c('span',{staticClass:"required-field"},[_vm._v("*")]):_vm._e()]),(_vm.editingCompany)?_c('span',{staticClass:"flex flex-column gap-2"},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'addressLine1',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter address line 1!',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'addressLine1',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Please enter address line 1!',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"placeholder":"Address Line 1"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'addressLine2',
                    { rules: [{ required: false }] },
                  ]),expression:"[\n                    'addressLine2',\n                    { rules: [{ required: false }] },\n                  ]"}],attrs:{"placeholder":"Address Line 2"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'city',
                    {
                      rules: [
                        { required: true, message: 'Please enter city!' },
                      ],
                    },
                  ]),expression:"[\n                    'city',\n                    {\n                      rules: [\n                        { required: true, message: 'Please enter city!' },\n                      ],\n                    },\n                  ]"}],attrs:{"placeholder":"City"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['state', { rules: [{ required: false }] }]),expression:"['state', { rules: [{ required: false }] }]"}],attrs:{"placeholder":"State"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'zipcode',
                    {
                      rules: [
                        { required: true, message: 'Please enter zipcode!' },
                      ],
                    },
                  ]),expression:"[\n                    'zipcode',\n                    {\n                      rules: [\n                        { required: true, message: 'Please enter zipcode!' },\n                      ],\n                    },\n                  ]"}],attrs:{"placeholder":"Zipcode"}})],1)],1):_c('span',{staticClass:"address"},[_vm._v(_vm._s(_vm.projectAddress))])])]),_c('div',{staticClass:"content-view-block"},[_c('h3',[_vm._v("Active Features")]),_c('p',[_c('b',[_vm._v("Shift Confirmation")]),(_vm.editingCompany)?_c('a-form-item',[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'shiftConfirmation',
                  { valuePropName: 'checked', rules: [{ required: true }] },
                ]),expression:"[\n                  'shiftConfirmation',\n                  { valuePropName: 'checked', rules: [{ required: true }] },\n                ]"}]})],1):_c('span',[_vm._v(_vm._s(_vm.project.shift_confirmation ? "Yes" : "No"))])],1),_c('p',[_c('b',[_vm._v("Require COVID Survey")]),(_vm.editingCompany)?_c('a-form-item',[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'requireCovidSurvey',
                  { valuePropName: 'checked', rules: [{ required: true }] },
                ]),expression:"[\n                  'requireCovidSurvey',\n                  { valuePropName: 'checked', rules: [{ required: true }] },\n                ]"}]})],1):_c('span',[_vm._v(_vm._s(_vm.project.require_covid_survey ? "Yes" : "No"))])],1),_c('p',[_c('b',[_vm._v("Shift Authorizations")]),(_vm.editingCompany)?_c('a-form-item',[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'actionAuthorization',
                  { valuePropName: 'checked', rules: [{ required: true }] },
                ]),expression:"[\n                  'actionAuthorization',\n                  { valuePropName: 'checked', rules: [{ required: true }] },\n                ]"}]})],1):_c('span',[_vm._v(_vm._s(_vm.project.action_authorization ? "Yes" : "No"))])],1),_c('p',[_c('b',[_vm._v("SMS Notifications")]),(_vm.editingCompany)?_c('a-form-item',[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'sendingSmsWithPush',
                  { valuePropName: 'checked', rules: [{ required: true }] },
                ]),expression:"[\n                  'sendingSmsWithPush',\n                  { valuePropName: 'checked', rules: [{ required: true }] },\n                ]"}]})],1):_c('span',[_vm._v(_vm._s(_vm.project.sending_sms_with_push ? "Yes" : "No"))])],1),_c('h3',[_vm._v("Contacts")]),_c('p',[_c('b',[_vm._v("Email "),(_vm.editingCompany)?_c('span',{staticClass:"required-field"},[_vm._v("*")]):_vm._e()]),(_vm.editingCompany)?_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'email',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter the email address!',
                      },
                      {
                        type: 'email',
                        message: 'The input is not a valid email!',
                      },
                    ],
                  },
                ]),expression:"[\n                  'email',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter the email address!',\n                      },\n                      {\n                        type: 'email',\n                        message: 'The input is not a valid email!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Enter email address"}})],1):_c('span',[_vm._v(_vm._s(_vm.project.email))])],1),_c('p',[_c('b',[_vm._v("Phone "),(_vm.editingCompany)?_c('span',{staticClass:"required-field"},[_vm._v("*")]):_vm._e()]),(_vm.editingCompany)?_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'phone',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter the phone number!',
                      },
                      {
                        pattern: /^\+?\d{10,15}$/,
                        message:
                          'The phone number must be 10 to 15 digits long and may start with a +.',
                      },
                    ],
                  },
                ]),expression:"[\n                  'phone',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter the phone number!',\n                      },\n                      {\n                        pattern: /^\\+?\\d{10,15}$/,\n                        message:\n                          'The phone number must be 10 to 15 digits long and may start with a +.',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Enter phone number"}})],1):_c('span',[_vm._v(_vm._s(_vm.project.phone))])],1),_c('div',{staticClass:"flex justify-content-end gap-2"},[(_vm.editingCompany)?[_c('a-button',{attrs:{"type":"danger","loading":_vm.loading},on:{"click":_vm.cancelEdit}},[_vm._v(" Cancel ")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading,"disabled":_vm.disableSave},on:{"click":_vm.handleSubmit}},[_vm._v(" Save ")])]:[_c('a-button',{attrs:{"disabled":!_vm.hasPermission('manage_project'),"type":"primary"},on:{"click":_vm.startEdit}},[_vm._v(" Edit ")])]],2)]),_c('div',{staticClass:"content-view-block"},[[_c('h3',[_vm._v("Company Policy")]),(_vm.project.project_policy_type == 'text')?_c('div',[_vm._v(" "+_vm._s(_vm.project.project_policy_text ?? "Company policy is not available")+" ")]):(_vm.project.project_policy_type == 'pdf')?_c('div',[_c('a-button',{attrs:{"icon":"download"},on:{"click":_vm.downloadPolicy}},[_vm._v(" Download Policy ")])],1):_vm._e()]],2)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }