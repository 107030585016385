import { PAYMENT_STATUS } from "./constants";

export default {
  payment: {},

  statuses: Object.entries(PAYMENT_STATUS).map(([key, value]) => ({
    id: key,
    name: value.title,
  })),
  systems: [],
};
