<template>
  <div class="wrapper">
    <a-form-model-item
      :colon="false"
      label-align="left"
      label="Associates"
      prop="associates"
    >
      <search-associates :value.sync="associatesList" :multiply="true" />
    </a-form-model-item>

    <a-form-model-item
      :colon="false"
      label-align="left"
      label="Dates"
      prop="dates"
    >
      <a-date-picker
        v-model="currentDate"
        format="MM/DD/YYYY"
        :allowClear="false"
        placeholder="Select Dates"
        @change="changeDates"
      >
        <template slot="dateRender" slot-scope="current">
          <div class="ant-calendar-date" :style="highlightDate(current)">
            {{ current.date() }}
          </div>
        </template>
      </a-date-picker>
    </a-form-model-item>

    <div v-if="dates && dates.length" class="date-container">
      <a-tag
        closable
        v-for="(date, index) in dates"
        @close="deleteDate(date, $event)"
        :key="index"
        >{{ formatDate(date) }}</a-tag
      >
    </div>
  </div>
</template>

<script>
import SearchAssociates from "@/components/search-associates.vue";
import { DatePicker, FormModel, Select, Tag } from "ant-design-vue";
import moment from "moment-timezone";

export default {
  components: {
    "a-form-model-item": FormModel.Item,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-date-picker": DatePicker,
    "a-tag": Tag,
    "search-associates": SearchAssociates,
  },
  data() {
    return {
      associatesList: [],

      currentDate: null,
    };
  },
  props: {
    dates: {
      type: Array,
      default: () => [],
    },
    associates: {
      type: Array,
      default: () => [],
    },
    initialValues: {
      type: Object,
      default: undefined,
    },
  },
  watch: {
    associates: {
      handler(newVal) {
        this.associatesList = newVal;
      },
      immediate: true,
    },
    associatesList: {
      handler(newVal) {
        this.$emit("update:associates", newVal);
      },
      deep: true,
    },
    initialValues: {
      handler(newVal) {
        if (!newVal) {
          return;
        }

        if (newVal.associateNumber)
          this.associatesList = [
            ...this.associatesList,
            newVal.associateNumber,
          ];

        if (newVal.start && newVal.end) {
          const end = moment(newVal.end);

          const rangeDates = [];

          let current = moment(newVal.start);

          while (current.isSameOrBefore(end)) {
            rangeDates.push(moment(current).utc(true).toISOString());
            current.add(1, "day");
          }

          if (rangeDates.length > 0) {
            this.$emit("update:dates", rangeDates);
          }
        } else if (newVal.start || newVal.end) {
          const updatedDates = [
            newVal.start && moment(newVal.start).utc(true).toISOString(),
            newVal.end && moment(newVal.end).utc(true).toISOString(),
          ].filter(Boolean);

          if (updatedDates.length > 0) {
            this.$emit("update:dates", updatedDates);
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // work with datePicker
    highlightDate(current) {
      const formattedDate = moment(current)
        .startOf("day")
        .utc(true)
        .toISOString();

      const isHighlighted = this.dates.includes(formattedDate);

      return {
        backgroundColor: isHighlighted ? "#1890ff" : "transparent",
        color: isHighlighted && "#fff",
      };
    },

    fillInitialValues() {},

    changeDates(date) {
      const formattedDate = moment(date).startOf("day").utc(true).toISOString();

      if (this.dates.includes(formattedDate)) {
        this.deleteDate(formattedDate);
      } else {
        const updatedDates = [...this.dates, formattedDate];

        this.$emit("update:dates", updatedDates);
      }

      this.currentDate = null;
    },

    deleteDate(dateToRemove, event) {
      if (event) event.preventDefault();

      const updatedDates = [
        ...this.dates.filter((date) => date !== dateToRemove),
      ];

      this.$emit("update:dates", updatedDates);
    },

    formatDate(date) {
      return moment(date)
        .utc()
        .format(this.$store.state.applicationState.dateFormat);
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ant-row {
  display: flex;
  justify-content: space-between;
  margin: 0 !important;
}

.ant-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}

.ant-calendar-picker {
  margin: 0;
  width: 100%;
}

.ant-tag {
  margin: 0;
}

.date-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #fff;
  padding: 10px;
}

.ant-input-number {
  width: 100%;
}
</style>
