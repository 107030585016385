import { ALERT_TYPES, SEVERITY_TYPES } from "./constants";

export default {
  getSeverity() {
    return (severity) => {
      return (
        SEVERITY_TYPES[severity] ?? {
          title: severity,
          color: SEVERITY_TYPES.normal.color,
        }
      );
    };
  },
  getType() {
    return (type) => {
      return (
        ALERT_TYPES[type] ?? {
          title: type,
          color: ALERT_TYPES.associate_left.color,
        }
      );
    };
  },
};
