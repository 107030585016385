<template>
  <a-modal
    width="600px"
    v-model="visibleModal"
    :centered="true"
    :footer="null"
    :title="modalTitle"
    @cancel="$emit('close')"
  >
    <div v-if="loading" class="loading-spin">
      <a-spin />
    </div>
    <div class="pt-3 text-center" v-else-if="totalWriteUps === 0">
      <h3>No Write-Up Found.</h3>
    </div>
    <a-tabs v-model="activeKey" v-else>
      <a-tab-pane key="1" tab="History">
        <div class="list">
          <div class="item" v-for="(item, index) in writeUps" :key="index">
            <div class="flex justify-content-between">
              <h4>№ {{ index + 1 }} from {{ formatDate(item.create_date) }}</h4>
              <a-button
                :loading="loadingButtons[item.id]"
                @click="cancelWriteUp(item.id)"
                type="danger"
              >
                Cancel
              </a-button>
            </div>
            <a-descriptions :column="1" bordered size="small">
              <a-descriptions-item label="Status">
                <a-badge
                  :color="getStatus(item.status).color"
                  :text="getStatus(item.status).label"
                />
              </a-descriptions-item>
              <a-descriptions-item label="Authorization">
                <a-tag
                  v-if="item.authorization"
                  :color="getAuthStatus(item.authorization).color"
                >
                  <span>{{ getAuthStatus(item.authorization).label }}</span>
                </a-tag>
                <span v-else>-</span>
              </a-descriptions-item>
              <a-descriptions-item label="Author">
                {{ item.author.first_name }}
                {{ item.author.last_name }}
              </a-descriptions-item>
              <a-descriptions-item
                label="Type of disciplinary action being taken"
              >
                {{ getDisciplinaryTitle(item.disciplinary_action_type) }}
              </a-descriptions-item>
              <a-descriptions-item label="Violation category">
                {{ getViolationTitle(item.violation_category) }}
              </a-descriptions-item>
              <a-descriptions-item label="Reason for action">
                {{ item.reason_for_action || " - " }}
              </a-descriptions-item>
              <a-descriptions-item label="Days until the follow-up review date">
                {{ item.follow_up_review_date_days || " - " }}
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Statistics" force-render>
        <div class="flex flex-column gap-2">
          <div>
            <h3>Write-Up By Status</h3>
            <bar-chart class="chart" :chart-data="statusChartData" />
          </div>
          <div>
            <h3>Write-Up By Auth Types</h3>
            <bar-chart class="chart" :chart-data="authTypeChartData" />
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import api from "@/api";
import {
  Badge,
  Button,
  Descriptions,
  Modal,
  Spin,
  Tabs,
  Tag,
  notification,
} from "ant-design-vue";
import BarChart from "@/components/bar-chart.vue";
import {
  authorizationsMap,
  writeUpStatuses,
  disciplinaryActionTypes,
  violationCategories,
} from "@/const";
import moment from "moment-timezone";

export default {
  data() {
    return {
      writeUps: [],
      visibleModal: false,
      loading: false,
      loadingButtons: {},
      activeKey: "1",
    };
  },
  components: {
    "a-modal": Modal,
    "a-tabs": Tabs,
    "a-tab-pane": Tabs.TabPane,
    "a-spin": Spin,
    "a-button": Button,
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
    "a-tag": Tag,
    "a-badge": Badge,
    "bar-chart": BarChart,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    associateNumber: {
      type: String,
      default: null,
    },
  },
  mixins: [api],
  methods: {
    showErrorNotification(message) {
      notification["error"]({
        message: "Error",
        description: message,
      });
    },

    showSuccessNotification(message) {
      notification["success"]({
        message: "Success",
        description: message,
      });
    },

    async fetchWriteUps() {
      this.loading = true;
      try {
        const resp = await this.apiGetAllWriteUp(this.associateNumber);
        this.writeUps = resp.data.writeups;
      } catch (error) {
        this.showErrorNotification(
          "Failed to load write-up history data: Unable to retrieve the history data due to a system error. Please check your network connection or try again later."
        );
      } finally {
        this.loading = false;
      }
    },

    async cancelWriteUp(id) {
      this.$set(this.loadingButtons, id, true);

      try {
        await this.apiCancelWriteUp({ id: id });

        this.writeUps = this.writeUps.filter((item) => item.id !== id);

        this.showSuccessNotification("Successfully canceled the write-up.");
      } catch (error) {
        this.showErrorNotification(
          "Failed to cancel the write-up: Unable to complete the cancellation due to an unexpected issue. Please check your network connection or try again later."
        );
      } finally {
        this.$set(this.loadingButtons, id, false);
      }
    },

    formatDate(date_iso) {
      return moment(date_iso).format("MMM D hh:mm A");
    },

    createChartData(items, map, keyField) {
      const itemCount = items.reduce((acc, item) => {
        acc[item[keyField]] = (acc[item[keyField]] || 0) + 1;
        return acc;
      }, {});

      const { labels, colors, data } = Object.entries(map).reduce(
        (acc, [key, { label, color }]) => {
          if (itemCount[key]) {
            acc.labels.push(label);
            acc.colors.push(color);
            acc.data.push(itemCount[key]);
          }
          return acc;
        },
        { labels: [], colors: [], data: [] }
      );

      return {
        labels,
        datasets: [
          {
            label: "Write-Ups Status Count",
            backgroundColor: colors,
            data,
          },
        ],
      };
    },

    getStatus(value) {
      const status = writeUpStatuses[value];

      return { label: status?.label || "", color: status?.color || "#fff" };
    },

    getAuthStatus(value) {
      const status = authorizationsMap[value];

      return { label: status?.label || "", color: status?.color || "#fff" };
    },

    getDisciplinaryTitle(status) {
      return disciplinaryActionTypes.find((item) => item.key === status)?.label;
    },

    getViolationTitle(status) {
      return violationCategories.find((item) => item.key === status)?.label;
    },
  },
  computed: {
    totalWriteUps() {
      return this.writeUps.length;
    },

    statusChartData() {
      return this.createChartData(this.writeUps, writeUpStatuses, "status");
    },

    authTypeChartData() {
      return this.createChartData(
        this.writeUps,
        authorizationsMap,
        "authorization"
      );
    },

    modalTitle() {
      return `Write-Up History (Total: ${this.totalWriteUps})`;
    },
  },
  watch: {
    visible(newVal) {
      this.visibleModal = newVal;

      if (newVal) {
        this.fetchWriteUps();
      }
    },
  },
  mounted() {
    if (this.visible && this.associateNumber) {
      this.fetchWriteUps();
    }
  },
};
</script>

<style scoped>
::v-deep .ant-modal-body {
  padding-top: 0;
  height: 600px;
}

::v-deep .ant-modal-content {
  overflow: hidden;
}

::v-deep .ant-modal-title {
  font-size: 24px;
}

::v-deep .ant-descriptions-item-colon {
  width: 50%;
}

::v-deep .ant-tabs-tabpane {
  overflow: auto;
}

::v-deep .ant-tabs-content {
  height: 500px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.chart {
  width: 80%;
  margin-bottom: 10px;
}
</style>
