<template>
  <div class="user-avatar">
    <a-skeleton
      v-if="isLoading"
      active
      :avatar="{
        size: width ?? defaultSideLengthPx,
        shape: 'circle',
      }"
      :title="false"
      :paragraph="false"
    >
    </a-skeleton>

    <img
      v-else
      alt="User Avatar"
      :src="imageSource"
      :style="{
        width: String(width ?? defaultSideLengthPx) + 'px',
        height: String(height ?? defaultSideLengthPx) + 'px',
      }"
    />
  </div>
</template>

<script>
import { Skeleton } from "ant-design-vue";

export default {
  components: {
    "a-skeleton": Skeleton,
  },
  props: {
    imagePath: {
      type: String,
      required: false,
    },
    width: {
      type: Number,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      defaultSideLengthPx: 40,
      imageSource: require("@/assets/img/avatar-placeholder.svg"),
      previousImagePath: null,
      isLoading: true,
    };
  },
  computed: {
    isLinkAvailable() {
      return this.imagePath != null && this.imagePath.length > 0;
    },
  },
  watch: {
    imagePath(newVal) {
      if (newVal !== this.previousImagePath) {
        this.tryToGetAvatar(newVal);
        this.previousImagePath = newVal;
      }
    },
  },
  methods: {
    tryToGetAvatar(imagePath) {
      if (this.isLinkAvailable) {
        const img = new Image();
        img.onload = () => {
          this.imageSource = imagePath;
          this.isLoading = false;
        };
        img.onerror = () => {
          this.imageSource = require("@/assets/img/avatar-placeholder.svg");
          this.isLoading = false;
        };
        img.src = imagePath + "?timestamp=" + new Date().getTime();
      } else {
        this.imageSource = require("@/assets/img/avatar-placeholder.svg");
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.tryToGetAvatar(this.imagePath);
  },
};
</script>

<style>
.user-avatar img {
  border-radius: 20%;
  object-fit: cover;
  border: 1px solid rgb(100, 99, 99);
}

.ant-skeleton-header .ant-skeleton-avatar-circle {
  border-radius: 20% !important;
  border: 1px solid rgb(100, 99, 99);
}

.ant-skeleton-header {
  padding: 0;
}
</style>
