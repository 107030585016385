import { notification } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const { mapState: alertState } = createNamespacedHelpers("alerts");
const { mapActions: modalActions } = createNamespacedHelpers("modals");

export default {
  data() {
    return {
      alertHandlers: {
        validate_joined_associate: this.handleValidateJoinedAssociate,
        associate_joined: this.handleAssociateJoined,
        associate_left: this.handleAssociateLeft,
        join_project_request: this.handleJoinProjectRequest,
        project_summary: this.handleProjectSummary,
        sequence_completed: this.handleSequenceCompleted,
        unauthorized_authorizations: this.handleUnauthorizedAuthorizations,
        document_signed: this.handleDocumentSigned,
        associate_document_expired: this.handleAssociateDocumentExpired,
        associate_inactive: this.handleAssociateInactive,
        associate_resigned: this.handleAssociateResigned,
      },
    };
  },
  computed: {
    ...alertState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...modalActions(["openModal"]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
        duration: 5,
      });
    },

    switchAlertType() {
      if (!this.alert) {
        return;
      }

      const type = this.alert.type;

      if (this.alertHandlers[type]) {
        this.alertHandlers[type]();
      }
    },
    handleValidateJoinedAssociate() {
      this.openModal({
        type: "profile",
        data: {
          visible: true,
          associate_number: this.alert.data.associate_number,
          edit: () => {},
          open_tab: "personal",
          is_editing: true,
        },
      });
    },

    handleAssociateJoined() {
      //TODO: change data

      return;
      // this.openModal({
      //   type: "profile",
      //   data: {
      //     visible: true,
      //     associate_number: this.alert.data.associate.associate_number,
      //     edit: () => {},
      //     open_tab: "personal",
      //   },
      // });
    },

    handleAssociateLeft() {
      //TODO: change data

      return;
      // this.openModal({
      //   type: "profile",
      //   data: {
      //     visible: true,
      //     associate_number: this.alert.data.associate.associate_number,
      //     edit: () => {},
      //     open_tab: "personal",
      //   },
      // });
    },

    handleJoinProjectRequest() {
      return;
    },

    handleProjectSummary() {
      const reportDate = this.alert.data.date;

      if (!reportDate) {
        return;
      }

      this.$router.push({
        path: "/daily-summary",
        query: {
          reportDate,
        },
      });
    },

    handleSequenceCompleted() {
      const associate = this.alert.data.associate
        ? this.alert.data.associate
        : this.alert.receiver;

      if (!associate) return;

      this.openModal({
        type: "profile",
        data: {
          visible: true,
          associate_number: associate.associate_number,
          edit: () => {},
          open_tab: "personal",
        },
      });
    },

    handleUnauthorizedAuthorizations() {
      this.$router.push("/project-shift-auths");
    },

    handleDocumentSigned() {
      //TODO: change data

      return;
      // this.openModal({
      //   type: "profile",
      //   data: {
      //     visible: true,
      //     associate_number: this.alert.data.associate.associate_number,
      //     edit: () => {},
      //     open_tab: "signed_documents",
      //   },
      // });
    },

    handleAssociateDocumentExpired() {
      this.openModal({
        type: "profile",
        data: {
          visible: true,
          associate_number: this.alert.data.associate_number,
          edit: () => {},
          open_tab: "associate_documents",
        },
      });
    },

    handleAssociateInactive() {
      const associate_number = this.alert.data.associate_number;

      if (!associate_number) return;

      this.showNotification("info", this.alert.title, this.alert.details);

      this.openModal({
        type: "profile",
        data: {
          visible: true,
          associate_number: associate_number,
          edit: () => {},
          open_tab: "associate_documents",
        },
      });
    },

    handleAssociateResigned() {
      const associate_number = this.alert.data.associate_number;

      if (!associate_number) return;

      this.showNotification("info", this.alert.title, this.alert.details);

      this.openModal({
        type: "profile",
        data: {
          visible: true,
          associate_number: associate_number,
          edit: () => {},
          open_tab: "job",
        },
      });
    },
  },
};
