<template>
  <a-modal title="Filters" width="600px" :visible="visible" @cancel="cancel">
    <div class="grid">
      <b class="col-4">Associate</b>
      <search-associates
        class="col-8"
        :is-associate-id="true"
        :multiply="true"
        :value.sync="filters.associate_ids"
      />

      <b class="col-4">Reviewer</b>
      <search-associates
        class="col-8"
        :is-associate-id="true"
        :multiply="true"
        :value.sync="filters.reviewer_ids"
      />

      <b class="col-4">Provider</b>
      <search-associates
        class="col-8"
        :is-associate-id="true"
        :multiply="true"
        :value.sync="filters.provider_ids"
      />

      <b class="col-4">Dates</b>

      <a-range-picker
        class="col-8"
        format="MM/DD/YYYY"
        v-model="dateProxy"
        valueFormat="YYYY-MM-DD"
        :ranges="dateRange"
      />

      <b class="col-4">Payroll Auths</b>
      <custom-select
        class="col-8"
        :multiple="true"
        :value.sync="payrollSelected"
        placeholder="Type"
        :options="payrollAuths"
        name="title"
        optionKey="key"
      />

      <b class="col-4">Disciplinary Auths</b>

      <custom-select
        class="col-8"
        :multiple="true"
        :value.sync="disciplinarySelected"
        placeholder="Type"
        :options="disciplinaryAuths"
        name="title"
        optionKey="key"
      />

      <b class="col-4">Status</b>
      <custom-select
        class="col-8"
        :multiple="false"
        :value.sync="filters.status"
        placeholder="Status"
        :options="statuses"
        name="name"
        optionKey="id"
      />

      <b class="col-4">Qualification</b>
      <custom-select
        class="col-8"
        :multiple="false"
        :value.sync="roleProxy"
        placeholder="Qualification"
        :options="roles"
        name="name"
        optionKey="id"
      />

      <template v-if="isShowPaymentType">
        <b class="col-4">Payment Type</b>
        <custom-select
          class="col-8"
          :multiple="false"
          :value.sync="filters.payment_type"
          placeholder="Payment Type"
          :options="paymentTypes"
          name="name"
          optionKey="id"
        />
      </template>

      <div class="flex gap-2 flex-wrap mt-2">
        <a-button
          @click="toggleFilter('assigned_to_me')"
          :type="filters.assigned_to_me ? 'primary' : 'default'"
        >
          Assigned to Me
        </a-button>
      </div>
    </div>
    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button type="danger" :disabled="isEmptyFilters" @click="reset">
          Reset
        </a-button>
        <a-button type="primary" @click="apply"> Apply </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {
  Modal,
  Button,
  Select,
  Checkbox,
  Switch,
  DatePicker,
} from "ant-design-vue";
import SearchAssociates from "../../../components/search-associates.vue";
import {
  AUTH_PROCESS_STATUSES,
  AUTH_STATUSES,
} from "@/store/modules/shifts/constants";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";
import CustomSelect from "@/components/ui/custom-select.vue";
import util from "@/util";

const { mapGetters: shiftGetters } = createNamespacedHelpers("shifts");

const filtersBaseState = Object.freeze({
  end: undefined,
  start: undefined,
  role: undefined,
  associate_ids: [],
  auth_types: [],
  reviewer_ids: [],
  provider_ids: [],
  payment_type: undefined,
  assigned_to_me: false,
  status: undefined,
});

export default {
  components: {
    "a-modal": Modal,
    "a-button": Button,
    "a-select": Select,
    "a-checkbox": Checkbox,
    "a-select-option": Select.Option,
    "a-switch": Switch,
    "a-range-picker": DatePicker.RangePicker,
    "search-associates": SearchAssociates,
    "custom-select": CustomSelect,
  },
  data() {
    return {
      filters: { ...filtersBaseState },

      disciplinaryAuths: Object.entries(AUTH_STATUSES)
        .map(([key, value]) => ({
          key,
          ...value,
        }))
        .filter((item) => item.type === "disciplinary"),

      payrollAuths: Object.entries(AUTH_STATUSES)
        .map(([key, value]) => ({
          key,
          ...value,
        }))
        .filter((item) => item.type === "payroll"),

      statuses: Object.entries(AUTH_PROCESS_STATUSES).map(([key, value]) => ({
        id: key,
        name: value,
      })),

      roles: this.$store.state.applicationState.projectRoles,
      paymentTypes: this.$store.state.applicationState.paymentTypes.map(
        (item) => ({ name: util.capitalize(item.name), id: item.name })
      ),
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    currentFilters: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...shiftGetters(["getAuthType"]),

    isShowPaymentType() {
      return (
        this.$store.state.applicationState.currentPaymentTypeName === "All"
      );
    },

    isDisabledWithResolvedAuths() {
      return (
        (this.filters.reviewer_ids ?? []).length > 0 ||
        (this.filters.provider_ids ?? []).length > 0
      );
    },

    isEmptyFilters() {
      return JSON.stringify(filtersBaseState) === JSON.stringify(this.filters);
    },

    dateRange() {
      return {
        Today: [moment(), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
      };
    },

    dateProxy: {
      get() {
        return [this.filters.start, this.filters.end];
      },
      set(value) {
        this.filters.start = value[0];
        this.filters.end = value[1];
      },
    },

    roleProxy: {
      get() {
        return this.filters.role ? Number(this.filters.role) : undefined;
      },
      set(value) {
        this.filters.role = value;
      },
    },

    payrollSelected: {
      get() {
        return (this.filters.auth_types ?? []).filter((key) =>
          this.payrollAuths.some((auth) => auth.key === key)
        );
      },
      set(values) {
        this.filters.auth_types = [...values, ...this.disciplinarySelected];
      },
    },

    disciplinarySelected: {
      get() {
        return (this.filters.auth_types ?? []).filter((key) =>
          this.disciplinaryAuths.some((auth) => auth.key === key)
        );
      },
      set(values) {
        this.filters.auth_types = [...values, ...this.payrollSelected];
      },
    },
  },
  watch: {
    currentFilters: {
      handler(newValue) {
        this.filters = { ...newValue };
      },
      immediate: true,
      deep: true,
    },
    visible: {
      handler() {
        this.filters = { ...this.currentFilters };
      },
    },
    "$store.state.applicationState.currentPaymentType": {
      handler() {
        this.filters.payment_type = undefined;
        this.$router.replace({ query: this.filters }).catch(() => {});
      },
      immediate: true,
    },
  },
  methods: {
    reset() {
      if (this.isEmptyFilters) {
        this.cancel();
      } else {
        this.filters = { ...filtersBaseState };
        this.$router.replace({ query: {} }).catch(() => {});
      }
    },

    toggleFilter(type) {
      this.filters[type] = this.filters[type] ? undefined : true;
    },

    cancel() {
      this.$emit("update:visible", false);
    },

    apply() {
      this.cancel();

      this.$router.replace({ query: this.filters }).catch(() => {});
    },
  },

  mounted() {
    const defaultFilters = {
      end: this.$route.query.end ?? moment().format("YYYY-MM-DD"),
      status: this.$route.query.status ?? "initial",
    };

    this.$router
      .replace({
        query: {
          ...this.$route.query,
          ...defaultFilters,
        },
      })
      .catch(() => {});
  },
};
</script>

<style scoped>
.ant-calendar-picker {
  margin: 0;
}
</style>
