import util from "@/util";

export default {
  data() {
    return {
      pagination: {
        pageSizeOptions: ["10", "50", "100"],
        pageSize: 50,
        current: 1,
        total: 0,
        showSizeChanger: true,
      },

      tableScrollHeight: 0,

      columns: [
        {
          title: "Title",
          dataIndex: "title",
          width: "200px",
          align: "center",
        },
        {
          title: "Severity",
          key: "severity",
          width: "100px",
          scopedSlots: { customRender: "severity" },
          align: "center",
        },
        {
          title: "Type",
          width: "200px",
          scopedSlots: { customRender: "type" },
          align: "center",
        },
        {
          title: "Details",
          dataIndex: "details",
          align: "left",
          scopedSlots: { customRender: "details" },
        },
        {
          title: "Date",
          key: "created_at",
          align: "center",
          width: "150px",
          customRender: (_, record) => {
            return util.convertDateTimeToCurrentTimeZoneWithFormat(
              record.created_at,
              "YYYY-MM-DD"
            );
          },
        },
      ],
    };
  },
  methods: {
    calculateTableHeight() {
      this.tableScrollHeight = window.innerHeight - 260;
    },

    handleTableChange(pagination) {
      const isPaginationChanged =
        pagination.current !== this.pagination.current ||
        pagination.pageSize !== this.pagination.pageSize;

      if (!isPaginationChanged) {
        return;
      }

      this.pagination = { ...pagination };

      this.loadData();
    },
  },
  mounted() {
    this.calculateTableHeight();
    window.addEventListener("resize", this.calculateTableHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.calculateTableHeight);
  },
};
