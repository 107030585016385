import apiClient from "@/services/api";

export default {
  async getProjects({ commit }, { query = "" }) {
    try {
      const { data } = await apiClient.post("api/search_project", { query });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setProjects", data);
    } catch (error) {
      throw error.message;
    }
  },

  async getUsers({ commit }, { query = "", disabled }) {
    try {
      const { data } = await apiClient.post("api/tc_search_employee", {
        query: query && query.trim().length > 0 ? query : "/all",
        disabled,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setUsers", data.associate_list);
    } catch (error) {
      throw error.message;
    }
  },

  async getAssociates(
    { commit, rootState },
    { query = "", filter, cancelPreviousRequests = false }
  ) {
    const currentPaymentType =
      rootState.applicationState.currentPaymentTypeName;
    const manager_scopes = rootState.applicationState.associate.manager_scopes;

    try {
      const { data } = await apiClient.post(
        "/api/associate/search",
        {
          project_id: rootState.applicationState.currentProject.id,
          query: query && query.trim().length > 0 ? query : "/all",
          filter: {
            ...filter,
            unverified: filter.unverified && true,
            manager_scopes,
            payment_type:
              currentPaymentType === "All"
                ? filter.payment_type
                : currentPaymentType,
          },
        },
        {
          cancelPreviousRequests: cancelPreviousRequests,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setAssociates", data.associates);
    } catch (error) {
      throw error.message;
    }
  },

  async getLegalEntities({ commit, rootState }, { query = "" }) {
    try {
      const { data } = await apiClient.get(
        `/api/project/legal_entity/search?project=${rootState.applicationState.currentProject.id}&name=${query}`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setLegalEntities", data.items);
    } catch (error) {
      throw error.message;
    }
  },
};
