<template>
  <a-spin :spinning="loading">
    <div>
      <div class="flex flex-column gap-4">
        <div class="flex justify-content-between align-items-center">
          <h3 class="m-0">Onboarding</h3>
          <div class="flex gap-2">
            <a-button icon="export" type="primary" @click="sendToOnboarding">
              Send To Onboarding
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { Button, notification, Spin } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const { mapState: profileState } = createNamespacedHelpers("profile");
const { mapActions: completeRequestActions } =
  createNamespacedHelpers("completeRequest");

export default {
  components: {
    "a-button": Button,
    "a-spin": Spin,
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
    }),
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...completeRequestActions(["sendAssociateToOnboarding"]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    async sendToOnboarding() {
      this.loading = true;

      const payload = {
        associate: [this.associate.id],
        type: "onboarding",
      };

      if (!this.associate.id) {
        return;
      }

      try {
        await this.sendAssociateToOnboarding(payload);

        this.showNotification(
          "success",
          "Success",
          "Associate successfully send to onboarding"
        );
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
