<template>
  <div>
    <a-modal
      v-model="changeCompanyLogoVisible"
      title="Change Company Logo"
      :footer="null"
    >
      <change-company-logo @set-project-logo="handleChangeLogo" />
    </a-modal>
    <div>
      <div class="project-name-with-logo-container">
        <div
          class="logo-container"
          :style="{ backgroundImage: 'url(' + projectAvatarSource + ')' }"
          @click="handleUpdateLogo"
        >
          <a-icon type="camera" class="camera-icon" />
        </div>
        <h2 class="project-name">{{ project.name }}</h2>
      </div>

      <a-form layout="inline" :form="form">
        <div class="content-view">
          <div class="content-view-block">
            <h3>Company</h3>
            <p>
              <b
                >Name
                <span v-if="editingCompany" class="required-field">*</span></b
              >
              <a-form-item v-if="editingCompany">
                <a-input
                  placeholder="Enter name"
                  v-decorator="[
                    'name',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the company name!',
                        },
                        {
                          min: 2,
                          max: 100,
                          message:
                            'The name must be between 2 and 100 characters.',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
              <span v-else>{{ project.name }}</span>
            </p>
            <p>
              <b
                >Description
                <span v-if="editingCompany" class="required-field">*</span></b
              >
              <a-form-item v-if="editingCompany">
                <a-textarea
                  placeholder="Enter description"
                  :auto-size="{ minRows: 1, maxRows: 5 }"
                  v-decorator="[
                    'description',
                    { rules: [{ required: false }] },
                  ]"
                />
              </a-form-item>
              <span v-else>{{ project.description }}</span>
            </p>
            <p>
              <b>Owner</b>
              <span>{{ project.owner }}</span>
            </p>
            <p>
              <b>Join Type</b>
              <a-form-item v-if="editingCompany">
                <a-select
                  placeholder="Select join type"
                  v-decorator="[
                    'joinType',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please select a join type!',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option value="open">Open</a-select-option>
                  <a-select-option value="request">Request</a-select-option>
                  <a-select-option value="invite">Invite</a-select-option>
                </a-select>
              </a-form-item>
              <span v-else>{{ Util.capitalize(project.join_type) }}</span>
            </p>
            <p>
              <b>Tags</b>
              <a-form-item v-if="editingCompany">
                <a-textarea
                  placeholder="Enter tags"
                  :auto-size="{ minRows: 1, maxRows: 5 }"
                  v-decorator="['tags', { rules: [{ required: false }] }]"
                />
              </a-form-item>
              <span v-else>{{ project.tags ?? "-" }}</span>
            </p>
            <p>
              <b>Tax ID (EIN)</b>
              <a-form-item v-if="editingCompany">
                <a-input
                  placeholder="Enter Tax ID (EIN)"
                  v-decorator="['tax_id']"
                />
              </a-form-item>
              <span v-else>{{ project.tax_id ?? "-" }}</span>
            </p>
            <p>
              <b
                >Address
                <span v-if="editingCompany" class="required-field">*</span></b
              >
              <span class="flex flex-column gap-2" v-if="editingCompany">
                <a-form-item>
                  <a-input
                    placeholder="Address Line 1"
                    v-decorator="[
                      'addressLine1',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter address line 1!',
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>

                <a-form-item>
                  <a-input
                    placeholder="Address Line 2"
                    v-decorator="[
                      'addressLine2',
                      { rules: [{ required: false }] },
                    ]"
                  />
                </a-form-item>
                <a-form-item>
                  <a-input
                    placeholder="City"
                    v-decorator="[
                      'city',
                      {
                        rules: [
                          { required: true, message: 'Please enter city!' },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
                <a-form-item>
                  <a-input
                    placeholder="State"
                    v-decorator="['state', { rules: [{ required: false }] }]"
                  />
                </a-form-item>
                <a-form-item>
                  <a-input
                    placeholder="Zipcode"
                    v-decorator="[
                      'zipcode',
                      {
                        rules: [
                          { required: true, message: 'Please enter zipcode!' },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </span>
              <span v-else class="address">{{ projectAddress }}</span>
            </p>
          </div>
          <div class="content-view-block">
            <h3>Active Features</h3>
            <p>
              <b>Shift Confirmation</b>
              <a-form-item v-if="editingCompany">
                <a-switch
                  v-decorator="[
                    'shiftConfirmation',
                    { valuePropName: 'checked', rules: [{ required: true }] },
                  ]"
                />
              </a-form-item>
              <span v-else>{{
                project.shift_confirmation ? "Yes" : "No"
              }}</span>
            </p>
            <p>
              <b>Require COVID Survey</b>
              <a-form-item v-if="editingCompany">
                <a-switch
                  v-decorator="[
                    'requireCovidSurvey',
                    { valuePropName: 'checked', rules: [{ required: true }] },
                  ]"
                />
              </a-form-item>
              <span v-else>{{
                project.require_covid_survey ? "Yes" : "No"
              }}</span>
            </p>
            <p>
              <b>Shift Authorizations</b>
              <a-form-item v-if="editingCompany">
                <a-switch
                  v-decorator="[
                    'actionAuthorization',
                    { valuePropName: 'checked', rules: [{ required: true }] },
                  ]"
                />
              </a-form-item>
              <span v-else>{{
                project.action_authorization ? "Yes" : "No"
              }}</span>
            </p>
            <p>
              <b>SMS Notifications</b>
              <a-form-item v-if="editingCompany">
                <a-switch
                  v-decorator="[
                    'sendingSmsWithPush',
                    { valuePropName: 'checked', rules: [{ required: true }] },
                  ]"
                />
              </a-form-item>
              <span v-else>{{
                project.sending_sms_with_push ? "Yes" : "No"
              }}</span>
            </p>

            <h3>Contacts</h3>
            <p>
              <b
                >Email
                <span v-if="editingCompany" class="required-field">*</span></b
              >
              <a-form-item v-if="editingCompany">
                <a-input
                  placeholder="Enter email address"
                  v-decorator="[
                    'email',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the email address!',
                        },
                        {
                          type: 'email',
                          message: 'The input is not a valid email!',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
              <span v-else>{{ project.email }}</span>
            </p>
            <p>
              <b
                >Phone
                <span v-if="editingCompany" class="required-field">*</span></b
              >
              <a-form-item v-if="editingCompany">
                <a-input
                  placeholder="Enter phone number"
                  v-decorator="[
                    'phone',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the phone number!',
                        },
                        {
                          pattern: /^\+?\d{10,15}$/,
                          message:
                            'The phone number must be 10 to 15 digits long and may start with a +.',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
              <span v-else>{{ project.phone }}</span>
            </p>
            <div class="flex justify-content-end gap-2">
              <template v-if="editingCompany">
                <a-button type="danger" :loading="loading" @click="cancelEdit">
                  Cancel
                </a-button>
                <a-button
                  type="primary"
                  :loading="loading"
                  :disabled="disableSave"
                  @click="handleSubmit"
                >
                  Save
                </a-button>
              </template>
              <template v-else>
                <a-button
                  :disabled="!hasPermission('manage_project')"
                  type="primary"
                  @click="startEdit"
                >
                  Edit
                </a-button>
              </template>
            </div>
          </div>

          <div class="content-view-block">
            <template>
              <h3>Company Policy</h3>
              <div v-if="project.project_policy_type == 'text'">
                {{
                  project.project_policy_text ??
                  "Company policy is not available"
                }}
              </div>
              <div v-else-if="project.project_policy_type == 'pdf'">
                <a-button icon="download" @click="downloadPolicy">
                  Download Policy
                </a-button>
              </div>
            </template>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import Util from "@/util";
import api from "@/api";
import {
  notification,
  Switch,
  DatePicker,
  Spin,
  Select,
  Icon,
  Button,
  Form,
  Input,
  Modal,
} from "ant-design-vue";
import BarChart from "@/components/bar-chart.vue";
import store from "@/store";
import ChangeCompanyLogo from "@/components/change-company-logo.vue";
import { vueTourOptions } from "@/const";
import permissionMixin from "@/mixins/permissionMixin";

export default {
  components: {
    ChangeCompanyLogo,
    "a-button": Button,
    "a-select": Select,
    "a-select-option": Select.Option,
    "bar-chart": BarChart,
    "a-switch": Switch,
    "a-spin": Spin,
    "a-icon": Icon,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-input": Input,
    "a-textarea": Input.TextArea,
    "a-modal": Modal,
  },
  mixins: [api, permissionMixin],
  data() {
    return {
      projectStatistics: undefined,

      statsInRange: false,

      statsFrom: undefined,
      statsTo: undefined,
      statsRole: undefined,

      form: undefined,
      loading: false,

      editingCompany: false,
      fieldsTouched: false,

      changeCompanyLogoVisible: false,

      projectAvatarSource: require("@/assets/img/avatar-placeholder.svg"),
    };
  },
  computed: {
    vueTourOptions() {
      return vueTourOptions;
    },
    Util() {
      return Util;
    },

    project() {
      return this.$store.state.applicationState.currentProject;
    },
    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    roles() {
      return this.$store.state.applicationState.projectRoles;
    },

    projectLogoUrlAvailable() {
      return this.project.logo_url != null;
    },

    combinedActiveFeatures() {
      const activeFeatures = [];

      if (this.project.require_covid_survey === true) {
        activeFeatures.push("Require COVID Survey");
      }
      if (this.project.action_authorization === true) {
        activeFeatures.push("Action Authorization");
      }
      if (this.project.onboarding_feature_status === "enabled") {
        activeFeatures.push("Onboarding");
      }
      if (this.project.departure_feature_status === "enabled") {
        activeFeatures.push("Departure");
      }
      let combined = "";

      activeFeatures.forEach((feature, idx) => {
        combined += feature;
        if (idx !== activeFeatures.length - 1) {
          combined += ", ";
        }
      });

      return combined;
    },

    projectAddress() {
      if (!(this.project || this.project.address)) {
        return "-";
      }

      const address = this.project.address;
      let addressString = address.address_line1;

      if (address.address_line2) {
        addressString += "\n" + address.address_line2;
      }
      if (address.city) {
        addressString += "\n" + address.city;
      }
      if (address.state) {
        addressString += "\n" + address.state;
      }
      if (address.zipcode) {
        addressString += "\n" + address.zipcode;
      }

      return addressString;
    },

    disableSave() {
      return !this.form || !this.fieldsTouched;
    },
  },
  methods: {
    initForm() {
      this.form = this.$form.createForm(this, {
        name: "company_edit_form",
        onFieldsChange: (_) => {
          this.fieldsTouched = true;
        },
        mapPropsToFields: () => {
          return {
            name: this.$form.createFormField({
              value: this.project.name,
            }),
            description: this.$form.createFormField({
              value: this.project.description,
            }),
            joinType: this.$form.createFormField({
              value: this.project.join_type,
            }),
            tags: this.$form.createFormField({
              value: this.project.tags,
            }),
            addressLine1: this.$form.createFormField({
              value: this.project.address.address_line1,
            }),
            addressLine2: this.$form.createFormField({
              value: this.project.address.address_line2,
            }),
            city: this.$form.createFormField({
              value: this.project.address.city,
            }),
            state: this.$form.createFormField({
              value: this.project.address.state,
            }),
            zipcode: this.$form.createFormField({
              value: this.project.address.zipcode,
            }),
            email: this.$form.createFormField({
              value: this.project.email,
            }),
            phone: this.$form.createFormField({
              value: this.project.phone,
            }),
            tax_id: this.$form.createFormField({
              value: this.project.tax_id,
            }),
            shiftConfirmation: this.$form.createFormField({
              value: this.project.shift_confirmation,
            }),
            requireCovidSurvey: this.$form.createFormField({
              value: this.project.require_covid_survey,
            }),
            actionAuthorization: this.$form.createFormField({
              value: this.project.action_authorization,
            }),
            sendingSmsWithPush: this.$form.createFormField({
              value: this.project.sending_sms_with_push,
            }),
          };
        },
      });
    },

    startEdit() {
      this.editingCompany = true;
    },
    cancelEdit() {
      this.editingCompany = false;
      this.fieldsTouched = false;
      this.initForm();
    },

    showIncorrectFormError(msg) {
      notification.warning({
        message: "Incorrect Form",
        description:
          msg ??
          "Some fields are not filled in or are filled in incorrectly. Please check the fields",
      });
    },

    showUpdateSuccessMessage() {
      notification.success({
        message: "Company Info Updated",
      });
    },
    showUpdateErrorMessage() {
      notification.warning({
        message: "Company Info Update Error",
        description: "Please try again later",
      });
    },

    showUpdateIconSuccessMessage() {
      notification.success({
        message: "Company Icon Updated",
      });
    },
    showUpdateIconErrorMessage() {
      notification.warning({
        message: "Company Icon Update Error",
        description: "Please try again later",
      });
    },

    handleChangeLogo(logoFileId) {
      this.changeCompanyLogoVisible = false;
      this.loading = true;

      const projectFields = this.project;

      this.apiSetProject(projectFields, logoFileId)
        .then(async ({ data }) => {
          if (data.error_code && data.error_code !== "0") {
            this.showUpdateIconErrorMessage();
            return;
          }

          this.showUpdateIconSuccessMessage();
          await store.dispatch("loadApplicationState");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (err) {
          this.showIncorrectFormError();
          return;
        }

        this.loading = true;

        const projectFields = {
          id: this.project.id,
          schedule_integration_url: this.project.schedule_integration_url,
          name: values.name,
          description: values.description,
          email: values.email,
          phone: values.phone,
          tax_id: values.tax_id,
          require_covid_survey: values.requireCovidSurvey,
          shift_confirmation: values.shiftConfirmation,
          action_authorization: values.actionAuthorization,
          sending_sms_with_push: values.sendingSmsWithPush,
          join_type: values.joinType,
          tags: values.tags,
          address: {
            address_line1: values.addressLine1,
            address_line2: values.addressLine2,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
          },
        };

        this.apiSetProject(projectFields)
          .then(async (response) => {
            const data = response.data;

            if (data.error_code && data.error_code !== "0") {
              this.showUpdateErrorMessage();
              return;
            }

            this.showUpdateSuccessMessage();
            await store.dispatch("loadApplicationState");
          })
          .finally(() => {
            this.loading = false;
            this.cancelEdit();
          });
      });
    },

    handleUpdateLogo() {
      this.changeCompanyLogoVisible = true;
    },

    tryToGetAvatar() {
      if (this.projectLogoUrlAvailable) {
        const img = new Image();
        const imgPath = this.project.logo_url;
        img.onload = () => {
          this.projectAvatarSource = imgPath;
        };
        img.onerror = () => {
          this.projectAvatarSource = require("@/assets/img/avatar-placeholder.svg");
        };
        img.src = imgPath;
      }
    },

    downloadPolicy() {
      window.open(this.project.project_policy_text, "_blank");
    },
  },
  mounted() {
    this.initForm();
    this.tryToGetAvatar();
  },
};
</script>

<style scoped>
.content-view-block {
  width: 365px;
}

.active-features {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.active-features-list {
  text-align: right;
  max-width: 250px;
  overflow-wrap: break-word;
}

.roles-list {
  max-height: 260px;
  overflow-y: auto;
}

.roles-list p {
  margin-right: 5px;
}

.loading-spin-project-info {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.open-view {
  font-size: 12px;
}

.open-view:hover {
  cursor: pointer;
}

.address {
  white-space: pre-wrap;
}

.join-type-select {
  min-width: 100px;
}

.project-name-with-logo-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.project-name {
  margin: unset;
}

.ant-form-inline .ant-form-item {
  margin-right: 0;
  max-width: 170px;
}

.logo-container {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  overflow: hidden;
}

.camera-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: white;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.logo-container:hover .camera-icon {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.role-select {
  width: 150px;
}
</style>
