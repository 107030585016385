<template>
  <div>
    <h3>Shift Payments</h3>
    <a-table
      class="shifts-to-pay-table"
      size="small"
      :row-selection="rowSelection"
      :columns="columns"
      :pagination="false"
      :data-source="paymentInfo.shift_payments"
      :row-key="(record) => record.shift_payment.id"
      @expand="handleExpand"
    >
      <span slot="date" slot-scope="text, record">
        {{ formatDateRange(record.shift.work_start, record.shift.work_end) }}
      </span>
      <span slot="status" slot-scope="text, record">
        <a-tag
          v-if="
            record.shift_payment.review_info &&
            record.shift_payment.review_info.status
          "
          class="m-0"
          :color="
            getShiftPaymentStatus(record.shift_payment.review_info.status).color
          "
          >{{
            getShiftPaymentStatus(record.shift_payment.review_info.status).title
          }}</a-tag
        >
        <span v-else>-</span>
      </span>
      <span slot="reviewer" slot-scope="text, record">
        <div v-if="record.shift_payment.review_info">
          <grouped-associate-info
            :first-name="record.shift_payment.review_info.reviewer.first_name"
            :last-name="record.shift_payment.review_info.reviewer.last_name"
            :photo-url="record.shift_payment.review_info.reviewer.photo_url"
            :associate-number="
              record.shift_payment.review_info.reviewer.associate_number
            "
            :unverified="!record.shift_payment.review_info.reviewer.is_verified"
          />
          <div class="review-date">
            {{ formatDate(record.shift_payment.review_info.reviewed_at) }}
          </div>
        </div>

        <span v-else>-</span>
      </span>
      <span slot="associate" slot-scope="text, record">
        <grouped-associate-info
          :first-name="record.associate.first_name"
          :last-name="record.associate.last_name"
          :photo-url="record.associate.photo_url"
          :associate-number="record.associate.associate_number"
          :unverified="!record.associate.is_verified"
        />
      </span>
      <div slot="expandedRowRender" slot-scope="record">
        <div class="grid" v-if="isShiftExist(record.shift.id)">
          <template v-if="record.shift_payment.review_info">
            <b class="col-4">Comment</b>
            <span class="col-8">{{
              record.shift_payment.review_info.comment ?? " - "
            }}</span>

            <b class="col-4">Review Date</b>
            <span class="col-8">{{
              formatDate(record.shift_payment.review_info.reviewed_at)
            }}</span>
          </template>

          <b class="col-4">Scheduled</b>
          <span class="col-8">{{ getScheduledDuration(record.shift.id) }}</span>

          <b class="col-4">Signed</b>
          <span class="col-8">{{ getSignedDuration(record.shift.id) }}</span>

          <b class="col-4">Actual</b>
          <span class="col-8">{{ getActualDuration(record.shift.id) }}</span>

          <b class="col-4">Lunch Break</b>
          <span class="col-8">{{
            getFirstLunchDuration(record.shift.id)
          }}</span>

          <b class="col-4">2nd Lunch Break</b>
          <span class="col-8">{{
            getSecondLunchDuration(record.shift.id)
          }}</span>

          <b class="col-4">Payment Type</b>
          <span class="col-8">{{ shifts[record.shift.id].payment_type }}</span>

          <b class="col-4">Hourly Rate, $ </b>
          <span class="col-8">{{ convertedRate(record.shift.id) }}</span>

          <template v-if="isEmployeePayment">
            <b class="col-4">Instant Loan</b>
            <span class="col-8">{{ instant_loan_percent * 100 }}%</span>
          </template>

          <b class="col-4">Attachments</b>
          <a-dropdown
            class="col-8"
            size="small"
            v-if="attachmentsAvailable(record.shift.id)"
          >
            <a-menu slot="overlay">
              <a-menu-item
                v-for="file in shifts[record.shift.id].files"
                :key="file.id"
                @click="openAttachmentOnNewPage(file.url)"
              >
                <a-icon type="file-image" />
                {{ file.url }}
              </a-menu-item>
            </a-menu>
            <a-button
              class="h-fit"
              :disabled="loading"
              size="small"
              icon="download"
            >
              Download
            </a-button>
          </a-dropdown>
          <span class="col-8" v-else> N/A </span>
        </div>
      </div>
    </a-table>
  </div>
</template>

<script>
import {
  Button,
  Dropdown,
  Icon,
  Menu,
  notification,
  Table,
  Tag,
} from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";
import shiftsHelper from "@/helpers/shifts";
import util from "@/util";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";

const { mapActions: shiftActions, mapGetters: shiftGetters } =
  createNamespacedHelpers("shifts");
const { mapState: paymentState } = createNamespacedHelpers("payment");

export default {
  components: {
    "a-table": Table,
    "a-button": Button,
    "a-tag": Tag,
    "a-menu": Menu,
    "a-menu-item": Menu.Item,
    "a-icon": Icon,
    "a-dropdown": Dropdown,
    "grouped-associate-info": GroupedAssociateInfo,
  },
  mixins: [shiftsHelper],
  props: {
    selectedRowKeys: {
      type: Array,
      default: [],
    },
    availableValidation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shifts: {},
      loadingShifts: {},

      columns: [
        {
          title: "Date",
          scopedSlots: { customRender: "date" },
          align: "center",
        },
        {
          title: "Status",
          scopedSlots: { customRender: "status" },
          align: "center",
        },
        {
          title: "Reviewer",
          scopedSlots: { customRender: "reviewer" },
        },
        {
          title: "Associate",
          scopedSlots: { customRender: "associate" },
        },
      ],

      instant_loan_percent:
        this.$store.state.applicationState.currentProject.payment_settings
          .instant_loan_percent,
    };
  },
  computed: {
    ...paymentState({
      paymentInfo: (state) => state.payment,
    }),
    ...shiftGetters(["getShiftPaymentStatus"]),

    isEmployeePayment() {
      return this.paymentInfo.payment.type === "employee";
    },

    rowSelection() {
      return this.isEmployeePayment || !this.availableValidation
        ? null
        : {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: (record) => ({
              disabled: !!record.shift_payment.review_info,
            }),
          };
    },
  },
  watch: {
    paymentInfo: {
      handler(newVal) {
        this.shifts = newVal.shift_payments.reduce((acc, item) => {
          acc[item.shift.id] = item;
          return acc;
        }, {});

        if (this.isEmployeePayment) {
          const selectedShifts = this.paymentInfo.shift_payments.map(
            (item) => item.shift_payment.id
          );

          this.$emit("update:selectedRowKeys", selectedShifts);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...shiftActions(["getShift"]),

    isShiftExist(id) {
      return this.shifts[id];
    },

    async handleExpand(expanded, record) {
      const shift_id = record.shift.id;

      if (!expanded || this.loadingShifts[shift_id] === false) {
        return;
      }

      this.loadingShifts[shift_id] = true;

      try {
        const payload = {
          shift_id,
        };

        const data = await this.getShift(payload);

        this.shifts[shift_id] = { ...data };
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingShifts[shift_id] = false;
      }
    },

    onSelectChange(selectedRowKeys) {
      this.$emit("update:selectedRowKeys", selectedRowKeys);
    },

    openAttachmentOnNewPage(file) {
      window.open(file, "_blank");
    },

    formatDate(date) {
      return moment(date).format(this.$store.state.applicationState.dateFormat);
    },

    convertedRate(shift_id) {
      return util.convertDbToMoney(this.shifts[shift_id].hour_rate);
    },

    attachmentsAvailable(shift_id) {
      return (
        this.shifts[shift_id].files && this.shifts[shift_id].files.length > 0
      );
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    getScheduledHours(shift_id) {
      return this.formatWorkHours(
        moment(this.shifts[shift_id].scheduled_start),
        moment(this.shifts[shift_id].scheduled_end)
      );
    },

    getScheduledDuration(shift_id) {
      const { scheduled_start, scheduled_end } = this.shifts[shift_id];

      const { start, end } = this.formatTimeRange(
        scheduled_start,
        scheduled_end
      );
      const scheduledHours = this.getScheduledHours(shift_id);

      return `${start} - ${end} (${scheduledHours})`;
    },

    getSignedHours(shift_id) {
      const {
        work_start,
        work_end,
        lunch_start,
        lunch_end,
        second_lunch_start,
        second_lunch_end,
      } = this.shifts[shift_id];

      const parseTime = (time) => (time ? moment(time) : null);

      return this.formatWorkHours(
        parseTime(work_start),
        parseTime(work_end),
        parseTime(lunch_start),
        parseTime(lunch_end),
        parseTime(second_lunch_start),
        parseTime(second_lunch_end)
      );
    },

    getSignedDuration(shift_id) {
      const { work_start, work_end } = this.shifts[shift_id];
      const { start, end } = this.formatTimeRange(work_start, work_end);
      const signedHours = this.getSignedHours(shift_id);

      return `${start} - ${end} (${signedHours})`;
    },

    getActualHours(shift_id) {
      const {
        actual_work_start,
        actual_work_end,
        actual_lunch_start,
        actual_lunch_end,
        actual_second_lunch_start,
        actual_second_lunch_end,
      } = this.shifts[shift_id];

      const parseTime = (time) => (time ? moment(time) : null);

      return this.formatWorkHours(
        parseTime(actual_work_start),
        parseTime(actual_work_end),
        parseTime(actual_lunch_start),
        parseTime(actual_lunch_end),
        parseTime(actual_second_lunch_start),
        parseTime(actual_second_lunch_end)
      );
    },

    getActualDuration(shift_id) {
      const { actual_work_start, actual_work_end } = this.shifts[shift_id];

      if (!actual_work_start || !actual_work_end) {
        return " - ";
      }

      const { start, end } = this.formatTimeRange(
        actual_work_start,
        actual_work_end
      );
      const actualHours = this.getActualHours(shift_id);

      return `${start} - ${end} (${actualHours})`;
    },

    getLunchDuration(startTime, endTime) {
      if (!startTime || !endTime) {
        return " - ";
      }

      const timeRange = this.formatTimeRange(startTime, endTime);
      const duration = moment(endTime).diff(moment(startTime), "minutes");

      return `${timeRange.start} - ${
        timeRange.end
      } (${this.minutesToHoursAndMinutes(duration)})`;
    },

    getFirstLunchDuration(shift_id) {
      return this.getLunchDuration(
        this.shifts[shift_id].lunch_start,
        this.shifts[shift_id].lunch_end
      );
    },

    getSecondLunchDuration(shift_id) {
      return this.getLunchDuration(
        this.shifts[shift_id].second_lunch_start,
        this.shifts[shift_id].second_lunch_end
      );
    },
  },
};
</script>

<style scoped>
.shifts-to-pay-table {
  max-height: 300px !important;
  overflow-y: auto;
}

.status-tag {
  margin: unset;
}

.review-date {
  padding-left: 40px;
  font-weight: 600;
}
</style>
