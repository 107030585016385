<template>
  <a-modal
    title="Move Shifts"
    :visible="visible"
    :destroy-on-close="true"
    width="600px"
    @cancel="closeModal"
  >
    <a-form-model
      layout="horizontal"
      :model="form"
      :rules="rules"
      ref="formRef"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Holder"
        prop="holder_id"
      >
        <search-associates
          :is-associate-id="true"
          :value.sync="form.holder_id"
        />
      </a-form-model-item>

      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Assignee"
        prop="assignee_id"
      >
        <a-select
          v-model="form.assignee_id"
          :dropdown-match-select-width="false"
          :allow-clear="true"
          :disabled="!form.holder_id"
          :placeholder="loadingProfiles ? 'Loading...' : 'Assignee'"
          :loading="loadingProfiles"
          option-label-prop="children"
        >
          <template #notFoundContent>
            <span class="text-center">
              The associate does not have any other profiles <br> to move shifts
              to</span
            ></template
          >
          <a-select-option
            v-for="profile in profiles"
            :key="profile.id"
            :value="profile.id"
          >
            {{
              `[${profile.associate_number}] ${profile.first_name} ${profile.last_name}`
            }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>

    <a-table
      :row-selection="{
        selectedRowKeys: form.shift_ids,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
          props: { disabled: record.shift.status === 'deleted' },
        }),
      }"
      :rowKey="(record) => record.shift.id"
      :locale="{ emptyText: 'Shifts Not Found' }"
      :loading="loadingShifts"
      size="small"
      :pagination="pagination"
      :columns="columns"
      :data-source="associateShifts"
      :scroll="{ y: 200 }"
      @change="handleTableChange"
    >
      <span slot="date" slot-scope="text, record">
        {{ formatDate(record.shift.scheduled_start) }} -
        {{ formatDate(record.shift.scheduled_end) }}
      </span>
      <span slot="status" slot-scope="text, record">
        <a-tooltip>
          <a-tag
            class="auth-tag"
            :color="
              record.shift.status !== 'completed'
                ? getShiftStatus(record.shift.status).color
                : getShiftPaymentRequestStatus(record.shift.payment_status)
                    .color
            "
          >
            {{
              record.shift.status !== "completed"
                ? getShiftStatus(record.shift.status).title
                : getShiftPaymentRequestStatus(record.shift.payment_status)
                    .title
            }}
          </a-tag>
        </a-tooltip>
      </span>
      <template slot="footer">
        <span v-if="hasSelected">{{
          `Selected ${form.shift_ids.length} shifts`
        }}</span>
      </template>
    </a-table>

    <template slot="footer">
      <div class="flex justify-content-between">
        <a-button @click="closeModal">Close</a-button>
        <div class="flex justify-content-end">
          <a-tooltip v-if="hasEmptyProfiles">
            <template slot="title">
              The associate does not have any other profiles to move shifts to.
            </template>
            <a-button
              @click="submit"
              :disabled="hasEmptyProfiles"
              icon="export"
              type="primary"
              ghost
            >
              Move
            </a-button>
          </a-tooltip>
          <a-button
            v-else
            @click="submit"
            :disabled="hasEmptyProfiles"
            icon="export"
            type="primary"
            ghost
          >
            Move
          </a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script>
import SearchAssociates from "@/components/search-associates.vue";
import {
  Button,
  FormModel,
  Modal,
  notification,
  Select,
  Table,
  Tag,
  Tooltip,
} from "ant-design-vue";
import moment from "moment-timezone";
import { createNamespacedHelpers } from "vuex";

const { mapActions: shiftActions, mapGetters: shiftGetters } =
  createNamespacedHelpers("shifts");
const { mapActions: profileActions } = createNamespacedHelpers("profile");
const { mapState: searchState } = createNamespacedHelpers("search");

const initialPagination = {
  pageSizeOptions: ["5", "10", "15"],
  showSizeChanger: true,
  pageSize: 5,
  current: 1,
  total: 0,
};

export default {
  components: {
    "a-modal": Modal,
    "a-form-model": FormModel,
    "a-form-model-item": FormModel.Item,
    "a-button": Button,
    "a-tooltip": Tooltip,
    "a-table": Table,
    "a-tag": Tag,
    "a-select": Select,
    "a-select-option": Select.Option,
    "search-associates": SearchAssociates,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initialValues: {
      type: Object,
      default: undefined,
    },
  },
  emits: ["move"],
  data() {
    return {
      loadingShifts: false,
      loadingProfiles: false,
      loading: false,

      associateShifts: [],
      profiles: [],

      form: {
        holder_id: undefined,
        assignee_id: undefined,
        shift_ids: [],
      },

      pagination: initialPagination,
      columns: [
        {
          title: "Shift ID",
          dataIndex: "shift.id",
          align: "center",
        },
        {
          title: "Date",
          scopedSlots: { customRender: "date" },
          align: "center",
        },
        {
          title: "Status",
          scopedSlots: { customRender: "status" },
          align: "center",
        },
      ],

      rules: {
        holder_id: [
          {
            required: true,
            message: "Please select associate",
            trigger: "change",
          },
        ],
        assignee_id: [
          {
            required: true,
            message: "Please select associate",
            trigger: "change",
          },
        ],
        shift_ids: [
          {
            required: true,
            message: "Please select shifts",
            trigger: "change",
            type: "array",
          },
        ],
      },
    };
  },
  watch: {
    "form.holder_id": {
      handler(newVal) {
        this.form.shift_ids = [];
        this.form.assignee_id = undefined;

        if (newVal) {
          const foundAssociate = this.users.find(
            (item) => item.associate_id === newVal
          );

          if (foundAssociate) {
            this.fetchAssociateProfiles(foundAssociate?.associate_number);
          }

          this.fetchShifts();
        }
      },
      immediate: true,
    },
    initialValues: {
      handler(newVal) {
        if (!newVal) {
          return;
        }

        if (newVal.associateNumber) this.fillAssociate(newVal.associateNumber);
      },
      immediate: true,
      deep: true,
    },
    users: {
      handler(newVal) {
        if (newVal.length > 0 && this.initialValues?.associateNumber) {
          this.fillAssociate(this.initialValues?.associateNumber);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...searchState({
      users: (state) => state.users,
    }),

    ...shiftGetters(["getShiftStatus", "getShiftPaymentRequestStatus"]),

    hasSelected() {
      return this.form.shift_ids.length > 0;
    },
    hasEmptyProfiles() {
      return this.profiles.length === 0;
    },
  },
  methods: {
    ...profileActions(["getAssociateProfiles"]),
    ...shiftActions(["getShifts", "postShiftMove"]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    fillAssociate(value) {
      if (!this.users) {
        return undefined;
      }

      const foundAssociate = this.users.find(
        (item) => item.employee_id === value
      );

      this.form.holder_id = foundAssociate
        ? foundAssociate.associate_id
        : undefined;
    },

    formatDate(date) {
      return moment(date).format(this.$store.state.applicationState.dateFormat);
    },

    closeModal() {
      this.$emit("update:visible", false);
    },

    onSelectChange(selectedRowKeys) {
      this.form.shift_ids = selectedRowKeys;
    },

    handleTableChange(pagination) {
      const isPaginationChanged =
        pagination.current !== this.pagination.current ||
        pagination.pageSize !== this.pagination.pageSize;

      if (!isPaginationChanged) {
        return;
      }

      this.pagination = { ...pagination };

      this.fetchShifts();
    },

    async fetchShifts() {
      try {
        this.loadingShifts = true;
        const payload = {
          filter: {
            shift_type: "worked",
            associate_id: this.form.holder_id,
          },
          page: this.pagination.current,
          size: this.pagination.pageSize,
          cancelPreviousRequests: false,
        };

        const { items, total_count } = await this.getShifts(payload);

        this.associateShifts = items;
        this.pagination.total = total_count;
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingShifts = false;
      }
    },

    async fetchAssociateProfiles(associate_number) {
      if (!associate_number) {
        return;
      }

      this.loadingProfiles = true;

      try {
        const payload = {
          associate_number,
        };

        const { associates } = await this.getAssociateProfiles(payload);

        this.profiles = [
          ...associates.filter((item) => item.id !== this.form.holder_id),
        ];
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingProfiles = false;
      }
    },

    async submit() {
      try {
        const valid = await new Promise((resolve) => {
          this.$refs.formRef.validate(resolve);
        });

        if (!valid) {
          this.showNotification(
            "warning",
            "Validation Error",
            "Please fill in all required fields correctly."
          );

          return;
        }

        this.loading = true;

        const payload = {
          shifts: this.form.shift_ids,
          new_associate: this.form.assignee_id,
        };

        await this.postShiftMove(payload);

        notification.success({
          message: "Success",
          description: "Shifts were successfully moved",
          duration: 10,
          btn: (h) => {
            return h(
              Button,
              {
                props: {
                  type: "primary",
                  size: "small",
                },
                on: {
                  click: () => {
                    this.$router
                      .replace({
                        query: {
                          ...this.$route.query,
                          shift_ids: [this.form.shift_ids],
                        },
                      })
                      .catch(() => {});
                  },
                },
              },
              "Open Shifts"
            );
          },
        });

        this.closeModal();
        this.$emit("move");
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
