<template>
  <a-modal title="Send Announcement" v-model="visibleModal" @cancel="close">
    <div v-if="!sendResult && associate" class="send-push-modal-content">
      <div class="content-view">
        <div class="content-view-block">
          <p>
            <b>Recipient</b>
            {{ combineAssociateName() }}
          </p>
          <p>
            <b>Title</b>
            <a-input v-model="textMessageTitle" placeholder="Title" />
          </p>
          <p>
            <b>Message</b>
            <a-textarea v-model="textMessage" placeholder="Message" auto-size />
          </p>
          <p>
            <b>Type</b>
            <a-select
              class="notification-type-select"
              v-model="notificationTypesSelection"
              mode="multiple"
              placeholder="Select notification types"
            >
              <a-select-option key="withAlert"> Alert </a-select-option>
              <a-select-option key="withSms"> SMS </a-select-option>
              <a-select-option key="withEmail"> Email </a-select-option>
              <a-select-option key="withWorkNotice"> Work Notice </a-select-option>
            </a-select>
          </p>
          <p class="files-section">
            <b>Files</b>
            <a-upload
              list-type="picture-card"
              accept="image"
              :file-list="fileList"
              :before-upload="beforeUpload"
              :remove="handleRemoveFile"
            >
              <div v-if="fileList.length < 8">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
          </p>
        </div>
      </div>
    </div>
    <div v-else>
      {{ sendResult }}
    </div>
    <template slot="footer">
      <div>
        <template v-if="!sendResult">
          <div class="flex justify-content-between">
            <a-button @click="close">Cancel</a-button>
            <a-button
              type="primary"
              :loading="sent"
              :disabled="sendButtonDisabled"
              @click="sendNotification"
            >
              Send
            </a-button>
          </div>
        </template>
        <template v-else>
          <a-button @click="close">OK</a-button>
        </template>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {
  Modal,
  Button,
  Input,
  notification,
  Upload,
  Icon,
  Select,
} from "ant-design-vue";

import api from "@/api";
import Util from "@/util";

export default {
  components: {
    "a-modal": Modal,
    "a-input": Input,
    "a-icon": Icon,
    "a-textarea": Input.TextArea,
    "a-button": Button,
    "a-upload": Upload,

    "a-select": Select,
    "a-select-option": Select.Option,
  },
  props: ["associate"],
  mixins: [api],
  data() {
    return {
      sent: false,
      textMessageTitle: undefined,
      textMessage: undefined,
      sendResult: undefined,
      notificationTypesSelection: ["withAlert"],

      fileList: [],
      fileIds: [],
    };
  },
  computed: {
    visibleModal() {
      return this.associate != null;
    },
    sendButtonDisabled() {
      return (
        !this.textMessage ||
        this.textMessage.length < 1 ||
        (!this.withSms && !this.withEmail && !this.withAlert && !this.withWorkNotice)
      );
    },

    withSms() {
      return this.notificationTypesSelection.includes("withSms");
    },
    withAlert() {
      return this.notificationTypesSelection.includes("withAlert");
    },
    withEmail() {
      return this.notificationTypesSelection.includes("withEmail");
    },
    withWorkNotice() {
      return this.notificationTypesSelection.includes('withWorkNotice');
    },
  },
  methods: {
    combineAssociateName() {
      if (!this.associate) {
        return "";
      }

      return Util.combineAssociateName(
        this.associate.first_name,
        this.associate.last_name
      );
    },
    async sendNotification() {
      this.sent = true;

      if (this.fileList.length > 0) {
        await this.handleUploadFiles();
      }

      await this.apiSendNotificationToAssociate(
        this.associate.associate_number,
        this.textMessageTitle,
        this.textMessage,
        this.withAlert,
        this.withSms,
        this.withEmail,
        this.withWorkNotice,
        this.fileIds
      )
        .then(() => (this.sendResult = "Notifications were successfully sent"))
        .catch(() => (this.sendResult = "An unknown error has occurred."));
    },

    handleRemoveFile(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    async handleUploadFiles() {
      await this.apiUploadFiles(this.fileList)
        .then((response) => {
          this.fileIds = response.data.ids;
        })
        .catch(() =>
          notification["warning"]({
            message: "Upload Files Error",
            description: "Notification will send without them",
          })
        );
    },

    close() {
      this.textMessageTitle = undefined;
      this.textMessage = undefined;
      this.sendResult = undefined;

      this.fileList = [];
      this.fileIds = [];

      this.sent = false;

      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.ant-input,
.notification-type-select {
  width: 375px;
}

.send-push-modal-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.content-view {
  padding: 10px 0;
}
.content-view > * {
  max-width: unset;
  width: 100%;
}

.files-section {
  gap: 68px;
}
</style>
