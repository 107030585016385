<template>
  <a-form-model
    layout="horizontal"
    :model="shiftForm"
    :rules="rules"
    :validateOnRuleChange="true"
    ref="shiftFormRef"
    class="flex flex-column gap-2"
    :label-col="{ span: 6 }"
    :wrapper-col="{ span: 18 }"
  >
    <a-form-model-item
      :colon="false"
      :label-col="{ span: 20 }"
      :wrapper-col="{ span: 4 }"
      label-align="left"
      label="Set Scheduled and Actual As Signed"
    >
      <a-switch v-model="shiftForm.set_scheduled_and_actual_as_signed" />
    </a-form-model-item>

    <div class="flex flex-column gap-2">
      <h3>Signed Work Time</h3>
      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Start"
        prop="work_start"
      >
        <a-date-picker
          v-model="shiftForm.work_start"
          placeholder="Select Start"
          :show-time="{ format: 'h:mm a', use12Hours: true }"
          :format="dateTimeFormat"
          :disabled-date="disabledSignedStartDate"
          @change="handleChangeSignedStart"
        />
      </a-form-model-item>
      <a-form-model-item
        :colon="false"
        label-align="left"
        label="End"
        prop="work_end"
      >
        <a-date-picker
          v-model="shiftForm.work_end"
          placeholder="Select End"
          :show-time="{ format: 'h:mm a', use12Hours: true }"
          :format="dateTimeFormat"
          :disabled="!shiftForm.work_start"
          :disabled-date="disabledSignedEndDate"
          @change="handleChangeSignedEnd"
        />
      </a-form-model-item>
      <div class="flex justify-content-end gap-2">
        <a-button @click="clearSignedTime">Clear</a-button>
      </div>
    </div>

    <div class="flex flex-column gap-2">
      <h3>First Lunch Time</h3>
      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Start"
        prop="lunch_start"
      >
        <a-date-picker
          v-model="shiftForm.lunch_start"
          placeholder="Select Start"
          :show-time="{ format: 'h:mm a', use12Hours: true }"
          :format="dateTimeFormat"
          :disabled="isLunchDisabled"
          :disabled-time="disabledLunchStartTime"
          :disabled-date="disabledLunchStartDate"
          @change="handleChangeLunchStart"
          @openChange="handleOpenChangeLunchStart"
        />
      </a-form-model-item>
      <a-form-model-item
        :colon="false"
        label-align="left"
        label="End"
        prop="lunch_end"
      >
        <a-date-picker
          v-model="shiftForm.lunch_end"
          placeholder="Select End"
          :show-time="{ format: 'h:mm a', use12Hours: true }"
          :format="dateTimeFormat"
          :disabled="isLunchEndDisabled"
          :disabled-time="disabledLunchEndTime"
          :disabled-date="disabledLunchEndDate"
          @change="handleChangeLunchEnd"
          @openChange="handleOpenChangeLunchEnd"
        />
      </a-form-model-item>
      <div class="flex justify-content-end gap-2">
        <a-button @click="clearLunch">Clear</a-button>
      </div>
      <div class="flex justify-content-between">
        <p class="white-space-nowrap font-bold m-0">1st Lunch Waive</p>
        <a-switch
          v-model="isLunchWaived"
          :disabled="!isLunchWaiveAvailable || !isLunchWaiver"
        />
      </div>
      <a-tag
        color="blue"
        class="m-0 white-space-normal text-left"
        v-for="message in lunchWaiveInfo"
        :key="message"
      >
        <a-icon type="info-circle" />
        {{ message }}
      </a-tag>
    </div>

    <div class="flex flex-column gap-2">
      <h3>Second Lunch Time</h3>
      <a-form-model-item
        :colon="false"
        label-align="left"
        label="Start"
        prop="second_lunch_start"
      >
        <a-date-picker
          v-model="shiftForm.second_lunch_start"
          placeholder="Select Start"
          :show-time="{ format: 'h:mm a', use12Hours: true }"
          :format="dateTimeFormat"
          :disabled="isSecondLunchDisabled"
          :disabled-time="disabledSecondLunchStartTime"
          :disabled-date="disabledSecondLunchStartDate"
          @change="handleChangeSecondLunchStart"
          @openChange="handleOpenChangeSecondLunchStart"
        />
      </a-form-model-item>
      <a-form-model-item
        :colon="false"
        label-align="left"
        label="End"
        prop="second_lunch_end"
      >
        <a-date-picker
          v-model="shiftForm.second_lunch_end"
          placeholder="Select End"
          :show-time="{ format: 'h:mm a', use12Hours: true }"
          :format="dateTimeFormat"
          :disabled="isSecondLunchEndDisabled"
          :disabled-time="disabledSecondLunchEndTime"
          :disabled-date="disabledSecondLunchEndDate"
          @change="handleChangeSecondLunchEnd"
          @openChange="handleOpenChangeSecondLunchEnd"
        />
      </a-form-model-item>
      <div class="flex justify-content-end gap-2">
        <a-button @click="clearSecondLunch">Clear</a-button>
      </div>
      <div class="flex justify-content-between">
        <p class="white-space-nowrap font-bold m-0">2nd Lunch Waive</p>
        <a-switch
          v-model="isSecondLunchWaived"
          :disabled="!isSecondLunchWaiveAvailable || !isLunchWaiver"
        />
      </div>
      <a-tag
        color="blue"
        class="m-0 white-space-normal text-left"
        v-for="message in secondLunchWaiveInfo"
        :key="message"
      >
        <a-icon type="info-circle" />
        {{ message }}
      </a-tag>
    </div>
  </a-form-model>
</template>

<script>
import {
  Button,
  DatePicker,
  FormModel,
  Icon,
  Select,
  Switch,
  Tag,
} from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";
import validation from "@/modules/shifts/mixins/ShiftValidationMixin";

const { mapActions: shiftActions, mapState: shiftState } =
  createNamespacedHelpers("shifts");

const DEFAULT_SHIFT_DURATION = 510;

export default {
  components: {
    "a-button": Button,
    "a-select": Select,
    "a-form-model-item": FormModel.Item,
    "a-form-model": FormModel,
    "a-switch": Switch,
    "a-date-picker": DatePicker,
    "a-tag": Tag,
    "a-icon": Icon,
  },
  mixins: [validation],
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    associate_number: {
      type: String,
      default: undefined,
    },
    isLunchWaiver: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLunchWaived: false,
      isSecondLunchWaived: false,

      rules: {
        work_start: [
          {
            validator: this.createValidator("validateWorkStart"),
            trigger: "change",
            message: this.validateWorkStart,
          },
        ],
        work_end: [
          {
            validator: this.createValidator("validateWorkEnd"),
            trigger: "change",
            message: this.validateWorkEnd,
          },
        ],
        lunch_start: [
          {
            validator: this.createValidator("validateFirstLunchStart"),
            trigger: "change",
            message: this.validateFirstLunchStart,
          },
        ],
        lunch_end: [
          {
            validator: this.createValidator("validateFirstLunchEnd"),
            trigger: "change",
            message: this.validateFirstLunchEnd,
          },
        ],
        second_lunch_start: [
          {
            validator: this.createValidator("validateSecondLunchStart"),
            trigger: "change",
            message: this.validateSecondLunchStart,
          },
        ],
        second_lunch_end: [
          {
            validator: this.createValidator("validateSecondLunchEnd"),
            trigger: "change",
            message: this.validateSecondLunchEnd,
          },
        ],
      },
    };
  },
  watch: {
    shiftForm: {
      handler(newForm) {
        for (const [field, value] of Object.entries(newForm)) {
          this.handleFieldChange(field, value);
        }
      },
      deep: true,
    },
    isLunchWaived(newVal) {
      this.clearLunch();
      this.handleFieldChange("lunch_waived", newVal ?? false);
    },
    isSecondLunchWaived(newVal) {
      this.clearSecondLunch();
      this.handleFieldChange("lunch_waived", newVal ?? false);
    },
  },
  computed: {
    ...shiftState({
      shiftForm: (state) => state.shiftForm,
    }),
  },

  methods: {
    ...shiftActions(["updateShiftField", "getEstimatedLunches"]),

    createValidator(validateFn) {
      return (rule, value, callback) => {
        const error = this[validateFn];
        if (error) {
          callback(new Error(error));
        } else {
          callback();
        }
      };
    },

    validateFields() {
      this.$refs.shiftFormRef
        .validate()
        .then(() => {})
        .catch((errors) => {});
    },

    handleFieldChange(field, value) {
      this.updateShiftField({ field, value });
    },

    handleChangeSignedStart(date) {
      if (!date) this.clearSignedTime();
      else {
        this.shiftForm.work_start = this.resetSeconds(
          this.shiftForm.work_start
        );

        if (!this.shiftForm.work_end)
          this.shiftForm.work_end = this.workEndInitialTime;

        this.saveEstimatedTimes();

        this.validateFields();
      }
    },

    handleChangeSignedEnd(date) {
      if (!date) this.clearLunch();
      else this.shiftForm.work_end = this.workEndInitialTime;

      this.saveEstimatedTimes();
      this.validateFields();
    },

    handleOpenChangeLunchStart() {
      this.shiftForm.lunch_start = this.lunchStartInitialTime;

      if (!this.shiftForm.lunch_end)
        this.shiftForm.lunch_end = this.lunchEndInitialTime;
    },

    handleChangeLunchStart(date) {
      if (!date) this.clearLunch();
      else this.handleOpenChangeLunchStart();
      this.validateFields();
    },

    handleOpenChangeLunchEnd() {
      this.shiftForm.lunch_end = this.lunchEndInitialTime;
    },

    handleChangeLunchEnd(date) {
      if (!date) this.clearSecondLunch();
      else this.handleOpenChangeLunchEnd();
      this.validateFields();
    },

    handleOpenChangeSecondLunchStart() {
      this.shiftForm.second_lunch_start = this.secondLunchStartInitialTime;

      if (!this.shiftForm.second_lunch_end)
        this.shiftForm.second_lunch_end = this.secondLunchEndInitialTime;
    },

    handleChangeSecondLunchStart(date) {
      if (!date) this.clearSecondLunch();
      else this.handleOpenChangeSecondLunchStart();

      this.validateFields();
    },

    handleOpenChangeSecondLunchEnd() {
      this.shiftForm.second_lunch_end = this.secondLunchEndInitialTime;
    },

    handleChangeSecondLunchEnd(date) {
      if (!date) return;
      else this.handleOpenChangeSecondLunchEnd();
      this.validateFields();
    },

    // clear fields

    clearSignedTime() {
      this.shiftForm.work_start = null;
      this.shiftForm.work_end = null;

      this.$refs.shiftFormRef.clearValidate("work_start");
      this.$refs.shiftFormRef.clearValidate("work_end");

      this.clearLunch();
      this.clearSecondLunch();
      this.validateFields();
    },

    clearLunch() {
      this.shiftForm.lunch_start = null;
      this.shiftForm.lunch_end = null;

      this.$refs.shiftFormRef.clearValidate("lunch_start");
      this.$refs.shiftFormRef.clearValidate("lunch_end");

      this.clearSecondLunch();
      this.validateFields();
    },

    clearSecondLunch() {
      this.shiftForm.second_lunch_start = null;
      this.shiftForm.second_lunch_end = null;

      this.$refs.shiftFormRef.clearValidate("second_lunch_start");
      this.$refs.shiftFormRef.clearValidate("second_lunch_end");
      this.validateFields();
    },

    fillLunchWaived() {
      this.isLunchWaived =
        this.isLunchWaiveAvailable && !!this.shiftForm.lunch_waived;

      this.isSecondLunchWaived =
        this.isSecondLunchWaiveAvailable && !!this.shiftForm.lunch_waived;
    },

    // autofill

    async saveEstimatedTimes() {
      const {
        estimated_lunch_end,
        estimated_lunch_start,
        estimated_second_lunch_end,
        estimated_second_lunch_start,
      } = await this.fillLunches();

      this.estimated_lunch_end = estimated_lunch_end;
      this.estimated_lunch_start = estimated_lunch_start;
      this.estimated_second_lunch_end = estimated_second_lunch_end;
      this.estimated_second_lunch_start = estimated_second_lunch_start;
    },

    async fillWorkTimes() {
      const estimatedWorkStart = this.shiftForm.scheduled_start;
      const estimatedWorkEnd = this.shiftForm.scheduled_end;

      this.shiftForm.work_start =
        estimatedWorkStart ?? this.shiftForm.work_start;
      this.shiftForm.work_end = estimatedWorkEnd ?? this.shiftForm.work_end;

      await this.saveEstimatedTimes();

      if (!this.isValidFirstLunchStart) {
        this.shiftForm.lunch_start = this.estimated_lunch_start;
        this.shiftForm.lunch_end = this.estimated_lunch_end;
      }

      if (!this.isValidSecondLunchStart) {
        this.shiftForm.second_lunch_start = this.estimated_second_lunch_start;
        this.shiftForm.second_lunch_end = this.estimated_second_lunch_end;
      }
    },

    async fillLunches() {
      const payload = {
        associate_number: this.associate_number,
        shift_start: this.shiftForm.work_start,
        shift_end: this.shiftForm.work_end,
        lunch_waived: false,
      };

      try {
        const data = await this.getEstimatedLunches(payload);

        return data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.fillLunchWaived();
  },
};
</script>

<style scoped>
.ant-form-item {
  margin-bottom: 0;
}

.ant-calendar-picker {
  width: 100%;
}

h3 {
  margin: 0;
}
</style>
