<template>
  <a-modal
    width="800px"
    :visible="visible"
    :footer="null"
    title="Create Write-Up"
    @cancel="closeModal"
  >
    <div class="grid">
      <b class="col-6">Associate</b>
      <grouped-associate-info
        class="col-6"
        :first-name="associate.first_name"
        :last-name="associate.last_name"
        :role="associate?.role?.name"
        :associate-number="associate.associate_number"
        :unverified="!associate.is_verified"
      />

      <b class="col-6">Date <span class="required-field">*</span></b>
      <a-create_date-picker
        class="col-6"
        v-model="create_date"
        :format="dateFormat"
      />

      <b class="col-6"
        >Follow up review days <span class="required-field">*</span></b
      >
      <div class="col-6">
        <a-input-number
          v-model="follow_up_review_date_days"
          :min="1"
          :max="31"
          placeholder="Follow up review days"
        />
      </div>

      <b class="col-6"
        >Type of disciplinary action <span class="required-field">*</span></b
      >
      <a-select
        v-model="disciplinary_action_type"
        class="col-6"
        placeholder="Select type"
        :options="disciplinaryActionTypes"
      />

      <b class="col-6"
        >Violation category <span class="required-field">*</span></b
      >
      <a-select
        v-model="violation_category"
        class="col-6"
        placeholder="Select category"
        :options="violationCategoryTypes"
      />

      <b class="col-6">Reason for counselling/corrective action</b>
      <a-auto-complete
        v-model="reason_for_action"
        class="col-6 dropdown-custom"
        @search="(value) => handleSearch(value, 'reason_for_action')"
        @select="(value) => handleSelect(value, 'reason_for_action')"
        @change="(value) => handleChange(value, 'reason_for_action')"
        placeholder="Type to search"
      >
        <template slot="dataSource">
          <a-select-option
            class="custom-dropdown-item"
            v-for="item in dataSource.reason_for_action"
            :key="item"
            :title="item"
          >
            {{ item }}
          </a-select-option>
        </template>
      </a-auto-complete>

      <b class="col-6">Expected level of performance</b
      ><a-auto-complete
        v-model="expected_level_of_performance"
        class="col-6 dropdown-custom"
        @search="
          (value) => handleSearch(value, 'expected_level_of_performance')
        "
        @select="
          (value) => handleSelect(value, 'expected_level_of_performance')
        "
        @change="
          (value) => handleChange(value, 'expected_level_of_performance')
        "
        placeholder="Type to search"
      >
        <template slot="dataSource">
          <a-select-option
            :style="{ color: 'red' }"
            class="custom-dropdown-item"
            v-for="item in dataSource.expected_level_of_performance"
            :key="item"
            :title="item"
          >
            {{ item }}
          </a-select-option>
        </template>
      </a-auto-complete>

      <div class="col-12">
        <signature
          checkbox-label="I agree that all data is true and correct"
          :associate="signatureAssociate"
          :available-signature="adminAssociateSignature"
          ref="signatureRef"
          @checked="setSignatureCheckboxValue"
          @save-signature="setSignatureId"
        />
      </div>

      <div class="col-12">
        <a-button
          type="primary"
          :disabled="disabled"
          :loading="loading"
          @click="createWriteUp"
        >
          Send
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {
  Modal,
  DatePicker,
  Select,
  Input,
  InputNumber,
  Button,
  notification,
  AutoComplete,
} from "ant-design-vue";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import { disciplinaryActionTypes, violationCategories } from "@/const";
import {
  LEVEL_OF_PERFORMANCE,
  WRITEUP_REASON,
} from "@/store/modules/profile/constants";
import { createNamespacedHelpers } from "vuex";
import util from "@/util";
import Signature from "@/components/signature.vue";

const { mapState: profileState, mapActions: profileActions } =
  createNamespacedHelpers("profile");

export default {
  components: {
    "a-modal": Modal,
    "a-create_date-picker": DatePicker,
    "a-select": Select,
    "a-input": Input,
    "a-input-number": InputNumber,
    "a-button": Button,
    "a-auto-complete": AutoComplete,
    "a-select-option": Select.Option,
    "grouped-associate-info": GroupedAssociateInfo,
    signature: Signature,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      create_date: undefined,
      follow_up_review_date_days: undefined,
      disciplinary_action_type: undefined,
      violation_category: undefined,
      reason_for_action: undefined,
      expected_level_of_performance: undefined,

      loading: false,

      dataSource: {
        reason_for_action: Object.values(WRITEUP_REASON),
        expected_level_of_performance: Object.values(LEVEL_OF_PERFORMANCE),
      },

      availableSources: {
        reason_for_action: Object.values(WRITEUP_REASON),
        expected_level_of_performance: Object.values(LEVEL_OF_PERFORMANCE),
      },

      checked: false,
      signature_id: undefined,
    };
  },
  watch: {
    visible() {
      this.create_date = undefined;
      this.follow_up_review_date_days = undefined;
      this.disciplinary_action_type = undefined;
      this.violation_category = undefined;
      this.reason_for_action = undefined;
      this.expected_level_of_performance = undefined;
    },
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
    }),
    dateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    disciplinaryActionTypes() {
      return disciplinaryActionTypes;
    },
    violationCategoryTypes() {
      return violationCategories;
    },
    serverDateFormat() {
      return this.$store.state.applicationState.serverDateFormat;
    },
    signatureAssociate() {
      return {
        name: util.combineAssociateName(
          this.$store.state.applicationState.associate.first_name,
          this.$store.state.applicationState.associate.last_name
        ),
        email: this.$store.state.applicationState.associate.email,
        phone: this.$store.state.applicationState.associate.phone,
      };
    },
    adminAssociateSignature() {
      return this.$store.state.applicationState.associate.signature;
    },
    disabled() {
      return (
        !this.create_date ||
        !this.follow_up_review_date_days ||
        !this.disciplinary_action_type ||
        !this.violation_category ||
        !this.checked
      );
    },
  },
  methods: {
    ...profileActions(["postAssociateWriteUp"]),

    closeModal() {
      this.$emit("update:visible", false);
    },

    showNotification(type, message, description) {
      notification[type]({
        message: message,
        description: description,
      });
    },

    setSignatureCheckboxValue(val) {
      this.checked = val;
    },

    setSignatureId(signature_id) {
      this.signature_id = signature_id;
    },

    getFilteredOptions(inputText, key) {
      if (!inputText) return [];

      return this.availableSources[key].filter((item) =>
        item.toLowerCase().includes(inputText.toLowerCase())
      );
    },

    handleSearch(value, key) {
      this[key] = value;
      this.dataSource[key] = this.getFilteredOptions(value, key);
    },

    handleSelect(value, key) {
      this[key] = value;
    },

    handleChange(value, key) {
      this[key] = value;
    },

    async createWriteUp() {
      this.loading = true;

      try {
        await this.$refs.signatureRef.saveSignature();

        const payload = {
          associate_number: this.associate.associate_number,
          signature_id: this.signature_id,
          manager_scopes: this.associate.manager_scopes,
          writeup: {
            create_date: this.create_date.format(this.serverDateFormat),
            follow_up_review_date_days: this.follow_up_review_date_days,
            disciplinary_action_type: this.disciplinary_action_type,
            violation_category: this.violation_category,
            reason_for_action: this.reason_for_action,
            expected_level_of_performance: this.expected_level_of_performance,
          },
        };

        await this.postAssociateWriteUp(payload);

        this.showNotification(
          "success",
          "Success",
          "Write-Up Created Successfully"
        );

        this.closeModal();
      } catch (error) {
        this.showNotification("error", "Creating Error", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.ant-calendar-picker {
  margin: 0;
}
.ant-input-number {
  width: 100%;
}
</style>

<style>
.custom-dropdown-item {
  overflow: visible !important;
  white-space: normal !important;
}
</style>
