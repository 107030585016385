import { vueTourOptions } from "@/const";

export default {
  data() {
    return {
      tourSteps: [
        {
          target: ".ant-table-row",
          header: {
            title: "Shift Authorizations",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: this.authsTourBeforeStartCallback,
          content: `
            <p>Authorizations occur when associates perform actions during their shifts that contradict company policies. You must review these actions to determine if they were justified or if disciplinary action is needed.</p>
            <p>This section shows details about the authorization, including the shift it occurred in, the date, and the associate involved. This helps you understand the context and the individual responsible.</p>
          `,
        },
        {
          target: "#shift-table",
          header: {
            title: "Shift Info",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: this.beforeShiftTableStepCallback,
          content:
            "This section provides detailed information about the shift during which the violation occurred, helping you review the context of the authorization.",
        },
        {
          target: ".ant-table-row td:nth-child(5)",
          header: {
            title: "Authorizations Info",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content: `
          <p>This section shows the authorizations that occurred as a result of signing the shift. There are various types of authorizations:</p>
          <ul style="text-align: left">
            <li>Early Start</li>
            <li>Late Start</li>
            <li>No Meal Break</li>
            <li>Early Meal Break End</li>
            <li>No Second Meal Break</li>
            <li>Early Second Meal Break End</li>
            <li>Early End</li>
            <li>Late End</li>
            <li>Edit Shift</li>
            <li>Overtime</li>
            <li>Outside of Work Area</li>
            <li>Absence on Shift</li>
            <li>Manually Shift</li>
            <li>Signed Actual Time Discrepancy</li>
            <li>No Rest Break</li>
          </ul>
        `,
        },
        {
          target: ".ant-table-row td:nth-child(6)",
          header: {
            title: "Actions",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content: `
          <p style="text-align: left">This section allows you to:</p>
          <ul style="text-align: left">
            <li>
              View authorisations and approve/decline it
            </li>
            <li>
              Adjust shift
            </li>
            <li>
              View user profile
            </li>
            <li>
              Send a message to associate
            </li>
          </ul>
          `,
        },
        {
          target: "#show-auths-btn",
          header: {
            title: "Show Authorizations",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content:
            "Clicking 'Show Authorizations' opens a detailed view where you can see the history of authorizations, who approved them, explanations for the actions, and their current status." +
            " When selecting authorizations from the list, you can accept or deny them",
        },
        {
          target: "#history-write-up-btn",
          header: {
            title: "Show Write-Up History",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content:
            "Clicking 'Write-Up History' opens a detailed view where you can see the history of write-ups",
        },
        {
          target: "#accept-auths-btn",
          header: {
            title: "Accept Authorizations",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: this.beforeAuthsWindowStepCallback,
          content:
            "Click 'Accept' to approve the authorization, indicating that the action was justified.",
        },
        {
          target: "#deny-auths-btn",
          header: {
            title: "Deny Authorizations",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content: `
            <p style="text-align: left">Click 'Deny' to reject the authorization, stating that the action was unjustified. In the modal window, you will need to:</p>
            <ul style="text-align: left">
              <li>Step 1: Select a reason for denial.</li>
              <li>Step 2: Optionally issue a disciplinary action if necessary.</li>
            </ul>
          `,
        },
        {
          target: "#edit-shift-btn",
          header: {
            title: "Open Shift",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: this.beforeEditShiftButtonStepCallback,
          content:
            "You can also edit the shift to address the violation, modifying details\n" +
            "as necessary.",
        },
        {
          target: "#edit-profile-btn",
          header: {
            title: "View Associate Profile",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content:
            "Review the associate's profile to evaluate their overall performance and consider any necessary adjustments to their role or responsibilities.",
        },
        {
          target: "#send-msg-btn",
          header: {
            title: "Send Message Button",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content:
            "Use the message button to send a message to the associate, advising them on what they need to improve and ensuring they understand the company's policies.",
        },
      ],
    };
  },
  computed: {
    vueTourOptions() {
      return vueTourOptions;
    },
  },
  methods: {
    authsTourBeforeStartCallback() {
      return new Promise((resolve, reject) => {
        const checkCondition = () => {
          if (this.tableData && this.tableData.length > 0 && !this.loading) {
            resolve();
          } else {
            setTimeout(checkCondition, 100);
          }
        };
        checkCondition();
      });
    },

    beforeShiftTableStepCallback() {
      const firstRow = document.querySelector("tbody tr");
      if (firstRow) {
        const buttonDiv = firstRow.querySelector(".ant-table-row-expand-icon");

        if (buttonDiv) {
          buttonDiv.click();
        }
      }
    },

    beforeAuthsWindowStepCallback() {
      return new Promise((resolve) => {
        this.showShiftAuthorizations(this.tableData[0]);
        setTimeout(() => {
          resolve();
        }, 500);
      });
    },

    beforeEditShiftButtonStepCallback() {
      return new Promise((resolve) => {
        this.closeCurrentShiftAuthsModalWithListClean();
        setTimeout(() => {
          resolve();
        }, 500);
      });
    },
  },
};
