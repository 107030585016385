<template>
  <div>
    <div v-for="(page, index) in paginatedData" :key="index">
      <div class="total-hours-page">
        <div class="header">
          <b>Total Hours Detail Report</b>
        </div>

        <div class="general-info">
          <div>
            <p class="section-header"><b>General Info</b></p>

            <div class="info-container">
              <div class="info-row">
                <span class="info-label">Company</span>
                <span class="info-value">{{ projectName }}</span>
              </div>
              <div class="info-row">
                <span class="info-label">Payment Type</span>
                <span class="info-value">{{ currentPaymentTypeName }}</span>
              </div>
              <div class="info-row">
                <span class="info-label">Qualification</span>
                <span class="info-value">{{ filteredRoleName }}</span>
              </div>
              <div class="info-row">
                <span class="info-label">Report Date</span>
                <span class="info-value">{{ currentTime }}</span>
              </div>
            </div>
          </div>

          <div v-if="filteredByDate">
            <p class="section-header"><b>Report Info</b></p>

            <div class="info-container">
              <div class="info-row">
                <span class="info-label">Page</span>
                <span class="info-value">{{ index + 1 }}</span>
              </div>
              <div v-if="dateFrom" class="info-row">
                <span class="info-label">Date From</span>
                <span class="info-value">{{ dateFrom }}</span>
              </div>
              <div v-if="dateTo" class="info-row">
                <span class="info-label">Date To</span>
                <span class="info-value">{{ dateTo }}</span>
              </div>
            </div>
          </div>
        </div>

        <table class="total-hours-table">
          <thead>
            <tr>
              <th>Associate Number</th>
              <th>Name</th>
              <th>Role</th>
              <th>Regular</th>
              <th>1st OT</th>
              <th>2nd OT</th>
              <th>Work</th>
              <th>Holiday</th>
              <th>Vacation</th>
              <th>Sick</th>
              <th>PTO</th>
              <th>Other</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dataUnit in page" :key="dataUnit.associate_number">
              <td>{{ dataUnit.associate_number }}</td>
              <td>{{ dataUnit.first_name + " " + dataUnit.last_name }}</td>
              <td>{{ dataUnit.role }}</td>
              <td>{{ dataUnit.reg_hours }}</td>
              <td>{{ dataUnit.first_overtime_hours }}</td>
              <td>{{ dataUnit.second_overtime_hours }}</td>
              <td>{{ dataUnit.work_hours }}</td>
              <td>{{ dataUnit.holiday_hours }}</td>
              <td>{{ dataUnit.vacation_hours }}</td>
              <td>{{ dataUnit.sick_hours }}</td>
              <td>{{ dataUnit.pto_hours }}</td>
              <td>
                <template
                  v-if="dataUnit.other_pc && dataUnit.other_pc.length > 0"
                >
                  <div class="flex flex-column gap-1">
                    <span
                      v-for="otherPc in dataUnit.other_pc"
                      :key="otherPc.code"
                    >
                      {{ otherPc.description }}: {{ otherPc.hours }}
                    </span>
                  </div>
                </template>
                <template v-else>-</template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="index < paginatedData.length - 1"
        class="html2pdf__page-break"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  props: ["reportData", "dateFrom", "dateTo", "roleName"],
  data() {
    return {};
  },
  computed: {
    filteredByDate() {
      return this.dateFrom || this.dateTo;
    },

    projectName() {
      return this.$store.state.applicationState.currentProject.name ?? "-";
    },
    currentPaymentTypeName() {
      return this.$store.state.applicationState.currentPaymentTypeName ?? "All";
    },
    filteredRoleName() {
      return this.roleName ?? "All";
    },

    currentTime() {
      return moment().format("MM/DD/YYYY hh:mm A");
    },

    paginatedData() {
      const pageSize = 15;

      let pages = [];
      let startIndex = 0;

      while (startIndex < this.reportData.length) {
        const endIndex = this.findEndIndex(startIndex, pageSize);
        pages.push(this.reportData.slice(startIndex, endIndex));
        startIndex = endIndex;
      }

      return pages;
    },
  },
  methods: {
    findEndIndex(startIndex, initialPageSize) {
      let count = 0;
      let pageHeight = 0;

      for (let i = startIndex; i < this.reportData.length; i++) {
        const currentEntry = this.reportData[i];
        let entryHeight = 1;

        if (currentEntry.other_pc && currentEntry.other_pc.length > 1) {
          entryHeight += currentEntry.other_pc.length - 1;
        }

        if (pageHeight + entryHeight > initialPageSize) {
          break;
        }

        pageHeight += entryHeight;
        count++;
      }

      return startIndex + count;
    },
  },
};
</script>

<style scoped>
.total-hours-page {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: sans-serif;
  font-size: 8pt;
  color: black;
  padding: 12mm;
}

td,
th {
  border: 1px solid black;
  border-right: none;
  border-bottom: none;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
}

table {
  width: 100%;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-spacing: 0;
  margin: 0;
}

.total-hours-table {
  margin-top: 3mm;
}

p {
  margin: 5px 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14pt;
}

.header,
.section-header {
  margin-bottom: 10px;
}

.general-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.info-container {
  display: flex;
  flex-direction: column;
  width: 240px;
  gap: 5px;
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-label {
  font-weight: bold;
}

.info-value {
  max-width: 150px;
  word-wrap: break-word;
  text-align: right;
  display: block;
}

tr {
  page-break-inside: avoid;
}
</style>
