<template>
  <div>
    <create-pay-code
      :visible.sync="visiblePayCodeModal"
      :pay-code-id.sync="payCodeId"
    />
    <deletion-pay-code
      :visible.sync="visibleDeletionModal"
      :pay-code-id.sync="payCodeId"
    />
    <h2 class="view-header">Pay Codes</h2>
    <div class="actions">
      <a-select
        v-model="filter.attached"
        placeholder="Attached"
        :defaultActiveFirstOption="true"
      >
        <a-select-option :value="null"> All Pay Codes </a-select-option>
        <a-select-option :value="true"> Only Attached </a-select-option>
        <a-select-option :value="false"> Only Not Attached </a-select-option>
      </a-select>
      <a-button
        v-if="
          hasAnyPermission([
            'validate_payments',
            'manage_shifts',
            'process_payments',
            'validate_payments',
          ])
        "
        type="primary"
        @click="openPayCodeModal"
        >Add Pay Code</a-button
      >
    </div>
    <a-table
      size="small"
      :pagination="{ pageSize: 50 }"
      :columns="columns"
      :data-source="tableData"
      :loading="loadingPayCodes"
    >
      <span
        v-if="
          !record.initial &&
          hasAnyPermission([
            'validate_payments',
            'manage_shifts',
            'process_payments',
            'validate_payments',
          ])
        "
        slot="actions"
        slot-scope="text, record"
      >
        <div class="flex gap-2 justify-content-center">
          <a-button
            id="open-btn"
            title="Delete"
            size="small"
            icon="edit"
            @click="openEditModal(record.id)"
          >
            Edit
          </a-button>
          <a-button
            id="open-btn"
            title="Delete"
            size="small"
            icon="delete"
            @click="openDeleteModal(record.id)"
          >
            Delete
          </a-button>
        </div>
      </span>
    </a-table>
  </div>
</template>

<script>
import { Button, notification, Select, Table } from "ant-design-vue";
import util from "@/util";
import AddPayCode from "../components/paycode-creation-modal.vue";
import PaycodeDeletionModal from "../components/paycode-deletion-modal.vue";
import { createNamespacedHelpers } from "vuex";
import permissionMixin from "@/mixins/permissionMixin";

const { mapState, mapActions } = createNamespacedHelpers("paycode");

export default {
  components: {
    "a-button": Button,
    "a-table": Table,
    "a-select": Select,
    "a-select-option": Select.Option,
    "create-pay-code": AddPayCode,
    "deletion-pay-code": PaycodeDeletionModal,
  },
  mixins: [permissionMixin],
  data: () => ({
    visiblePayCodeModal: false,
    visibleDeletionModal: false,

    columns: [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 200,
        sorter: (a, b) =>
          a.name == null
            ? -1
            : b.name == null
            ? 1
            : a.name.localeCompare(b.name),
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        sorter: (a, b) =>
          a.description == null
            ? -1
            : b.description == null
            ? 1
            : a.description.localeCompare(b.description),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        width: 200,
        align: "center",
        sorter: (a, b) =>
          a.type == null
            ? -1
            : b.type == null
            ? 1
            : a.type.localeCompare(b.type),
        customRender: (_, { type }) => util.titleCase(type),
      },
      {
        title: "Value",
        dataIndex: "value",
        key: "value",
        width: 100,
        align: "center",
        sorter: (a, b) => a.value - b.value,
        customRender: (_, { value, type }) =>
          type === "duration" ? `${value} hours` : `${value} $`,
      },
      {
        title: "Conflicted",
        key: "conflicted",
        align: "center",
        sorter: (a, b) => a.conflicted - b.conflicted,
        customRender: (_, { conflicted }) => (conflicted ? "Yes" : "No"),
      },
      {
        title: "Attached",
        key: "attached",
        align: "center",
        sorter: (a, b) => a.attached - b.attached,
        customRender: (_, { attached }) => (attached ? "Yes" : "No"),
      },
      {
        title: "Actions",
        key: "actions",
        align: "center",
        scopedSlots: { customRender: "actions" },
      },
    ],
    filter: {
      attached: null,
    },

    payCodeId: undefined,
  }),
  watch: {
    filter: {
      handler() {
        this.fetchPayCodesInfo();
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState({
      tableData: (state) => state.payCodes,
      loadingPayCodes: (state) => state.loadingPayCodes,
    }),
  },
  methods: {
    ...mapActions(["fetchPayCodes", "deletePayCode"]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    async fetchPayCodesInfo() {
      try {
        this.fetchPayCodes({ filter: this.filter });
      } catch {
        this.showNotification("error", "Error", error);
      }
    },

    openPayCodeModal() {
      this.visiblePayCodeModal = true;
    },

    openEditModal(id) {
      this.openPayCodeModal();
      this.payCodeId = id;
    },

    openDeleteModal(id) {
      this.visibleDeletionModal = true;
      this.payCodeId = id;
    },
  },
};
</script>

<style scoped>
.actions {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: 8px;
  gap: 8px;
  margin-right: 0;
}
</style>
