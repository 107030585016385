<template>
  <div>
    <h2 class="view-header">Associate Pay Codes</h2>
    <shift-pay-code
      :visible.sync="visiblePayCodeModal"
      :initial-values="initialValues"
      @add="fetchPayCodes"
    />

    <shift-view
      :visible.sync="visibleShiftModal"
      :info="editingShift"
      @edit="fetchPayCodes"
    />

    <div class="table_controls_and_data">
      <div class="flex gap-2 align-items-center">
        <a-button type="primary" @click="openPayCodeModal"
          >Add Pay Code</a-button
        >
      </div>
      <div class="flex gap-2 align-items-center">
        <search-associates
          class="w-16rem"
          :value.sync="selectedAssociateNumber"
        />
        <a-range-picker
          format="MM/DD/YYYY"
          v-model="dateProxy"
          valueFormat="YYYY-MM-DD"
          :ranges="dateRange"
        />
      </div>
    </div>
    <a-table
      size="small"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      :pagination="pagination"
      row-key="shift.id"
      @change="handleTableChange"
      :customRow="
        (record) => ({ on: { click: () => editCode(record.shift.id) } })
      "
    >
      <span
        slot="associate"
        slot-scope="text, record"
        :key="record.associate.photo_url"
      >
        <grouped-associate-info
          :key="record.shift.id"
          :first-name="record.associate.first_name"
          :last-name="record.associate.last_name"
          :photo-url="record.associate.photo_url"
          :unverified="!record.associate.is_verified"
          :associate-number="record.associate.associate_number"
        />
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-button-group size="small">
          <a-tooltip>
            <template slot="title"> Show Code </template>
            <a-button
              icon="eye"
              @click.stop="
                editCode(record.shift.id, record.associate.associate_number)
              "
            />
          </a-tooltip>
          <a-tooltip>
            <template slot="title"> Delete Code </template>
            <a-button icon="delete" @click.stop="deleteCode(record.shift.id)" />
          </a-tooltip>
        </a-button-group>
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  Button,
  DatePicker,
  Dropdown,
  notification,
  Modal,
  Select,
  Table,
  Tooltip,
} from "ant-design-vue";
import api from "@/api";
import moment from "moment-timezone";
import Util from "@/util";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import ShiftPayCodeModal from "../components/shift-pay-code-create-modal.vue";
import ShiftViewModal from "../components/shift-view-modal.vue";
import SearchAssociates from "@/components/search-associates.vue";
import { createNamespacedHelpers } from "vuex";

const { mapActions: shiftActions } = createNamespacedHelpers("shifts");

export default {
  components: {
    "a-range-picker": DatePicker.RangePicker,
    "a-button": Button,
    "a-button-group": Button.Group,
    "a-dropdown": Dropdown,
    "a-select": Select,
    "a-table": Table,
    "a-modal": Modal,
    "a-tooltip": Tooltip,
    "grouped-associate-info": GroupedAssociateInfo,
    "shift-pay-code": ShiftPayCodeModal,
    "shift-view": ShiftViewModal,
    "search-associates": SearchAssociates,
  },
  watch: {
    start(newVal) {
      this.sendToFirstPage();
      this.initialValues.start = newVal
        ? moment.utc(newVal).format(this.serverDateFormat)
        : null;
      this.fetchPayCodes();
    },
    end(newVal) {
      this.sendToFirstPage();
      this.initialValues.end = newVal
        ? moment.utc(newVal).format(this.serverDateFormat)
        : null;
      this.fetchPayCodes();
    },
    selectedAssociateNumber(newVal) {
      this.sendToFirstPage();
      this.initialValues.associateNumber = newVal;
      this.fetchPayCodes();
    },
  },
  data() {
    return {
      tableData: [],

      loading: false,

      selectedAssociateNumber: undefined,

      start: undefined,
      end: moment(),

      serverDateFormat: this.$store.state.applicationState.serverDateFormat,

      visiblePayCodeModal: false,
      visibleShiftModal: false,

      editingShift: undefined,

      columns: [
        {
          title: "Shift ID",
          dataIndex: "shift.id",
          key: "shift_id",
          sorter: true,
        },
        {
          title: "Associate",
          scopedSlots: { customRender: "associate" },
          key: "associate_name",
          sorter: true,
        },
        {
          title: "Qualification",
          dataIndex: "associate.role.name",
          key: "role_name",
          sorter: true,
        },
        {
          title: "Date",
          dataIndex: "shift.date",
          key: "date",
          sorter: true,
          customRender: (_, record) => {
            const dateMoment = moment
              .utc(record.shift.date)
              .tz(this.$store.state.applicationState.timeZone);

            return dateMoment.format(
              this.$store.state.applicationState.dateFormat
            );
          },
        },
        {
          title: "Code",
          dataIndex: "shift.pay_code.name",
          key: "pay_code_name",
          sorter: true,
        },
        {
          title: "Type",
          dataIndex: "shift.pay_code.type",
          key: "shift.pay_code.type",
          customRender: (_, record) => {
            return record.shift.pay_code.type.toUpperCase();
          },
        },
        {
          title: "Value",
          dataIndex: "shift.hour_duration",
          key: "shift.hour_duration",
          customRender: (_, record) => {
            return record.shift.pay_code.type.toLowerCase() === "total"
              ? `${(
                  Util.convertDbToMoney(record.shift.hour_rate) *
                  (record.shift.duration / 60)
                ).toFixed(2)}$`
              : `${(record.shift.duration / 60).toFixed(0)}h`;
          },
        },
        {
          title: "Actions",
          key: "actions",
          scopedSlots: { customRender: "actions" },
        },
      ],

      pagination: {
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "20", "50"],
        pageSize: 50,
        total: 0,
        current: 1,
      },

      initialValues: {
        associateNumber: undefined,
        start: null,
        end: null,
      },
    };
  },
  mixins: [api],
  computed: {
    dateRange() {
      return {
        Today: [moment(), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
      };
    },

    dateProxy: {
      get() {
        return [this.start, this.end];
      },
      set(value) {
        this.start = value[0];
        this.end = value[1];
      },
    },
    dateFromString() {
      const updatedDate = this.start
        ? moment.utc(this.start).format(this.serverDateFormat)
        : null;

      return updatedDate;
    },
    dateToString() {
      const updatedDate = this.end
        ? moment.utc(this.end).format(this.serverDateFormat)
        : null;

      return updatedDate;
    },
  },
  methods: {
    ...shiftActions(["getPayCodes"]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    async fetchPayCodes(sorter) {
      const payload = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        filter: {
          associate_number: this.selectedAssociateNumber ?? null,
          start: this.dateFromString,
          end: this.dateToString,
          sorter,
        },
      };

      this.loading = true;

      let skipFinally = false;

      try {
        const data = await this.getPayCodes(payload);

        this.tableData = data.shifts;
        this.pagination.total = data.total;
      } catch (error) {
        if (error === "canceled") {
          skipFinally = true;
          this.loading = true;
          return;
        }
        this.showNotification("error", "Error", error);
      } finally {
        if (!skipFinally) this.loading = false;
      }
    },

    handleSearchUsersChange(value) {
      this.selectedAssociateNumber = value;
    },

    editCode(shiftId, associate_number) {
      this.editingShift = {
        shift_id: shiftId,
        associate_number: associate_number,
      };
      this.visibleShiftModal = true;
    },

    deleteCode(shiftId) {
      Modal.confirm({
        title: "Are you sure?",
        content: "You're going to delete the Pay Code under Shift #" + shiftId,
        onOk: () => {
          this.apiDeleteAssociateShift(shiftId)
            .then(() => {
              notification["success"]({
                message: "Success",
                description:
                  "Pay Code under shift #" +
                  shiftId +
                  " was successfully deleted",
              });
              this.fetchPayCodes();
            })
            .catch(() => {
              notification["error"]({
                message: "Error",
                description:
                  "An error occurred when deleting the shift. Please contact the administrator",
              });
            });
        },
        onCancel() {},
      });
    },

    sendToFirstPage() {
      this.pagination.current = 1;
    },

    handleTableChange(pagination, filters, sorter) {
      this.pagination = { ...pagination };
      this.fetchPayCodes(sorter);
    },

    openPayCodeModal() {
      this.visiblePayCodeModal = true;
    },
  },

  mounted() {
    this.fetchPayCodes();
  },
};
</script>
