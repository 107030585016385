import apiClient from "@/services/api";

export default {
  async getDocumentByType(_, { type, associate_id }) {
    try {
      const { data } = await apiClient.post(`/api/document/project/type/get`, {
        type,
        associate_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      return data.document;
    } catch (error) {
      throw error.message;
    }
  },

  async exportDocument(_, { export_type, ...rest }) {
    try {
      const { data } = await apiClient.post(
        `/api/document/project/export/${export_type}`,
        {
          ...rest,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg} Please try again later`);
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async getProjectDocuments({ rootState }, { filters }) {
    try {
      const project_id = rootState.applicationState.currentProject.id;

      const { data } = await apiClient.get(
        `/api/document/project/${project_id}`,
        {
          params: { ...filters },
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async getDocumentsGroups({ rootState, commit }) {
    try {
      const { data } = await apiClient.post(
        `/api/document/project/group/get_all`,
        {
          project_id: rootState.applicationState.currentProject.id,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setGroups", data.groups);
    } catch (error) {
      throw error.message;
    }
  },

  async getProjectDocument({ rootState, commit }, { document_id }) {
    try {
      const { data } = await apiClient.post(`/api/document/project/get`, {
        project_id: rootState.applicationState.currentProject.id,
        use_placeholder: false,
        document_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setDocument", data.document);
    } catch (error) {
      throw error.message;
    }
  },

  async createDocument({ rootState }, { document }) {
    try {
      const { data } = await apiClient.post(`/api/document/project/create`, {
        project_id: rootState.applicationState.currentProject.id,
        document,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async updateDocument({ rootState }, { document }) {
    try {
      const { data } = await apiClient.post(`/api/document/project/update`, {
        project_id: rootState.applicationState.currentProject.id,
        document,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async deleteDocument({ rootState }, { document_id }) {
    try {
      const { data } = await apiClient.post(`/api/document/project/delete`, {
        project_id: rootState.applicationState.currentProject.id,
        document_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async getAssociateDocuments(
    { commit },
    { associate_id, page = 1, size = 10 }
  ) {
    try {
      const { data } = await apiClient.get(
        `/api/document/associate/${associate_id}/store`,
        { params: { page, size } }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while loading the documents."
          } Please try again later`
        );
      }

      commit("setAssociateDocuments", data);
    } catch (error) {
      throw error.message;
    }
  },

  async createAssociateDocument({ state, commit }, { document, associate_id }) {
    try {
      const { data } = await apiClient.post(
        `/api/document/associate/${associate_id}/store`,
        {
          document,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      const updatedDocuments = {
        ...state.associateDocuments,
        items: [data.document, ...state.associateDocuments.items],
      };

      commit("setAssociateDocuments", updatedDocuments);
    } catch (error) {
      throw error.message;
    }
  },

  async deleteAssociateDocument({}, { document, associate_id }) {
    try {
      const { data } = await apiClient.delete(
        `/api/document/associate/${associate_id}/store/${document}`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async getAssociateTemplate({ rootState }, { page = 1, size = 10, filters }) {
    try {
      const project_id = rootState.applicationState.currentProject.id;

      const { data } = await apiClient.get(`/api/document/associate/template`, {
        params: { page, size, ...filters, project_id },
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async getAssociateTemplateById({}, { template_id }) {
    try {
      const { data } = await apiClient.get(
        `/api/document/associate/template/${template_id}`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async checkCreatedAssociateDocument({}, { template_id, associate_id }) {
    try {
      const { data } = await apiClient.get(
        `/api/document/associate/${associate_id}/store/check_created`,
        {
          params: {
            template_id,
          },
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },
};
