<template>
  <div>
    <time-off-requests-filters
      :current-filters="filters"
      :visible="filtersModalVisible"
      @apply="applyFilters"
    />

    <time-off-request-modal
      :time-off-request.sync="currentTimeOffRequest"
      @resolved="resolvedRequest"
    />

    <associate-profile-view
      :visible.sync="visibleProfileModal"
      :associate_number.sync="associateNumberToShow"
      @edit="loadData"
    />

    <h2 class="view-header">Time Off Requests</h2>
    <div class="flex align-items-center justify-content-end gap-2 mb-2">
      <a-button-group>
        <a-tooltip>
          <template slot="title"> Open Filters </template>
          <a-button
            icon="filter"
            :disabled="loading"
            @click.stop="showFilterModal"
          />
        </a-tooltip>
        <a-tooltip>
          <template slot="title"> Reset Filters </template>
          <a-button
            icon="undo"
            :disabled="loading || disableResetButton"
            @click.stop="resetFilters"
          />
        </a-tooltip>
      </a-button-group>
      <a-range-picker
        format="MM/DD/YYYY"
        v-model="dateProxy"
        valueFormat="YYYY-MM-DD"
        :ranges="dateRange"
      />
    </div>
    <a-table
      size="small"
      :row-key="(record) => record.id"
      :loading="loading"
      :columns="columns"
      :data-source="tableData"
      :customRow="
        (record) => ({ on: { click: () => openTimeOffRequest(record) } })
      "
    >
      <span slot="requesting-dates" slot-scope="text, record">
        {{ formatDates(record.dates) }}
      </span>
      <span slot="type" slot-scope="text, record">
        <a-tag :color="getTagColor(record.type)">
          {{ convertTypeToText(record.type) }}
        </a-tag>
      </span>
      <span slot="status" slot-scope="text, record">
        <a-tag :color="getStatusColor(record.status)">
          {{ convertStatusToText(record.status) }}
        </a-tag>
      </span>
      <span
        slot="associate"
        slot-scope="text, record"
        :key="record.associate.photo_url"
      >
        <grouped-associate-info
          :first-name="record.associate.first_name"
          :last-name="record.associate.last_name"
          :photo-url="record.associate.photo_url"
          :associate-number="record.associate.associate_number"
          :unverified="!record.associate.is_verified"
        />
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-button-group>
          <a-tooltip>
            <template slot="title"> Open Request </template>
            <a-button
              icon="folder-open"
              size="small"
              @click.stop="openTimeOffRequest(record)"
            />
          </a-tooltip>

          <a-tooltip v-if="hasPermission('manage_associates')">
            <template slot="title"> Open Profile </template>
            <a-button
              icon="user"
              size="small"
              @click.stop="
                openAssociateProfile(record.associate.associate_number)
              "
            />
          </a-tooltip>
        </a-button-group>
      </span>
    </a-table>
  </div>
</template>
<script>
import {
  Table,
  Input,
  Button,
  Tag,
  DatePicker,
  notification,
  Modal,
  Tooltip,
} from "ant-design-vue";

import {
  timeOffRequestStatusMap,
  timeOffRequestTypeColorMap,
  timeOffRequestTypeMap,
} from "@/const";
import api from "@/api";
import Util from "@/util";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import TimeOffRequestsFilters from "@/views/time-off-requests-filters.vue";
import TimeOffRequestModal from "@/components/time-off-request-modal.vue";
import moment from "moment-timezone";
import ProfileAssociateView from "@/modules/profile/components/profile-view.vue";
import permissionMixin from "@/mixins/permissionMixin";

const filtersBaseState = Object.freeze({
  associate: undefined,
  type: undefined,
  status: undefined,
});

export default {
  components: {
    "a-table": Table,
    "a-input": Input,
    "a-button": Button,
    "a-button-group": Button.Group,
    "a-tag": Tag,
    "a-range-picker": DatePicker.RangePicker,
    "a-modal": Modal,
    "a-tooltip": Tooltip,
    "associate-profile-view": ProfileAssociateView,
    "grouped-associate-info": GroupedAssociateInfo,
    "time-off-requests-filters": TimeOffRequestsFilters,
    "time-off-request-modal": TimeOffRequestModal,
  },
  mixins: [api, permissionMixin],
  data() {
    return {
      tableData: [],
      columns: [
        {
          title: "Associate",
          key: "name",
          scopedSlots: { customRender: "associate" },
          align: "center",
        },
        {
          title: "Requesting Dates",
          key: "requestingDates",
          scopedSlots: { customRender: "requesting-dates" },
          align: "center",
        },
        {
          title: "Type",
          key: "type",
          scopedSlots: { customRender: "type" },
          align: "center",
        },
        {
          title: "Status",
          key: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
        },
        {
          title: "Actions",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          align: "center",
        },
      ],

      dateFrom: undefined,
      dateTo: moment(),

      loading: false,
      filters: { ...filtersBaseState },
      associateNumberToShow: undefined,

      visibleProfileModal: false,
      filtersModalVisible: false,

      currentTimeOffRequest: undefined,
      serverDateFormat: this.$store.state.applicationState.serverDateFormat,
    };
  },
  watch: {
    dateTo() {
      this.loadData();
    },
    filters: {
      handler(newVal, prevVal) {
        if (JSON.stringify(newVal) != JSON.stringify(prevVal)) {
          this.loadData();
        }
      },
      deep: true,
    },
  },
  computed: {
    dateRange() {
      return {
        Today: [moment(), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
      };
    },

    dateProxy: {
      get() {
        return [this.dateFrom, this.dateTo];
      },
      set(value) {
        this.dateFrom = value[0];
        this.dateTo = value[1];
      },
    },
    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    serverFormatDateFrom() {
      if (!this.dateFrom) {
        return null;
      }

      return moment.utc(this.dateFrom).format(this.serverDateFormat);
    },
    serverFormatDateTo() {
      if (!this.dateTo) {
        return null;
      }

      return moment.utc(this.dateTo).format(this.serverDateFormat);
    },
    disableResetButton() {
      return JSON.stringify(filtersBaseState) == JSON.stringify(this.filters);
    },
  },
  methods: {
    formatDates: Util.formatDatesListForTimeOffRequestTable,
    async loadData() {
      this.loading = true;

      try {
        const { data } = await this.apiGetAllTimeOffRequests(
          this.filters.associate,
          this.filters.type,
          this.filters.status,
          this.serverFormatDateFrom,
          this.serverFormatDateTo
        );

        if (data.error_code && data.error_code !== "0") {
          notification["warning"]({
            message: "Error",
            description:
              "An error occurred while receiving associate activity. Please try again later",
          });
          return;
        }

        this.tableData = data.items;
      } catch (error) {
        notification["error"]({
          message: "Network Error",
          description: "Failed to load data. Please check your connection.",
        });
      } finally {
        this.loading = false;
      }
    },

    convertTypeToText(timeOffType) {
      return timeOffRequestTypeMap[timeOffType] ?? timeOffType;
    },

    convertStatusToText(timeOffStatus) {
      return timeOffRequestStatusMap[timeOffStatus] ?? timeOffStatus;
    },

    getTagColor(timeOffType) {
      return timeOffRequestTypeColorMap[timeOffType] ?? "#808080";
    },

    getStatusColor(timeOffStatus) {
      switch (timeOffStatus) {
        case "pending":
          return "#52bfff";
        case "approved":
          return "#34db30";
        case "declined":
          return "#ff4646";
      }
    },

    applyFilters(filters) {
      this.hideFilterModal();
      this.filters = { ...filters };
    },
    showFilterModal() {
      this.filtersModalVisible = true;
    },
    hideFilterModal() {
      this.filtersModalVisible = false;
    },
    resetFilters() {
      this.filters = { ...filtersBaseState };
    },

    openAssociateProfile(number) {
      this.associateNumberToShow = number;
      this.visibleProfileModal = true;
    },

    openTimeOffRequest(record) {
      this.currentTimeOffRequest = record;
    },

    resolvedRequest() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
