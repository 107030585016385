import apiClient from "@/services/api";

export default {
  async sendAssociateToOnboarding({}, { associate, type }) {
    try {
      const { data } = await apiClient.patch(
        `/api/complete_request/project/recipients_add`,
        { associate, type }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(`${data.msg}  Please try again later`);
      }
    } catch (error) {
      throw error.message;
    }
  },
};
