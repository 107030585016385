<template>
  <div class="grid">
    <div class="col flex flex-column gap-4">
      <div>
        <h3>Job</h3>
        <a-descriptions :column="1" size="small" class="col-fixed p-0">
          <a-descriptions-item label="Qualification">
            {{ associate?.role?.name }}
          </a-descriptions-item>
          <a-descriptions-item label="Schedule Type">
            {{ getScheduledTypeName(associate.schedule_type) }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <div>
        <h3>Company</h3>
        <a-descriptions :column="1" size="small" class="col-fixed p-0">
          <a-descriptions-item label="Shift Actions Autocomplete">
            {{ associate.automatic_completing_actions ? "Yes" : "Manually" }}
          </a-descriptions-item>
          <a-descriptions-item label="Auto Approve Payment Request">
            {{ associate.payment_auto_approve ? "Yes" : "No" }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <div>
        <h3>License</h3>
        <a-descriptions
          v-if="associate.license"
          :column="1"
          size="small"
          class="col-fixed p-0"
        >
          <a-descriptions-item label="Name">
            {{ associate.license.name }} </a-descriptions-item
          ><a-descriptions-item label="Number">
            {{ associate.license?.number ?? " - " }}
          </a-descriptions-item>
          <a-descriptions-item label="Description">
            {{ associate.license.description }}
          </a-descriptions-item>
          <a-descriptions-item label="Expiration Date">
            {{ formatDateString(associate?.license?.expiration_date) }}
          </a-descriptions-item>
          <a-descriptions-item label="Created At">
            {{ formatDateString(associate?.license?.created_at) }}
          </a-descriptions-item>
          <a-descriptions-item label="Files">
            <a-upload
              list-type="picture-card"
              :file-list="fileList"
              :showUploadList="{
                showRemoveIcon: false,
                showPreviewIcon: true,
                showDownloadIcon: true,
              }"
              @preview="handlePreview"
              @download="handleDownload"
            >
            </a-upload>
          </a-descriptions-item>
        </a-descriptions>
        <p v-else>No license available</p>
      </div>
    </div>

    <div class="col flex flex-column gap-4">
      <activity-info :associate-id="associate.id" />
      <suspension-info
        v-if="isActiveAssociate"
        :suspension="associate.suspension"
        :associate-number="associate.associate_number"
      />
    </div>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { SCHEDULED_TYPE } from "@/store/modules/profile/constants";
import { Button, Descriptions, Modal, Upload } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";
import ProfileActivityInfo from "./profile-activity-info.vue";
import ProfileSuspensionInfo from "./profile-suspension-info.vue";
import moment from "moment-timezone";

const { mapState: profileState } = createNamespacedHelpers("profile");

export default {
  components: {
    "a-button": Button,
    "a-modal": Modal,
    "a-upload": Upload,
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
    "activity-info": ProfileActivityInfo,
    "suspension-info": ProfileSuspensionInfo,
  },
  data() {
    return {
      previewVisible: false,
      previewImage: "",

      fileList: [],
    };
  },
  watch: {
    associate: {
      handler(newVal) {
        this.fileList = newVal.license.files
          .map((item) => ({
            uid: item.id,
            name: item.url,
            url: item.url,
            status: "done",
          }))
          .sort((a, b) => a.uid - b.uid);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
    }),

    isActiveAssociate() {
      return this.associate.is_active;
    },
  },
  methods: {
    getScheduledTypeName(type) {
      return SCHEDULED_TYPE[type];
    },
    formatDateString(dateString) {
      return moment(dateString).utc().format("MM/DD/YYYY");
    },

    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    async handleDownload(file) {
      let preview = file.preview;
      if (!file.url && !preview) {
        preview = await this.getBase64(file.originFileObj);
      }

      const downloadFile = (url, fileName) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName || "download";
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        link.remove();
      };

      if (file.url) {
        downloadFile(file.url, file.name || "download");
      } else if (preview) {
        const base64Data = preview.split(",")[1];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
          type: file.type || "application/octet-stream",
        });
        const url = URL.createObjectURL(blob);
        downloadFile(url, file.name || "download");
        setTimeout(() => URL.revokeObjectURL(url), 1000);
      }
    },

    handleCancel() {
      this.previewVisible = false;
    },
  },
};
</script>

<style scoped>
.ant-card {
  height: fit-content;
  border-radius: 10px;
}

.ant-descriptions {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

::v-deep .ant-descriptions-item-label {
  font-weight: bold;
  border: none;
  background-color: white;
}

::v-deep .ant-descriptions-row {
  border: none;
}

::v-deep .ant-descriptions-item-content {
  overflow-x: auto;
}

::v-deep .ant-descriptions-view {
  padding: 16px;
}

::v-deep .ant-descriptions-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
