<template>
  <div>
    <alert-store-filters
      :current-filters="filters"
      :visible="filtersModalVisible"
      @apply="applyFilters"
    />
    <h2 class="view-header">Alerts</h2>
    <div class="flex align-items-center justify-content-end gap-2 mb-2">
      <a-button-group>
        <a-tooltip>
          <template slot="title"> Open Filters </template>
          <a-button
            icon="filter"
            :disabled="loading"
            @click="showFilterModal"
          />
        </a-tooltip>
        <a-tooltip>
          <template slot="title"> Reset Filters </template>
          <a-button
            icon="undo"
            :disabled="loading || disableResetButton"
            @click="resetFilters"
          />
        </a-tooltip>
      </a-button-group>
      <a-range-picker
        format="MM/DD/YYYY"
        v-model="dateProxy"
        :ranges="dateRange"
      />
    </div>
    <a-table
      size="small"
      :rowKey="(record) => record.id"
      :loading="loading"
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :scroll="{ x: 1600, y: tableScrollHeight }"
      @change="handleTableChange"
      :customRow="
        (record) => ({
          on: {
            click: () => fetchAlert(record.id),
          },
        })
      "
    >
      <span slot="details" slot-scope="text, record" class="clamped-text">
        {{ record.details }}
      </span>
      <span
        slot="type"
        slot-scope="text, record"
        :style="{ color: getType(record.type).color, fontWeight: 'bold' }"
      >
        {{ getType(record.type).title }}
      </span>
      <span slot="severity" slot-scope="text, record">
        <a-tag :color="getSeverity(record.severity).color">
          {{ getSeverity(record.severity).title }}
        </a-tag>
      </span>
    </a-table>
  </div>
</template>
<script>
import {
  Table,
  Input,
  Button,
  Tag,
  DatePicker,
  notification,
  Modal,
  Tooltip,
  message,
} from "ant-design-vue";
import AlertStoreFilters from "@/modules/alerts/components/alert-store-filters.vue";
import { createNamespacedHelpers } from "vuex";
import table from "../mixins/AlertTableMixin";
import actions from "../mixins/AlertActionsMixin";
import moment from "moment-timezone";

const filtersBaseState = Object.freeze({
  alertType: undefined,
  alertSeverity: undefined,
});

const { mapActions, mapGetters } = createNamespacedHelpers("alerts");

export default {
  components: {
    "alert-store-filters": AlertStoreFilters,
    "a-table": Table,
    "a-input": Input,
    "a-button": Button,
    "a-button-group": Button.Group,
    "a-tooltip": Tooltip,
    "a-tag": Tag,
    "a-range-picker": DatePicker.RangePicker,
    "a-modal": Modal,
  },
  mixins: [table, actions],
  data() {
    return {
      tableData: [],

      dateFrom: undefined,
      dateTo: undefined,
      loading: false,
      filters: { ...filtersBaseState },
      filtersModalVisible: false,
    };
  },
  watch: {
    dateFrom() {
      this.loadData();
    },
    filters: {
      handler(newVal, prevVal) {
        if (JSON.stringify(newVal) != JSON.stringify(prevVal)) {
          this.loadData();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["getSeverity", "getType"]),

    dateRange() {
      return {
        Today: [moment(), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
      };
    },

    dateProxy: {
      get() {
        return [this.dateFrom, this.dateTo];
      },
      set(value) {
        this.dateFrom = value[0];
        this.dateTo = value[1];
      },
    },

    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    serverDateFormat() {
      return this.$store.state.applicationState.serverDateFormat;
    },

    disableResetButton() {
      return JSON.stringify(filtersBaseState) == JSON.stringify(this.filters);
    },
  },
  methods: {
    ...mapActions(["getAlerts", "getAlert"]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    async loadData() {
      const payload = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        filter: {
          type: this.filters.alertType,
          severity: this.filters.alertSeverity,
          date_range: {
            start: this.dateFrom
              ? this.dateFrom.format(this.serverDateFormat)
              : undefined,
            end: this.dateTo
              ? this.dateTo.format(this.serverDateFormat)
              : undefined,
          },
        },
      };

      this.loading = true;

      try {
        const data = await this.getAlerts(payload);

        this.tableData = data.items;
        this.pagination.total = data.total_count;
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loading = false;
      }
    },

    async fetchAlert(id) {
      const loadingMessage = message.loading("Action in progress..", 0);

      try {
        await this.getAlert({ id });

        this.switchAlertType();
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        if (loadingMessage()) {
          loadingMessage().destroy();
        }
        this.loading = false;
      }
    },

    applyFilters(filters) {
      this.hideFilterModal();
      this.filters = { ...filters };
    },

    showFilterModal() {
      this.filtersModalVisible = true;
    },

    hideFilterModal() {
      this.filtersModalVisible = false;
    },

    resetFilters() {
      this.filters = { ...filtersBaseState };
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.clamped-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .ant-table-row {
  cursor: pointer;
}
</style>
