<template>
  <a-select
    show-search
    :mode="mode"
    v-model="associatesProxy"
    :autoClearSearchValue="false"
    :allow-clear="true"
    :loading="loadingAssociated"
    :filter-option="false"
    :disabled="disabled"
    :placeholder="loadingAssociated ? 'Loading...' : 'Select Associate'"
    @search="searchAssociates"
    @focus="focusAssociates"
    @blur="blurAssociates"
  >
    <a-select-option
      v-for="associate in associates"
      :key="associate.id"
      :value="associate.id"
    >
      {{
        `[${associate.associate_number}] ${associate.first_name} ${associate.last_name}`
      }}
    </a-select-option>
  </a-select>
</template>

<script>
import { debounce } from "@/helpers/debounce";
import { Divider, Icon, notification, Select, Space } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const { mapState: searchState, mapActions: searchActions } =
  createNamespacedHelpers("search");

export default {
  components: {
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-divider": Divider,
    "a-space": Space,
    "a-icon": Icon,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    multiply: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    payment_type: {
      type: String,
      default: "contractor",
    },
  },
  data() {
    return {
      loadingAssociated: false,

      searchQuery: "",
    };
  },
  watch: {
    searchQuery: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) this.fetchAssociates();
      },
      immediate: true,
    },
    payment_type: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) this.fetchAssociates();
      },
      immediate: true,
    },
  },
  computed: {
    ...searchState({
      associates: (state) => state.associates,
    }),

    mode() {
      return this.multiply ? "multiple" : "default";
    },

    associatesProxy: {
      get() {
        return this.value;
      },
      set(value) {
        if (value === undefined) {
          this.searchQuery = "";

          this.$emit("update:value", undefined);

          return;
        }

        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    ...searchActions(["getAssociates"]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    searchAssociates: debounce(function (value) {
      this.searchQuery = value;
    }, 1000),

    focusAssociates() {
      this.searchQuery = "";
    },

    blurAssociates() {
      this.searchQuery = "";
    },

    addAssociate() {
      this.$emit("add");
    },

    // async calls

    async fetchAssociates() {
      this.loadingAssociated = true;
      try {
        await this.getAssociates({
          query: this.searchQuery,
          filter: {
            payment_type: this.payment_type.toLowerCase(),
          },
        });
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingAssociated = false;
      }
    },
  },
};
</script>
