<template>
  <div>
    <documents-send
      :visible.sync="visibleSendDocumentsModal"
      :loading="loadingSendDocument"
      @send="sendDocuments"
      @close="closeSendDocumentsModal"
    />

    <div class="flex flex-column gap-4">
      <div class="flex justify-content-between align-items-center">
        <h3 class="m-0">Signed Documents</h3>
        <a-dropdown-button type="primary">
          Export
          <a-menu slot="overlay">
            <a-menu-item key="mail" @click="openSendDocumentsModal(undefined)">
              <a-icon type="mail" /> Send All To Email
            </a-menu-item>
            <a-menu-item key="download" @click="downloadAllDocuments">
              <a-icon type="download" /> Download All
            </a-menu-item>
          </a-menu>
        </a-dropdown-button>
      </div>

      <a-table
        size="small"
        :style="{ whiteSpace: 'pre' }"
        :columns="columns"
        :data-source="documents"
        :loading="loading"
        :pagination="pagination"
        :rowKey="(record) => record.id"
        @change="handleTableChange"
      >
        <span slot="date" slot-scope="text, record">
          {{ formatDateString(record.signature.signed_date) }}
        </span>
        <span slot="actions" slot-scope="text, record">
          <div class="flex gap-2 justify-content-center">
            <a-dropdown-button type="primary">
              Export
              <a-menu slot="overlay">
                <a-menu-item
                  key="mail"
                  @click="downloadDocument(record.signed_version_id)"
                >
                  <a-icon type="download" /> Download
                </a-menu-item>
                <a-menu-item
                  key="download"
                  @click="openSendDocumentsModal(record.signed_version_id)"
                >
                  <a-icon type="mail" /> Send To Email
                </a-menu-item>
              </a-menu>
            </a-dropdown-button>
          </div>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Dropdown,
  Menu,
  notification,
  Table,
  Icon,
} from "ant-design-vue";
import moment from "moment-timezone";
import { createNamespacedHelpers } from "vuex";
import DocumentSendModal from "@/components/send-modal.vue";

const { mapActions: profileActions, mapState: profileState } =
  createNamespacedHelpers("profile");
const { mapActions: documentActions } = createNamespacedHelpers("document");

export default {
  components: {
    "a-table": Table,
    "a-button": Button,
    "a-dropdown-button": Dropdown.Button,
    "a-menu": Menu,
    "a-menu-item": Menu.Item,
    "a-icon": Icon,
    "documents-send": DocumentSendModal,
  },
  data() {
    return {
      visibleSendDocumentsModal: false,

      sendDocumentVersion: undefined,

      pagination: {
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "20", "50"],
        pageSize: 10,
        total: 0,
        current: 1,
      },

      documents: [],

      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          width: 80,
          sorter: (a, b) => a.id - b.id,
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: (a, b) =>
            a.name == null
              ? -1
              : b.name == null
              ? 1
              : a.name.localeCompare(b.name),
        },
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          align: "center",
          width: 150,
          scopedSlots: { customRender: "date" },
          sorter: (a, b) =>
            a.signature.signed_date == null
              ? -1
              : b.signature.signed_date == null
              ? 1
              : moment(a.signature.signed_date).valueOf() -
                moment(b.signature.signed_date).valueOf(),
        },
        {
          title: "Actions",
          dataIndex: "actions",
          key: "actions",
          align: "center",
          width: 300,
          scopedSlots: { customRender: "actions" },
        },
      ],

      loadingSendDocument: false,
      loading: false,
    };
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
      signedDocuments: (state) => state.signedDocuments,
    }),
  },
  watch: {
    signedDocuments: {
      handler(newVal) {
        this.pagination.total = newVal.total_count;
        this.documents = newVal.items;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...profileActions(["getSignedDocuments"]),

    ...documentActions(["exportDocument"]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    formatDateString(dateString) {
      return moment(dateString).format("MM/DD/YYYY");
    },

    openSendDocumentsModal(version_id) {
      console.log(version_id);
      this.visibleSendDocumentsModal = true;
      if (version_id) this.sendDocumentVersion = version_id;
    },

    closeSendDocumentsModal() {
      this.visibleSendDocumentsModal = false;
      this.sendDocumentVersion = undefined;
    },

    handleTableChange(pagination) {
      const isPaginationChanged =
        pagination.current !== this.pagination.current ||
        pagination.pageSize !== this.pagination.pageSize;

      if (!isPaginationChanged) {
        return;
      }

      this.pagination = { ...pagination };

      this.fetchDocuments();
    },

    async downloadDocument(version_id) {
      try {
        const payload = {
          type: "project_document_version",
          object_ids: [version_id],
          export_type: "download",
        };

        const { file_url } = await this.exportDocument(payload);

        window.open(file_url, "_blank");
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },

    async downloadAllDocuments() {
      try {
        const payload = {
          associate_id: this.associate.id,
          type: "project_document_version",
          export_type: "download",
        };

        const { file_url } = await this.exportDocument(payload);

        window.open(file_url, "_blank");
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },

    async sendDocuments(email) {
      const payload = {
        type: "project_document_version",
        export_type: "send_email",
        email,
      };

      if (this.sendDocumentVersion) {
        payload.object_ids = [this.sendDocumentVersion];
      } else {
        payload.associate_id = this.associate.id;
      }

      this.loadingSendDocument = true;

      try {
        await this.exportDocument(payload);

        this.showNotification(
          "success",
          "Success",
          "Document successfully sent to email"
        );

        this.closeSendDocumentsModal();
      } catch (error) {
        this.showNotification("error", "Error", error || "An error occurred");
      } finally {
        this.loadingSendDocument = false;
      }
    },

    async fetchDocuments() {
      this.loading = true;

      const payload = {
        associate_id: this.associate.id,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };

      try {
        await this.getSignedDocuments(payload);
      } catch (error) {
        this.showNotification("error", "Error", error || "An error occurred");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
