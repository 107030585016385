<template>
  <div class="flex flex-column gap-2">
    <create-write-up :visible.sync="visibleCreateWriteup" />

    <div class="flex justify-content-between align-items-center">
      <h3>Write-Up</h3>
      <a-button icon="plus" type="primary" @click="openWriteupForm">
        Create Write-Up
      </a-button>
    </div>

    <div class="pt-3 text-center" v-if="writeups.length === 0">
      <h3>No Write-Up Found.</h3>
    </div>
    <a-tabs v-model="activeKey" v-else>
      <a-tab-pane key="history" tab="History">
        <div class="list">
          <div class="item" v-for="(item, index) in writeups" :key="item.id">
            <div class="flex justify-content-between">
              <h4>№ {{ index + 1 }} from {{ formatDate(item.create_date) }}</h4>
              <a-button
                :loading="loadingButtons[item.id]"
                @click="cancelWriteUp(item.id)"
                type="danger"
              >
                Cancel
              </a-button>
            </div>
            <a-descriptions :column="1" bordered size="small">
              <a-descriptions-item label="Status">
                <a-badge
                  :color="getStatus(item.status).color"
                  :text="getStatus(item.status).label"
                />
              </a-descriptions-item>
              <a-descriptions-item label="Authorization">
                <a-tag
                  v-if="item.authorization"
                  :color="getAuthStatus(item.authorization).color"
                >
                  <span>{{ getAuthStatus(item.authorization).label }}</span>
                </a-tag>
                <span v-else>-</span>
              </a-descriptions-item>
              <a-descriptions-item label="Author">
                {{ item.author?.first_name }}
                {{ item.author?.last_name }}
              </a-descriptions-item>
              <a-descriptions-item
                label="Type of disciplinary action being taken"
              >
                {{ getDisciplinaryTitle(item.disciplinary_action_type) }}
              </a-descriptions-item>
              <a-descriptions-item label="Violation category">
                {{ getViolationTitle(item.violation_category) }}
              </a-descriptions-item>
              <a-descriptions-item label="Reason for action">
                {{ item.reason_for_action || " - " }}
              </a-descriptions-item>
              <a-descriptions-item label="Days until the follow-up review date">
                {{ item.follow_up_review_date_days || " - " }}
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="statistics" tab="Statistics" force-render>
        <div class="grid m-0">
          <div class="col-6">
            <h4>Write-Up By Status</h4>
            <bar-chart class="chart" :chart-data="statusChartData" />
          </div>
          <div class="col-6">
            <h4>Write-Up By Auth Types</h4>
            <bar-chart class="chart" :chart-data="authTypeChartData" />
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import CreateWriteUp from "./create-write-up.vue";
import {
  Badge,
  Button,
  Descriptions,
  notification,
  Tabs,
  Tag,
} from "ant-design-vue";
import BarChart from "@/components/bar-chart.vue";
import {
  authorizationsMap,
  disciplinaryActionTypes,
  violationCategories,
  writeUpStatuses,
} from "@/const";
import moment from "moment-timezone";

const { mapActions: profileActions, mapState: profileState } =
  createNamespacedHelpers("profile");

export default {
  components: {
    "a-button": Button,
    "a-tabs": Tabs,
    "a-tab-pane": Tabs.TabPane,
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
    "a-tag": Tag,
    "a-badge": Badge,
    "create-write-up": CreateWriteUp,
    "bar-chart": BarChart,
  },
  data() {
    return {
      visibleCreateWriteup: false,

      activeKey: "history",

      loadingButtons: {},
    };
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
      writeups: (state) => state.associateWriteUps,
    }),

    statusChartData() {
      return this.createChartData(writeUpStatuses, "status");
    },

    authTypeChartData() {
      return this.createChartData(authorizationsMap, "authorization");
    },
  },
  methods: {
    ...profileActions(["postCancelWriteUp"]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    openWriteupForm() {
      this.visibleCreateWriteup = true;
    },

    formatDate(date) {
      return moment(date).format("MMM D hh:mm A");
    },

    createChartData(map, keyField) {
      const itemCount = this.writeups.reduce((acc, item) => {
        acc[item[keyField]] = (acc[item[keyField]] || 0) + 1;
        return acc;
      }, {});

      const { labels, colors, data } = Object.entries(map).reduce(
        (acc, [key, { label, color }]) => {
          if (itemCount[key]) {
            acc.labels.push(label);
            acc.colors.push(color);
            acc.data.push(itemCount[key]);
          }
          return acc;
        },
        { labels: [], colors: [], data: [] }
      );

      return {
        labels,
        datasets: [
          {
            label: "Write-Ups Status Count",
            backgroundColor: colors,
            data,
          },
        ],
      };
    },

    getStatus(value) {
      const status = writeUpStatuses[value];

      return { label: status?.label || "", color: status?.color || "#fff" };
    },

    getAuthStatus(value) {
      const status = authorizationsMap[value];

      return { label: status?.label || "", color: status?.color || "#fff" };
    },

    getDisciplinaryTitle(status) {
      return disciplinaryActionTypes.find((item) => item.key === status)?.label;
    },

    getViolationTitle(status) {
      return violationCategories.find((item) => item.key === status)?.label;
    },

    async cancelWriteUp(id) {
      this.$set(this.loadingButtons, id, true);

      try {
        await this.postCancelWriteUp({ writeup: id });

        this.showNotification(
          "success",
          "Success",
          "Successfully canceled the write-up."
        );
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.$set(this.loadingButtons, id, false);
      }
    },
  },
};
</script>

<style scoped>
::v-deep .ant-descriptions-item-colon {
  width: 50%;
}

::v-deep .ant-tabs-content {
  height: 480px !important;
  padding: 0 !important;
  overflow-y: visible !important;
}

::v-deep .ant-tabs-content .ant-tabs-tabpane {
  overflow-y: auto;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.chart {
  width: 80%;
  margin-bottom: 10px;
}
</style>
